import {
    loginErrorActionCreator,
    loginSuccessActionCreator,
    logoutActionCreator,
    recoverPasswordSuccessActionCreator,
    requestActionCreator,
    resetErrorActionCreator,
    resetPasswordErrorActionCreator,
    resetPasswordModalShowActionCreator,
    resetPasswordSuccessActionCreator,
    signUpCloseActionCreator,
    signUpErrorActionCreator,
    signUpSuccessActionCreator,
    updatePasswordErrorActionCreator,
    updatePasswordSuccessActionCreator, updateUserDataErrorActionCreator,
    updateUserDataSuccessActionCreator,
} from './actions';

export enum AuthActionTypes {
    REQUEST = '@@auth/REQUEST',

    LOGIN = '@@auth/LOGIN',
    LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS',
    LOGIN_ERROR = '@@auth/LOGIN_ERROR',

    SIGN_UP = '@@auth/SIGN_UP',
    SIGN_UP_SUCCESS = '@@auth/SIGN_UP_SUCCESS',
    SIGN_UP_ERROR = '@@auth/SIGN_UP_ERROR',
    SIGN_UP_CLOSE = '@@auth/SIGN_UP_CLOSE',

    LOGOUT = '@@auth/LOGOUT',
    LOGOUT_SUCCESS = '@@auth/LOGOUT_SUCCESS',
    CHANGE_PASSWORD = '@@auth/CHANGE_PASSWORD',
    CHANGE_USER_DATA = '@@auth/CHANGE_USER_DATA',
    CHANGE_USER_DETAILS = '@@auth/CHANGE_USER_DETAILS',
    CHECK_AUTH = '@@auth/CHECK_AUTH',

    RESET_PASSWORD = '@@auth/RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS = '@@auth/RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_ERROR = '@@auth/RESET_PASSWORD_ERROR',
    RESET_PASSWORD_MODAL_SHOWN = '@@auth/RESET_PASSWORD_MODAL_SHOWN',

    RECOVER_PASSWORD = '@@auth/RECOVER_PASSWORD',
    RECOVER_PASSWORD_SUCCESS = '@@auth/RECOVER_PASSWORD_SUCCESS',

    UPDATE_PASSWORD_SUCCESS = '@@auth/UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_ERROR = '@@auth/UPDATE_PASSWORD_ERROR',

    UPDATE_USER_DATA_SUCCESS = '@@auth/UPDATE_USER_DATA_SUCCESS',
    UPDATE_USER_DATA_ERROR = '@@auth/UPDATE_USER_DATA_ERROR',

    RESET_ERROR = '@@auth/RESET_ERRORS',
}

export type LoginAction = {
    type: AuthActionTypes.LOGIN;
    payload: { email: string; password: string };
};

export interface SignUpAction {
    type: AuthActionTypes.SIGN_UP;
    payload: {
        firstName: string;
        lastName: string;
        email: string;
        password: string;
    };
}

export interface ResetPasswordAction {
    type: AuthActionTypes.RESET_PASSWORD,
    payload: {
        email: string
    }
}

export interface RecoverPasswordAction {
    type: AuthActionTypes.RECOVER_PASSWORD,
    payload: {
        password: string,
        recoveryCode: string
    }
}

export interface ChangePasswordAction {
    type: AuthActionTypes.CHANGE_PASSWORD,
    payload: {
        currentPassword: string,
        newPassword: string
    }
}

export interface ChangeUserDataAction {
    type: AuthActionTypes.CHANGE_USER_DATA,
    payload: {
        firstName: string,
        lastName: string,
        email: string
    }
}

export interface AuthState {
    readonly loading: boolean;
    readonly authenticated: boolean | null;
    readonly errors: [any] | null;
    readonly resetPasswordRequestSent: boolean | null;
    readonly signUpSucceeded: boolean;
    readonly recoverPasswordRequestSent: boolean | null;
    readonly updatePassword: boolean | null;
    readonly updateProfile: boolean | null;
    readonly updateUserData: boolean | null;
}

//action creator
type LoginSuccessActionType = ReturnType<typeof loginSuccessActionCreator>;
type LoginErrorActionType = ReturnType<typeof loginErrorActionCreator>;
type SignUpSuccessActionType = ReturnType<typeof signUpSuccessActionCreator>;
type SignUpErrorActionType = ReturnType<typeof signUpErrorActionCreator>;
type LogoutActionType = ReturnType<typeof logoutActionCreator>;
type RequestActionType = ReturnType<typeof requestActionCreator>;
type ResetErrorActionType = ReturnType<typeof resetErrorActionCreator>;
type SignUpCloseActionType = ReturnType<typeof signUpCloseActionCreator>;
type resetPasswordSuccessActionType = ReturnType<typeof resetPasswordSuccessActionCreator>;
type resetPasswordModalShowActionType = ReturnType<typeof resetPasswordModalShowActionCreator>;
type resetPasswordErrorActionType = ReturnType<typeof resetPasswordErrorActionCreator>;
type recoverPasswordSuccessActionType = ReturnType<typeof recoverPasswordSuccessActionCreator>;
type updatePasswordSuccessActionType = ReturnType<typeof updatePasswordSuccessActionCreator>;
type updatePasswordErrorActionType = ReturnType<typeof updatePasswordErrorActionCreator>;
type updateUserDataSuccessActionType = ReturnType<typeof updateUserDataSuccessActionCreator>;
type updateUserDataErrorActionType = ReturnType<typeof updateUserDataErrorActionCreator>;

export type AuthActionsType =
    | LoginSuccessActionType
    | LoginErrorActionType
    | SignUpSuccessActionType
    | SignUpErrorActionType
    | LogoutActionType
    | RequestActionType
    | ResetErrorActionType
    | SignUpCloseActionType
    | resetPasswordSuccessActionType
    | resetPasswordModalShowActionType
    | resetPasswordErrorActionType
    | recoverPasswordSuccessActionType
    | updatePasswordSuccessActionType
    | updatePasswordErrorActionType
    | updateUserDataSuccessActionType
    | updateUserDataErrorActionType
