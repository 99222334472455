import React from 'react';
import { useFormik } from 'formik';
import { Button, CardActions, CardContent, Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import PasswordField from '../../../common/PasswordField';
import { AuthActionTypes, ChangePasswordAction } from '../../../ducks/auth/types';

const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().min(8, 'Password must contain at least 8 characters').required('Field is required'),
    newPassword: Yup.string()
        .required('Confirm your password'),
});

type ChangePasswordPropsType = {
    isChanged: (isChanged: boolean) => void
}

const ChangePasswordForm: React.FC<ChangePasswordPropsType> = ({ isChanged  }) => {
    const dispatch = useDispatch();


    const initialValues = {
        currentPassword: '',
        newPassword: '',
    };

    const form = useFormik({
        initialValues,
        onSubmit: ({ currentPassword, newPassword }) => {

            dispatch({
                type: AuthActionTypes.CHANGE_PASSWORD,
                payload: { currentPassword, newPassword }
            } as ChangePasswordAction)
        },
        validationSchema,
    });

    return (
        <form onSubmit={form.handleSubmit}>
            <CardContent>
                <PasswordField
                    fullWidth
                    variant="outlined"
                    error={!!(form.errors.currentPassword && form.touched.currentPassword)}
                    label="Current password"
                    name="currentPassword"
                    value={form.values.currentPassword}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    helperText={form.errors.currentPassword && form.touched.currentPassword && form.errors.currentPassword}
                    margin="dense"
                />

                <PasswordField
                    fullWidth
                    variant="outlined"
                    error={!!(form.errors.newPassword && form.touched.newPassword)}
                    label="New password"
                    name="newPassword"
                    value={form.values.newPassword}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    helperText={
                        form.errors.newPassword && form.touched.newPassword && form.errors.newPassword
                    }
                    margin="dense"
                />
            </CardContent>
            <CardActions>
                <Grid container direction="row" justify="space-around">
                    <Button type="submit" variant="contained" color="primary">
                        Change Password
                    </Button>
                </Grid>
            </CardActions>
        </form>
    );
};

export default ChangePasswordForm;
