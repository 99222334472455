import { all, AllEffect, call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
    AuthActionTypes,
    ChangePasswordAction,
    ChangeUserDataAction,
    LoginAction,
    RecoverPasswordAction,
    ResetPasswordAction,
    SignUpAction
} from './types';
import { fetchUserActionCreator, updateUserActionCreator } from '../user/actions';
import {
    loginErrorActionCreator,
    loginSuccessActionCreator,
    logoutActionCreator,
    recoverPasswordSuccessActionCreator,
    requestActionCreator,
    signUpCloseActionCreator,
    signUpErrorActionCreator,
    signUpSuccessActionCreator,
    updatePasswordErrorActionCreator,
    updatePasswordSuccessActionCreator,
    updateUserDataErrorActionCreator,
    updateUserDataSuccessActionCreator,
} from './actions';
import { authAPI } from '../../api/authAPI';
import { MeResponseType, SignInResponseType, SignUpParamsType } from '../../api/types';
import { instance } from '../../api/axios';

export function addAuthorizationHeader(accessToken: string): void {
    instance.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
}

export function* saveToStorage(accessToken: string, signInData: SignInResponseType): Generator<void, void> {
    yield localStorage.setItem('accessToken', accessToken);
    yield localStorage.setItem('user', JSON.stringify(signInData));
}

export function* checkAuth() {
    const token = yield localStorage.getItem('accessToken');
    addAuthorizationHeader(token);
    const checkTokenParams = {
        params: {
            accessToken: token,
        },
    };
    try {
        const response: AxiosResponse<MeResponseType> = yield call(authAPI.me, checkTokenParams);
        yield put(fetchUserActionCreator(response.data));
        yield put(loginSuccessActionCreator());
    } catch (error) {
        yield put(logoutActionCreator());
        yield localStorage.removeItem('user');
        yield localStorage.removeItem('accessToken');
    }
}

export function* login(loginAction: LoginAction) {
    const { email, password } = loginAction.payload;

    const loginParams = {
        email,
        password,
    };

    yield put(requestActionCreator());
    try {
        const response: AxiosResponse<SignInResponseType> = yield call(authAPI.signIn, loginParams);
        const { accessToken } = response.data;
        yield saveToStorage(accessToken, response.data);
        addAuthorizationHeader(accessToken);
        yield put(loginSuccessActionCreator());
        //yield put(setUserActionCreator(response.data.user));
    } catch (error) {
        const validationErrors = error.response?.data?.message;
        if (validationErrors) {
            yield put(loginErrorActionCreator(validationErrors));
        } else {
            yield put(loginErrorActionCreator(['Network error']));
        }
    }
}

export function* signUp(signUpAction: SignUpAction) {
    const { firstName, lastName, email, password } = signUpAction.payload;

    const signUpParams = {
        firstName,
        lastName,
        email,
        password,
    };

    yield put(requestActionCreator());
    try {
        const response: AxiosResponse<SignUpParamsType> = yield call(authAPI.signUp, signUpParams);
        if (response.status === 200) {
            yield put(signUpSuccessActionCreator());
        } else {
            yield put(signUpCloseActionCreator());
        }
    } catch (error) {
        const validationErrors = error.response?.data.message;
        if (validationErrors) {
            yield put(signUpErrorActionCreator(validationErrors));
        } else {
            yield put(loginErrorActionCreator(['Network error']));
        }
    }
}

function* resetPassword(resetPasswordAction: ResetPasswordAction) {
    const {
        email
    } = resetPasswordAction.payload;

    try {
        yield call(authAPI.recoveryPassword, email);
        yield put({
            type: AuthActionTypes.RESET_PASSWORD_SUCCESS
        });
    } catch (error) {
        const validationErrors = error.response?.data?.message;
        if (validationErrors) {
            yield put({ type: AuthActionTypes.RESET_PASSWORD_ERROR, payload: { errors: validationErrors } });
        }
    }
}

function* recoverPassword(recoverPasswordAction: RecoverPasswordAction) {
    const { password, recoveryCode } = recoverPasswordAction.payload;

    try {
        yield call(authAPI.verifyPasswordResetToken, password, recoveryCode);
        yield put(recoverPasswordSuccessActionCreator(true));
    } catch (error) {
        const recoverPasswordError = error.response?.data?.message
        yield put({ type: AuthActionTypes.RESET_PASSWORD_ERROR, payload: { errors: recoverPasswordError } });
    }
}

function* changePassword(changePasswordAction: ChangePasswordAction) {
    const { currentPassword, newPassword } = changePasswordAction.payload;

    try {
        yield call(authAPI.updatePassword, currentPassword, newPassword);
        yield put(updatePasswordSuccessActionCreator(true))
    } catch (error) {
        const recoverPasswordError = error.response?.data?.message
        yield put(updatePasswordErrorActionCreator(recoverPasswordError));
    }
}

function* changeUserData(changeUserAction: ChangeUserDataAction) {
    const { firstName, lastName, email } = changeUserAction.payload;

    try {
        const changeUserDataResponse: AxiosResponse<any> = yield call(authAPI.updateUserData, email, firstName, lastName);
        yield put(updateUserDataSuccessActionCreator(true))
        yield put(updateUserActionCreator(
            changeUserDataResponse.data.email,
            changeUserDataResponse.data.id,
            changeUserDataResponse.data.firstName,
            changeUserDataResponse.data.lastName,
        ))
    } catch (error) {
        const recoverPasswordError = error.response?.data?.message
        yield put(updateUserDataErrorActionCreator(recoverPasswordError));
    }
}

function* watchCheckAuth() {
    yield takeEvery(AuthActionTypes.CHECK_AUTH, checkAuth);
}

function* watchSignUp() {
    yield takeEvery(AuthActionTypes.SIGN_UP, signUp);
}

function* watchLogin() {
    yield takeEvery(AuthActionTypes.LOGIN, login);
}

function* watchResetPassword() {
    yield takeEvery(AuthActionTypes.RESET_PASSWORD, resetPassword);
}

function* watchRecoverPassword() {
    yield takeEvery(AuthActionTypes.RECOVER_PASSWORD, recoverPassword);
}

function* watchChangePassword() {
    yield takeEvery(AuthActionTypes.CHANGE_PASSWORD, changePassword);
}

function* watchChangeUserData() {
    yield takeEvery(AuthActionTypes.CHANGE_USER_DATA, changeUserData);
}

export default function* authSaga(): Generator<AllEffect<Generator<ForkEffect<never>, void>>, void> {
    yield all([watchLogin(), watchSignUp(), watchCheckAuth(), watchResetPassword(), watchRecoverPassword(), watchChangePassword(), watchChangeUserData()]);
}
