import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const PasswordField: React.FC<TextFieldProps> = ({ ...rest }) => {
    const [isVisible, setIsVisible] = useState(false);

    const onClickHandler = () => {
        setIsVisible(!isVisible);
    };

    return (
        <TextField
            type={isVisible ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={onClickHandler}>
                            {isVisible && <Visibility />}
                            {!isVisible && <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...rest}
        />
    );
};

export default PasswordField;
