import React, { useEffect } from 'react';
import { TextField, Button, CardContent, CardActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Routes from '../../../routes';
import { AuthActionTypes } from '../../../ducks/auth/types';
import { resetPasswordRequestSelector } from '../../../ducks/auth/selectors';

type ResetPasswordInterface = {
    email: string;
};

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
});

const ResetPasswordForm: React.FC = () => {
    const initialValues = {
        email: '',
    };
    const dispatch = useDispatch();
    const history = useHistory();
    const resetPasswordRequestSent = useSelector(resetPasswordRequestSelector);

    const submit = (instance: ResetPasswordInterface) => {
        dispatch({
            type: AuthActionTypes.RESET_PASSWORD,
            payload: { email: instance.email } as ResetPasswordInterface
        });
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (data) => {
            submit(data);
        },
        validationSchema,
    });

    useEffect(() => {
        if (resetPasswordRequestSent) {
            formik.resetForm({});
        }
    }, [resetPasswordRequestSent, formik]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <CardContent>
                <TextField
                    fullWidth
                    variant="outlined"
                    error={!!(formik.errors.email && formik.touched.email)}
                    label="Email"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.errors.email && formik.touched.email && formik.errors.email}
                    margin="dense"
                />
            </CardContent>
            <CardActions>
                <Grid container direction="row" justify="space-around">
                    <Button style={{width:150}} type="submit" variant={'contained'} color={'primary'}>
                        Reset
                    </Button>
                    <Button style={{width:150}} type="button" onClick={() => history.push(Routes.LOGIN)}>
                        back to login
                    </Button>
                </Grid>
            </CardActions>
        </form>
    );
};

export default ResetPasswordForm;
