export enum StripeActionTypes {
    PAYMENT_METHOD = '@@stripe/PAYMENT_METHOD',
}

export type PaymentAction = {
    type: StripeActionTypes.PAYMENT_METHOD;
    payload: {
        paymentMethodId: string;
        totalCost: number;
    };
};



