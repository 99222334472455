const authHeader = (): { 'x-access-token': string } | Record<string, unknown> => {
    const getUserFromLocalStorage = localStorage.getItem('user') as string;
    const user = JSON.parse(getUserFromLocalStorage);

    if (user && user.accessToken) {
        return { 'x-access-token': user.accessToken };
    }
    return {};
};

export default authHeader;
