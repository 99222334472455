import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { authenticatedSelector } from '../../ducks/auth/selectors';
import Routes from '../../routes';

type AuthRouteProps = {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
};

const AuthRoute: React.FC<AuthRouteProps> = ({ component: Component }: AuthRouteProps) => {
    const isAuthenticated = useSelector(authenticatedSelector);
    const history = useHistory();

    useEffect(() => {
        if (isAuthenticated) {
            history.push(Routes.SEARCH_PROMOTIONS);
        }
    }, [isAuthenticated, history]);

    return !isAuthenticated ? <Route render={(props) => <Component {...props} />} /> : null;
};

export default AuthRoute;
