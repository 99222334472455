import jwt from 'jsonwebtoken';

const secretEnv = process.env.REACT_APP_PUBLIC_KEY
  ? (process.env.REACT_APP_PUBLIC_KEY.replace(/\\n/g, "\n") as string)
  : "";

export const jwtVerify = (token: string): boolean => {

    let publicKey =
      "-----BEGIN PUBLIC KEY-----" + secretEnv + "-----END PUBLIC KEY-----";

    let response = false;
    try {
      const decode = jwt.verify(token, publicKey, { algorithms: ["RS256"] });

      response = true;
    } catch (ex) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
    }
    return response;
};
