export const formatDate = (date: Date | null) => {
    if (date) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return year + '-' + ('00' + month).substr(-2) + '-' + ('00' + day).substr(-2);
    }

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    return year + '-' + ('00' + month).substr(-2) + '-' + ('00' + day).substr(-2);
};

export const formatDateGB = (date: Date | null) => {
    if (date) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return ('00' + day).substr(-2) + '/' + ('00' + month).substr(-2) + '/' + year;
    }

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    return ('00' + day).substr(-2) + '/' + ('00' + month).substr(-2) + '/' + year;
};
