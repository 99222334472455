import { instance } from './axios';
import { ResponseType, VenueResponseType } from './types';
import { AxiosResponse } from 'axios';

export const venueAPI = {
    getVenues(): Promise<AxiosResponse<ResponseType<VenueResponseType[]>>> {
        return instance.get<ResponseType<Array<VenueResponseType>>>('venues');
    },
    getVenueById(id: number): Promise<AxiosResponse<ResponseType<VenueResponseType>>> {
        return instance.get<ResponseType<VenueResponseType>>(`venues/${id}`);
    },
};
