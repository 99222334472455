import { all, call, takeEvery } from 'redux-saga/effects';
import { PaymentAction, StripeActionTypes } from './types';
import { AddPaymentMethodResponseType } from '../../api/types';
import PaymentService from '../../services/paymentService';

export function* payment(action: PaymentAction) {
    try {
        const paymentDataResponse: AddPaymentMethodResponseType = yield call(
            PaymentService.addPaymentMethod,
            action.payload.paymentMethodId,
        );
        const { paymentMethodId } = paymentDataResponse;
        const createPaymentIntentResponse = yield call(PaymentService.createPaymentIntent, {
            amount: action.payload.totalCost,
            currency: 'GBP',
            paymentMethod: paymentMethodId,
        });
        if (createPaymentIntentResponse.error) {
        } else if (createPaymentIntentResponse && createPaymentIntentResponse.status === 'succeeded') {
        }
    } catch (error) {}
}

function* watchAddPaymentMethod() {
    yield takeEvery(StripeActionTypes.PAYMENT_METHOD, payment);
}

export default function* stripeSaga() {
    yield all([watchAddPaymentMethod()]);
}
