import { fetchUserActionCreator, updateUserActionCreator } from './actions';

export enum UserActionTypes {
    SET_USER = '@@user/SET_USER',
    FETCH_USER = `@@user/FETCH_USER`,
}



export interface UserType {
    addressLineFirst: string;
    addressLineSecond: string;
    companyName: string;
    //createdAt: string;
    //customerId: string;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    //mappingId: number;
    //password: string;
    //paymentMethodId: number;
    phoneNumber: string;
    postCode: string;
    //resetPasswordSentAt: string;
    //resetPasswordToken: string;
    town: string;
    //updatedAt: string;
}

//action creator

export type UserActionsType =
    ReturnType<typeof updateUserActionCreator>
    | ReturnType<typeof fetchUserActionCreator>

export type UpdateUserDataType = {
    companyName: string,
    addressLineFirst: string,
    addressLineSecond: string,
    email: string,
    phoneNumber: string,
    town: string,
    postCode: string,
    productInformation: string,
    campaignInformation: string,
}