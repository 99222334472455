import React from 'react';
import ModalComponent from '@material-ui/core/Modal';
import { Card, CardContent, CardHeader, createStyles, makeStyles, Theme } from '@material-ui/core';

type ModalPropsType = {
    isOpen: boolean;
    handleClose: () => any;
    title: string;
    children: React.ReactNode;
};

const Modal: React.FC<ModalPropsType> = ({ isOpen, handleClose, title, children }: ModalPropsType) => {
    const classes = useStyles();

    return (
        <ModalComponent className={classes.modalWindow} open={isOpen} onClose={handleClose}>
            <Card className={classes.modalCard} variant="outlined">
                {title ? <CardHeader title={title} /> : null}
                <CardContent className={classes.modalCardContent}>{children}</CardContent>
            </Card>
        </ModalComponent>
    );
};

export default Modal;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalWindow: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalCard: {
            outline: 'none',
            maxHeight: '90%',
            overflow: 'auto',
        },
        modalCardContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    }),
);
