import axios from 'axios';
import authHeader from './authHeader';
import { BookingDataType } from '../api/types';

const API_URL = process.env.REACT_APP_API_URL;

const PaymentService = {
    addPaymentMethod(paymentMethod: any): Promise<any> {
        return axios
            .post(`${API_URL}/stripe/addPaymentMethod`, { paymentMethod }, { headers: authHeader() })
            .then((response) => response.data)
            .catch((err) => console.log(err));
    },
    createPaymentIntent(createPaymentIntent: any): Promise<any> {
        return axios
            .post(`${API_URL}/stripe/createPaymentIntent`, createPaymentIntent, { headers: authHeader() })
            .then((response) => response.data)
            .catch((err) => console.log(err));
    },
    confirmPaymentIntent(confirmPaymentIntent: any): Promise<any> {
        return axios
            .post(`${API_URL}/stripe/confirmPaymentIntent`, confirmPaymentIntent, { headers: authHeader() })
            .then((response) => response.data)
            .catch((err) => console.log(err));
    },
    retrievePaymentIntent(clientSecret: { paymentIntentId: string }) {
        return axios
            .post(`${API_URL}/stripe/retrievePaymentIntent`, clientSecret, { headers: authHeader() })
            .then((response) => response.data)
            .catch((err) => console.log(err));
    },
    createPayment(createPaymentData: { paymentMethod: string, percentage: number, booking: BookingDataType}) {
        return axios
            .post(`${API_URL}/stripe/createPayment`, createPaymentData, { headers: authHeader() })
            .then((response) => response.data)
            .catch((err) => console.log(err))
    }
};

export default PaymentService;
