import React, { useEffect } from 'react';
import { Card, Grid, Typography } from '@material-ui/core';

import PasswordRecoveryForm from '../../components/forms/authForm/PasswordRecoveryForm';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authenticatedSelector, recoveryPasswordRequestSelector } from '../../ducks/auth/selectors';
import Routes from '../../routes';
import ErrorSnackbarComponent from '../../components/ErrorSnackbar/ErrorSnackbarComponent';
import { recoverPasswordSuccessActionCreator } from '../../ducks/auth/actions';

const PasswordRecoveryPage: React.FC = () => {
    const recoverySuccess = useSelector(recoveryPasswordRequestSelector);
    const isAuthenticated = useSelector(authenticatedSelector);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const url = location.pathname;
    const token = url.slice(url.length - 60)

    if (isAuthenticated) {
        history.push(Routes.PROFILE)
    }

    useEffect(() => {
        if (recoverySuccess) {
            setTimeout(() => {
                history.push(Routes.LOGIN);
                dispatch(recoverPasswordSuccessActionCreator(false))
            }, 2000)
        }
    }, [recoverySuccess,dispatch,history])

    return (
        <div>
            {recoverySuccess && <ErrorSnackbarComponent error={'Password changed successfully! Please wait, or click Sign In'}/>}
            <Typography align="center" variant="h3" component="h1">
                Reset password
            </Typography>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={6}>
                    <Card variant="outlined">
                        <PasswordRecoveryForm code={token}/>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default PasswordRecoveryPage;
