import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Container,
    createStyles,
    LinearProgress,
    makeStyles,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography,
    withStyles,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import AddIcon from '@material-ui/icons/Add';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import cx from 'clsx';
import { LocationOn } from '@material-ui/icons';
// @ts-ignore
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
// @ts-ignore
import { usePushingGutterStyles } from '@mui-treasury/styles/gutter/pushing';

import PaymentPage from '../PaymentPage';
import SpacesComponent from './SpacesComponent';
import CustomerDetails from './CustomerDetails';
import ConfirmBookingComponent from './ConfirmBookingComponent';
import { currentVenueSelector } from '../../ducks/venue/selectors';
import { SpacesType, VenuesActionTypes } from '../../ducks/venue/types';
import { BookingDataType, ConfirmedBookingType } from '../../api/types';
import {
    bookingDataSelector,
    bookingErrorsSelector,
    confirmedDataSelector,
    customerDetailsSelector,
    customerSpacesSelector,
    isCreatedPaymentSelector
} from '../../ducks/booking/selectors';
import {
    addCustomerActionCreator,
    addCustomerSpacesActionCreator,
    confirmedBookingDataActionCreator,
    deleteSpaceActionCreator,
    resetBookingErrorActionCreator
} from '../../ducks/booking/action';
import { spacesAPI } from '../../api/spacesAPI';
import { validationDateOfPaymont } from '../../helpers/validationDateOfPaymant';
import ErrorSnackbarComponent from '../../components/ErrorSnackbar/ErrorSnackbarComponent';
import { partialPayment } from '../../helpers/partialPayment';
import { selectUserState } from '../../ducks/user/selectors';
import { RootState } from '../../store';
import { UpdateUserDataType, UserType } from '../../ducks/user/types';
import { AuthActionTypes } from '../../ducks/auth/types';
import InfoIcon from "@material-ui/icons/Info";

const getSteps = () => {
    return ['1 step: choose spaces', '2 step: customer details', '3 step: checkout', '4 step:payment details'];
};

const BookingPage: React.FC = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const queryParams = new URLSearchParams(useLocation().search);
    const venueId = queryParams.get('venue');
    const spaceId = queryParams.get('space');
    const currentVenue = useSelector(currentVenueSelector);
    const customerSpaces = useSelector(customerSpacesSelector);
    const customerDetails = useSelector(customerDetailsSelector);
    const bookingData = useSelector(bookingDataSelector);
    const confirmedData = useSelector(confirmedDataSelector);
    const shadowStyles = useFadedShadowStyles();
    const gutterStyles = usePushingGutterStyles({ firstExcluded: true });
    const [open, setOpen] = useState(false);
    const [openConfirmBooking, setOpenConfirmBooking] = useState(false);
    const [openPayment, setOpenPayment] = useState<boolean>(false);
    const [showingTable, setShowingTable] = useState(true);
    const [confirmCondition, setConfirmCondition] = useState<boolean>(false);
    const user = useSelector<RootState, UserType>(selectUserState);
    const [userDetails, setUserDetails] = useState<UpdateUserDataType>({} as UpdateUserDataType);
    const [dataToSend, setDataToSend] = useState<BookingDataType>();
    const [initBool, setInitBool] = useState<boolean>(false);
    const [total, setTotal] = useState<string>('0');
    const [duplicatedArray, setDuplicatedArray] = useState<Array<number>>([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const titleRef = useRef<any>(2000);
    const isCreated = useSelector(isCreatedPaymentSelector)
    const defaultImageUrl = `${process.env.REACT_APP_API_URL}/images/default.jpeg`;
    const permissionRedirect = confirmedData.mappingId //&& stripeDependingRedirect;
    const [bookedTime, setBookedTime] = useState(new Map());
    const error = useSelector(bookingErrorsSelector);
    const [isPartialPayment, setIsPartialPayment] = useState<boolean>(false);

    const executeScroll = useCallback(() => scrollToRef(titleRef), []);

    const scrollToRef = (ref: any) => window.scrollTo(20, ref.current.offsetTop);

    const steps = getSteps();

    const currentVenueSpace = currentVenue.Spaces.filter((spase) => {
        if (spaceId) return spase.id === +spaceId;
        return currentVenue.Spaces;
    });
    const startArray = currentVenue.Spaces.filter((space) => space.id.toString() === spaceId);

    const values = currentVenueSpace.map((space, index) => {
        space.index = index;
        return space;
    });

    const [spacesArray, setSpacesArray] = useState<Array<SpacesType>>(values);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        if (venueId && !currentVenue.address) {
            dispatch({ type: VenuesActionTypes.GET_VENUE, payload: { id: venueId } });
        }
        if (startArray.length === 1 && !initBool) {
            setInitBool(true);
            setSpacesArray(startArray);
        }
        if (permissionRedirect) {
            history.push(`/booking-page/${confirmedData.id}/?created=${isCreated}`);
        }
        if (spacesArray.length) {
            executeScroll();
        }

        if (bookingData.mappingId) {
            setOpenPayment(true);
        }
        if (error) {
            //dispatch(resetBookingErrorActionCreator())
            setShowingTable(true);
            setOpen(false);
            setConfirmCondition(false);
            setOpenPayment(false);
            setActiveStep(1);
        }
        if (customerSpaces.length) {
            const partPayment = partialPayment(customerSpaces);
            setIsPartialPayment(partPayment)
        }
    }, [
        dispatch,
        history,
        venueId,
        isCreated,
        currentVenue.Spaces.length,
        currentVenue.address,
        initBool,
        permissionRedirect,
        startArray.length,
        spacesArray.length,
        bookingData.mappingId,
        confirmedData.state,
        confirmedData.mappingId,
        error,
        customerSpaces.length,
    ]);

    useEffect(() => {
        setOpenPayment(false);
        dispatch(confirmedBookingDataActionCreator({} as ConfirmedBookingType))
        if (spaceId) {
            const fetchData = async () => {
                const spaceTimesPeriods = await spacesAPI.getSpaceBookingData(+spaceId);
                setBookedTime(new Map(bookedTime.set(spaceId, spaceTimesPeriods.data)))
            }
            fetchData()
        }
        dispatch({type:AuthActionTypes.CHECK_AUTH})

    }, [])

    const addSpace = useCallback((): void => {
        const element = {
            ...startArray[0],
            index: spacesArray.length,
            checkInDate: null,
            checkOutDate: null,
        };
        setSpacesArray([...spacesArray, element]);
        executeScroll();
    }, [spacesArray, executeScroll, startArray]);

    const addCustomer = useCallback(
        (customer: UpdateUserDataType) => {
            dispatch(addCustomerActionCreator(customer));
        },
        [dispatch],
    );

    const addSpacesCustomer = useCallback(
        (customerSpaces: SpacesType[]) => {
            dispatch(addCustomerSpacesActionCreator(customerSpaces));
        },
        [dispatch],
    );

    const deleteSpacesCustomer = useCallback(
        () => {
            dispatch(deleteSpaceActionCreator());
        },
        [dispatch],
    );

    const getBookedTime = (spaceId: number) => {

        const fetchData = async () => {
            const spaceTimesPeriods = await spacesAPI.getSpaceBookingData(+spaceId);
            setBookedTime(new Map(bookedTime.set(spaceId.toString(), spaceTimesPeriods.data)))
        }
        fetchData();
    };

    const setTotalPrice = () => {
        let sum = 0;
        for (const item of customerSpaces) {
            if (item.amout)
                sum += item.amout;
        }
        return Number(sum.toFixed(2));
    };

    const nextStepOfPayment = () => {
        validationDateOfPaymont({
            spacesArray,
            setOpen,
            setShowingTable,
            handleNext,
            addSpacesCustomer,
            setDuplicatedArray
        });
    }

    const disableNext = () => {
        if (Number(total) === 0) {
            return true
        }
        return false
    };

    const imageFromServer = `${process.env.REACT_APP_API_URL}/images/${currentVenue.id}_full.jpg`;

    const cardImage = {
        color: 'blue',
        backgroundImage: `url(${imageFromServer})`,
    };

    const defaultImage = {
        color: 'blue',
        backgroundImage: `url(${defaultImageUrl})`,
    };

    return currentVenue.Spaces.length ? (
      <Container maxWidth="lg" className={classes.root}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card elevation={0} className={classes.roots}>
            {error && (
              <ErrorSnackbarComponent error={"Not all dates are available"} />
            )}
            <CardMedia
              className={classes.image}
              style={
                currentVenue.hasImage === "true" ? cardImage : defaultImage
              }
            >
              <div />
            </CardMedia>
            <CardContent className={cx(shadowStyles.roots, classes.content)}>
              <div className={classes.venueName}>{currentVenue.name}</div>
              <Box
                color={"grey.500"}
                display={"flex"}
                alignItems={"center"}
                mb={1}
              >
                <LocationOn className={classes.locationIcon} />
                <span>
                  {currentVenue.region}, {currentVenue.address},{" "}
                  {currentVenue.town}, {currentVenue.postCode}
                </span>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                mb={1}
                className={gutterStyles.parent}
              >
                <Typography variant={"body2"} className={classes.rateValue}>
                  Weekly footfall:{" "}
                  <NumberFormat
                    value={currentVenue.weeklyFootfall}
                    displayType={"text"}
                    thousandSeparator={true}
                  />{" "}
                  Indicative Weekly Price:{" "}
                  <NumberFormat
                    value={currentVenue.avgDailyCost}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"£"}
                  />
                  {/* , Average weekend price: {currentVenue.avgWeekendCost} */}
                </Typography>
              </Box>
              <Typography
                component={"div"}
                color={"textSecondary"}
                variant={"body2"}
                style={{ fontSize: 14 }}
              >
                {ReactHtmlParser(currentVenue.details)}
              </Typography>
              <Box
                mt={2}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              />
            </CardContent>
          </Card>
        </div>
        <div className={classes.stepper}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {showingTable ? (
          <>
            <TableContainer className={classes.table} component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">spaces</StyledTableCell>
                    <StyledTableCell align={"left"} className={classes.border2}>
                      Date
                    </StyledTableCell>
                    <StyledTableCell align="center">Daily Cost</StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ borderLeft: "1px solid white" }}
                    >
                      Days
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ borderLeft: "1px solid white" }}
                    >
                      Total
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {spacesArray.map((row, index) => {
                    return (
                      <SpacesComponent
                        key={index + row.id + index * row.id * 15}
                        bookedTime={bookedTime}
                        setDuplicatedArray={setDuplicatedArray}
                        isError={!!duplicatedArray.find((id) => id === index)}
                        setBookedTime={setBookedTime}
                        spacesArray={spacesArray}
                        setTotal={setTotal}
                        total={total}
                        setSpacesArray={setSpacesArray}
                        currentVenue={currentVenue}
                        getBookedTime={getBookedTime}
                        row={{
                          ...row,
                          index,
                        }}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{display:'flex'}}>
              
              <InfoIcon style={{fontSize: 'small',    margin: '13px 0px'
}} />
            
              <p className={classes.details}>
                Weekly bookings (Monday-Sunday) for our UK network of
                promotional kiosks can be made through this online portal. If
                you would like to book for a different time period, or are
                looking for more information, please call our team on 033 33 401
                500
              </p>
            </div>
            <div className={classes.footerBar}>
              <Button
                variant="contained"
                color="primary"
                onClick={addSpace}
                endIcon={<AddIcon>Add space</AddIcon>}
              >
                Add space
              </Button>
              <div style={{ display: "flex" }}>
                <Card className={classes.totalCost}>
                  <div>Rental Cost:</div>
                  <div style={{ fontWeight: "bold" }}>
                    <NumberFormat
                      value={total}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"£"}
                    />
                  </div>
                </Card>

                <Card className={classes.totalCost}>
                  <div>Admin Fee:</div>
                  <div style={{ fontWeight: "bold" }}>
                    <NumberFormat
                      value={currentVenue.additionalCharge}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"£"}
                    />
                  </div>
                </Card>

                <Card className={classes.totalCost}>
                  <div>Total cost:</div>
                  <div style={{ fontWeight: "bold" }}>
                    <NumberFormat
                      value={
                        Number(total) + Number(currentVenue.additionalCharge)
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"£"}
                    />
                  </div>
                </Card>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={disableNext()}
                onClick={() => {
                  dispatch(resetBookingErrorActionCreator());
                  setDuplicatedArray([]);
                  nextStepOfPayment();
                }}
              >
                next
              </Button>
            </div>
          </>
        ) : null}
        {open ? (
          <CustomerDetails
            setOpen={setOpen}
            setUserDetails={setUserDetails}
            setOpenConfirmBooking={setOpenConfirmBooking}
            setShowingTable={setShowingTable}
            addCustomer={addCustomer}
            handleNext={handleNext}
            handleBack={handleBack}
            deleteSpacesCustomer={deleteSpacesCustomer}
            customerDetails={customerDetails}
            user={user}
          />
        ) : null}
        {openConfirmBooking ? (
          <ConfirmBookingComponent
            setOpenConfirmBooking={setOpenConfirmBooking}
            openConfirmBooking={openConfirmBooking}
            customerSpaces={customerSpaces}
            userDetails={userDetails}
            setTotalPrice={setTotalPrice}
            confirmCondition={confirmCondition}
            setConfirmCondition={setConfirmCondition}
            setDataToSend={setDataToSend}
            currentVenueRegion={currentVenue.region}
            currentVenueId={currentVenue.id}
            handleNext={handleNext}
            handleBack={handleBack}
            setOpen={setOpen}
            setOpenPayment={setOpenPayment}
            total={total}
            additionalCharge={currentVenue.additionalCharge}
          />
        ) : null}
        {openPayment && !open && !openConfirmBooking && !showingTable && (
          <PaymentPage
            openConfirmBooking={setOpenConfirmBooking}
            setOpenPayment={setOpenPayment}
            handleBack={handleBack}
            dataToSend={dataToSend as BookingDataType}
            setOpen={setOpen}
            isPartialPayment={isPartialPayment}
            additionalCharge={currentVenue.additionalCharge}
          />
        )}
        <div ref={titleRef} style={{ float: "left", clear: "both" }} />
      </Container>
    ) : (
      <LinearProgress />
    );
};

export default BookingPage;

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#01458E',
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
            fontFamily: 'Example',
            letterSpacing: 0.4,
            '& > div': {
                padding: 10,
            },
        },
    }),
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Example',
            paddingBottom: 20,
        },
        venue: {
            fontSize: 20,
            textAlign: 'center',
            marginTop: 30,
            marginBottom: 30,
        },
        space: {
            display: 'grid',
            flexDirection: 'column',
        },
        table: {
            borderRadius: 15,
        },
        spaceName: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            letterSpacing: 1,
            color: '#01458E',
            fontSize: 16,
            '&:hover': {
                color: '#5288e3',
            },
        },
        border: {
            borderLeft: '1px solid #01458E',
            borderRight: '1px solid #01458E',
        },
        border2: {
            borderLeft: '1px solid white',
            borderRight: '1px solid white',
        },
        dialogSpaceText: {
            textAlign: 'center',
            marginTop: 5,
            textTransform: 'uppercase',
        },
        fab: {
            margin: theme.spacing(2),
        },
        footerBar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 20,
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-around',
            marginBottom: 20,
        },
        button: {
            margin: 10,
            width: 250,
        },
        totalCost: {
            width: 150,
            height: 50,
            borderRadius: 15,
            fontSize: 16,
            margin: '0 0 0 15px',
            fontFamily: 'Example',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > div': {
                marginLeft: 5,
            },
        },
        stepper: {
            width: '100%',
            marginBottom: 20,
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        roots: {
            overflow: 'initial',
            marginTop: 20,
            backgroundColor: 'transparent',
            marginBottom: 20,
            width: '100%',
        },
        rateValue: {
            fontWeight: 'bold',
            marginTop: 2,
        },
        content: {
            position: 'relative',
            padding: 24,
            margin: '-5% 16px 0',
            backgroundColor: '#fff',
            borderRadius: 4,
        },
        locationIcon: {
            marginRight: 4,
            fontSize: 18,
        },
        image: {
            width: '100%', height: 250, backgroundColor: 'red'
        },
        venueName: {
            fontSize: 16,
            letterSpacing: 1,
            margin: '0 0 10px 20px',
            fontWeight: 'bold'
        },
        details: {
            fontSize: "0.875rem",
            margin: "10px 5px",
          }
    }),
);

