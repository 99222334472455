import { AxiosResponse } from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
    BookingAction,
    BookingActionTypes,
    BookingGetByIdAction,
    ConfirmBookingDependingSecurePaymentAction
} from './types';
import {
    bookingDataActionCreator,
    bookingDataByIdActionCreator,
    bookingErrorActionCreator,
    isCreatedPaymentActionCreator,
    resetBookingErrorActionCreator,
} from './action';
import { bookingAPI } from '../../api/bookingAPI';
import { BookingDataByIdType, ConfirmedBookingType } from '../../api/types';

function* bookingCreate(action: BookingAction) {
    const { booking, balanceTransaction } = action.payload;
    try {
        const response: AxiosResponse<ConfirmedBookingType> = yield call(bookingAPI.sendData, booking, balanceTransaction);
        yield put(bookingDataActionCreator(response.data));
        yield put(isCreatedPaymentActionCreator(true))
    } catch (error) {
        yield put(isCreatedPaymentActionCreator(false))
        yield put(bookingErrorActionCreator(error.message));
    }
}

function* bookingGetById(action: BookingGetByIdAction) {
    const { id, paymentIntent } = action.payload;
    yield put(resetBookingErrorActionCreator());
    try {
        const response: AxiosResponse<BookingDataByIdType> = yield call(bookingAPI.getBookings, id, paymentIntent);
        yield put(bookingDataByIdActionCreator(response.data));
    } catch (error) {
        yield put(bookingErrorActionCreator(error.message));
    }
}

function* confirmBookingFromSecurePayment(action: ConfirmBookingDependingSecurePaymentAction) {
    const { bookingId, paymentIntent } = action.payload;
    try {
        yield call(bookingAPI.confirmBookingFromPayment, paymentIntent, +bookingId);
        yield bookingGetById({
            type: BookingActionTypes.BOOKING_GET_DATA_BY_ID,
            payload: { id: bookingId, paymentIntent: paymentIntent }
        })
        yield isCreatedPaymentActionCreator(true);
    }
    catch (error) {
        yield put(bookingErrorActionCreator(error.message))
    }
}

function* watchSendBookings() {
    yield takeEvery(BookingActionTypes.BOOKING_SEND_DATA, bookingCreate);
}

function* watchGetBookingsById() {
    yield takeEvery(BookingActionTypes.BOOKING_GET_DATA_BY_ID, bookingGetById);
}

function* watchConfirmBookingFromSecurePayment() {
    yield takeEvery(BookingActionTypes.CONFIRM_BOOKING_DEPENDING_SECURE_PAYMENT, confirmBookingFromSecurePayment)
}

export default function* bookingSaga() {
    yield all([watchSendBookings(), watchGetBookingsById(), watchConfirmBookingFromSecurePayment()]);
}
