import axios from 'axios';

const token = localStorage.getItem('accessToken');

const settings = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
};
const baseURL = process.env.REACT_APP_API_URL;
// const baseURL = 'https://example-of-my-app.azurewebsites.net/';

export const instance = axios.create({
    baseURL,
    ...settings,
});
