import React, { useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Routes from './routes';
import { authenticatedSelector, authErrorsSelector } from './ducks/auth/selectors';
import AuthRoute from './common/AuthRoute';
import Layout from './components/LayoutForm/Layout';
import ErrorSnackbarComponent from './components/ErrorSnackbar/ErrorSnackbarComponent';
import { AuthActionTypes } from './ducks/auth/types';
import PrivateRoute from './common/PrivateRoute';
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import OnboardingPage from './pages/OnboardingPage';
import Profile from './pages/ProfilePage';
import LocationsFoundPage from './pages/LocationsFoundPage';
import PasswordRecoveryPage from './pages/PasswordRecoveryPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import SearchPromotion from './pages/SearchPromotionPage';
import VenueDetailsPage from './pages/VenueDetailsPage';
import BookingPage from './pages/BookingPage';
import BookingPageById from './pages/BookingPage/BookingPageById';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import AgreementPage from './pages/AgreementPage/AgreementPage';

const App: React.FC = () => {
    const isAuthenticated = useSelector(authenticatedSelector);
    const error = useSelector(authErrorsSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = process.env.REACT_APP_URL_NAME as string
        if (!isAuthenticated) {
            dispatch({ type: AuthActionTypes.CHECK_AUTH });
        }
    }, [dispatch, isAuthenticated]);

    return isAuthenticated !== null ? (
        <HashRouter>
            <CssBaseline/>
            {error && <ErrorSnackbarComponent error={error}/>}
            <Layout isAuthenticated={isAuthenticated}>
                <Switch>
                    <AuthRoute component={LoginPage} path={Routes.LOGIN}/>
                    <AuthRoute component={SignUpPage} path={Routes.SIGN_UP}/>
                    <Route exact component={OnboardingPage} path={Routes.ONBOARDING}/>
                    <Route component={SearchPromotion} path={Routes.SEARCH_PROMOTIONS}/>
                    <Route component={LocationsFoundPage} path={Routes.LOCATIONS_FOUND}/>
                    <Route component={ResetPasswordPage} path={Routes.RESET_PASSWORD}/>
                    <Route component={AgreementPage} path={Routes.AGREEMENT_PAGE}/>
                    <Route component={PasswordRecoveryPage} path={Routes.PASSWORD_RECOVERY}/>
                    <PrivateRoute component={BookingPageById} path={Routes.BOOKING_DETAILS_PAGE}/>
                    <Route component={VenueDetailsPage} path={Routes.VENUE_DETAILS_PAGE}/>
                    <PrivateRoute component={BookingPage} path={Routes.BOOKING_PAGE}/>
                    <PrivateRoute component={Profile} path={Routes.PROFILE}/>
                    <Route path={Routes.NOT_AUTHORIZED} render={() => <ErrorPage/>}/>
                    <Route render={() => <ErrorPage/>}/>
                </Switch>
            </Layout>
        </HashRouter>
    ) : null;
};

export default App;
