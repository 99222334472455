export enum SpaceActionTypes {
    GET_BOOKING_TIMES_BY_SPACE = '@@space/GET_BOOKING_TIMES_BY_SPACE',
    FETCH_CALCULATED_PERIOD_COST = '@@space/FETCH_CALCULATED_PERIOD_COST',
}

export type GetBookingTimeBySpaceAction = {
    type: SpaceActionTypes.GET_BOOKING_TIMES_BY_SPACE;
    payload: {
        spaceId: number;
    };
};

export interface FetchCalculatedPeriodCostAction {
    type: SpaceActionTypes.FETCH_CALCULATED_PERIOD_COST;
    payload: {
        date: {
            start: string;
            end: string;
        },
        spaceId: number
    };
}