import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Button, Card, createStyles, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UpdateUserDataType, UserType } from '../../ducks/user/types';

type CustomerDetailsPropsType = {
    setOpen: (switcher: boolean) => void;
    setUserDetails: (userDetail: UpdateUserDataType) => void;
    setOpenConfirmBooking: (switcher: boolean) => void;
    setShowingTable: (switcher: boolean) => void;
    handleNext: () => void;
    handleBack: () => void;
    addCustomer: (addCustomer: UpdateUserDataType) => void;
    customerDetails: UpdateUserDataType;
    deleteSpacesCustomer: () => void;
    user: UserType;
};

const validationSchema = Yup.object().shape({
    companyName: Yup.string().required('Company name is required'),
    addressLineFirst: Yup.string().required('Address line first name is required'),
    addressLineSecond: Yup.string().required('Address line second name is required'),
    email: Yup.string().required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    town: Yup.string().required('Town number is required'),
    postCode: Yup.string().required('Post code number is required'),
    productInformation: Yup.string().required('Product information is required'),
    campaignInformation: Yup.string().required('Campaign information is required'),
});

const CustomerDetails: React.FC<CustomerDetailsPropsType> = React.memo(({
                                                                            setOpen,
                                                                            setUserDetails,
                                                                            setOpenConfirmBooking,
                                                                            setShowingTable,
                                                                            handleNext,
                                                                            handleBack,
                                                                            addCustomer,
                                                                            customerDetails,
                                                                            deleteSpacesCustomer,
                                                                            user,
                                                                        }) => {
    const classes = useStyles();
    const initialErrors = {};

    const handleClose = () => {
        setOpen(false);
        setShowingTable(true);
        handleBack();
        deleteSpacesCustomer();
    };

    const initialValues = {
        companyName: customerDetails.companyName || user.companyName,
        addressLineFirst: customerDetails.addressLineFirst || user.addressLineFirst,
        addressLineSecond: customerDetails.addressLineSecond || user.addressLineSecond,
        email: customerDetails.email || user.email,
        phoneNumber: customerDetails.phoneNumber || user.phoneNumber,
        town: customerDetails.town || user.town,
        postCode: customerDetails.postCode || user.postCode,
        productInformation: customerDetails.productInformation,
        campaignInformation: customerDetails.campaignInformation,
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            setUserDetails(values);
            setOpenConfirmBooking(true);
            handleNext();
            setOpen(false);
            addCustomer(values);
        },
        validationSchema,
        initialErrors,
        enableReinitialize: true,
    });

    return (
        <Card>
            <form onSubmit={formik.handleSubmit} className={classes.dialogContainer}>
                <p
                    style={{
                        textAlign: 'center',
                        fontFamily: 'Example',
                        fontSize: 20,
                        color: '#01458E',
                        fontWeight: 'bold',
                    }}
                >
                    Enter your details
                </p>
                <div>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={formik.values.companyName}
                        label="Company Name"
                        name="companyName"
                        placeholder="Company Name"
                        margin="dense"
                        error={!!(formik.errors.companyName && formik.touched.companyName)}
                        onChange={formik.handleChange}
                        InputProps={{
                            startAdornment: ' ',
                        }}
                    />
                </div>
                <div className={classes.dialogInput}>
                    <TextField
                        style={{ marginRight: 10 }}
                        fullWidth
                        variant="outlined"
                        value={formik.values.email}
                        label="Email"
                        name="email"
                        error={!!(formik.errors.email && formik.touched.email)}
                        placeholder="Email"
                        disabled={true}
                        margin="dense"
                        onChange={formik.handleChange}
                        InputProps={{
                            startAdornment: ' ',
                        }}
                    />
                    <TextField
                        style={{ marginLeft: 10 }}
                        fullWidth
                        variant="outlined"
                        value={formik.values.phoneNumber}
                        label="Phone number"
                        name="phoneNumber"
                        error={!!(formik.errors.phoneNumber && formik.touched.phoneNumber)}
                        placeholder="Phone number"
                        margin="dense"
                        onChange={formik.handleChange}
                        InputProps={{
                            startAdornment: ' ',
                        }}
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={formik.values.addressLineFirst}
                        label="Address Line 1"
                        name="addressLineFirst"
                        placeholder="Address Line 1"
                        margin="dense"
                        error={!!(formik.errors.addressLineFirst && formik.touched.addressLineFirst)}
                        onChange={formik.handleChange}
                        InputProps={{
                            startAdornment: ' ',
                        }}
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={formik.values.addressLineSecond}
                        label="Address Line 2"
                        name="addressLineSecond"
                        placeholder="Address Line 2"
                        margin="dense"
                        error={!!(formik.errors.addressLineSecond && formik.touched.addressLineSecond)}
                        onChange={formik.handleChange}
                        InputProps={{
                            startAdornment: ' ',
                        }}
                    />
                </div>
                <div className={classes.dialogInput}>
                    <TextField
                        style={{ marginRight: 10 }}
                        fullWidth
                        variant="outlined"
                        value={formik.values.town}
                        label="Town"
                        name="town"
                        error={!!(formik.errors.town && formik.touched.town)}
                        placeholder="town"
                        margin="dense"
                        onChange={formik.handleChange}
                        InputProps={{
                            startAdornment: ' ',
                        }}
                    />
                    <TextField
                        style={{ marginLeft: 10 }}
                        fullWidth
                        variant="outlined"
                        value={formik.values.postCode}
                        label="Post code"
                        name="postCode"
                        error={!!(formik.errors.postCode && formik.touched.postCode)}
                        placeholder="Post code"
                        margin="dense"
                        onChange={formik.handleChange}
                        InputProps={{
                            startAdornment: ' ',
                        }}
                    />
                </div>
                <div className={classes.textareaContainer}>
                    <TextField
                        variant={'outlined'}
                        defaultValue={customerDetails.productInformation}
                        label="Product information"
                        style={{ minWidth: '100%', maxWidth: '100%', maxHeight: '100%' }}
                        name={'productInformation'}
                        placeholder="Please provide an overview of your company’s product/services"
                        multiline
                        error={!!(formik.errors.productInformation && formik.touched.productInformation)}
                        onChange={formik.handleChange}
                        rows={2}
                        rowsMax={3}
                        InputProps={{
                            startAdornment: ' ',
                        }}
                    />
                </div>
                <div className={classes.textareaContainer}>
                    <TextField
                        variant={'outlined'}
                        defaultValue={customerDetails.campaignInformation}
                        style={{ minWidth: '100%', maxWidth: '100%', maxHeight: '100%' }}
                        label={'Campaign information'}
                        name="campaignInformation"
                        placeholder="Campaign information"
                        onChange={formik.handleChange}
                        error={!!(formik.errors.campaignInformation && formik.touched.campaignInformation)}
                        multiline
                        rows={2}
                        rowsMax={3}
                        InputProps={{
                            startAdornment: ' ',
                        }}
                    />
                </div>
                <div className={classes.buttonContainer}>
                    <Button className={classes.button} variant="contained" onClick={handleClose} color="primary">
                        back
                    </Button>
                    <Button className={classes.button} variant="contained" color="primary" type="submit">
                        next
                    </Button>
                </div>
            </form>
        </Card>
    );
});

export default CustomerDetails;

const useStyles = makeStyles(() =>
    createStyles({
        dialogContainer: {
            padding: 10,
            borderRadius: 50,
            '& >div': {
                margin: '15px 0 15px 0',
            },
        },
        dialogInput: {
            display: 'flex',
        },
        textareaContainer: {
            marginBottom: 40,
        },
        textarea: {
            minWidth: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-around',
            marginBottom: 20,
        },
        button: {
            margin: 10,
            width: 250,
        },
    }),
);

