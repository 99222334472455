import { formatDate } from './formatDate';
import { SpacesType } from '../ducks/venue/types';

type nextStepOfPaymentPropsType = {
    spacesArray: SpacesType[];
    setOpen: (isOpen: boolean) => void;
    setShowingTable: (show: boolean) => void;
    handleNext: () => void;
    addSpacesCustomer:(addSpacesCustomer: Array<SpacesType>)=>void
    setDuplicatedArray:(dup: Array<number>)=>void

}

export const validationDateOfPaymont = ({
                                            spacesArray,
                                            setOpen,
                                            setShowingTable,
                                            handleNext,
                                            setDuplicatedArray,
                                            addSpacesCustomer,
                                            ...rest
                                        }: nextStepOfPaymentPropsType) => {
    const emptyDate = spacesArray.find(space => (space.checkInDate === null || space.checkOutDate === null));
    let isDuplicate = false
    const dupArray: number[] = [];

    if (emptyDate) {
        isDuplicate = true;
        dupArray.push(emptyDate.index);
        setDuplicatedArray(Array.from(new Set(dupArray)))
    } else {
        for (const space of spacesArray) {
            for (let i = 0; i < spacesArray.length; i++) {
                if ((space.index !== spacesArray[i].index) && (space.id === spacesArray[i].id)) {
                    if (formatDate(spacesArray[i].checkInDate as Date) >= formatDate(space.checkInDate as Date) &&
                        formatDate(spacesArray[i].checkInDate as Date) <= formatDate(space.checkOutDate as Date)) {
                        dupArray.push(space.index);
                        isDuplicate = true;
                    } else if (formatDate(spacesArray[i].checkOutDate as Date) >= formatDate(space.checkInDate as Date) &&
                        formatDate(spacesArray[i].checkOutDate as Date) <= formatDate(space.checkOutDate as Date)) {
                        dupArray.push(space.index);
                        isDuplicate = true;
                    } else if (formatDate(space.checkInDate as Date) >= formatDate(spacesArray[i].checkInDate as Date) &&
                        formatDate(space.checkInDate as Date) <= formatDate(spacesArray[i].checkOutDate as Date)) {
                        dupArray.push(space.index);
                        isDuplicate = true;
                    } else if (formatDate(space.checkOutDate as Date) >= formatDate(spacesArray[i].checkInDate as Date) &&
                        formatDate(space.checkOutDate as Date) <= formatDate(spacesArray[i].checkOutDate as Date)) {
                        dupArray.push(space.index);
                        isDuplicate = true;
                    }
                }
            }
        }
    }
    if (!isDuplicate) {
        setOpen(true);
        setShowingTable(false);
        handleNext();
        addSpacesCustomer(spacesArray)
    } else {
        setDuplicatedArray(Array.from(new Set(dupArray)))
    }
}
