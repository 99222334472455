import React, { useCallback } from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createStyles,
    Grid,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Routes from '../../routes';
import PasswordField from '../../common/PasswordField';
import { AuthActionTypes } from '../../ducks/auth/types';
import { signUpCloseActionCreator } from '../../ducks/auth/actions';
import { authenticatedSelector, loadingSelector } from '../../ducks/auth/selectors';

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
});

const initialValues = {
    email: '',
    password: '',
};

const LoginPage: React.FC = () => {
    const classes = useStyles();
    const initialErrors = {};
    const isAuthenticated = useSelector(authenticatedSelector);

    const loading = useSelector(loadingSelector);

    const dispatch = useDispatch();
    const history = useHistory();

    const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues,
        onSubmit: ({ email, password }) => {
            dispatch({ type: AuthActionTypes.LOGIN, payload: { email, password, history } });
        },
        validationSchema,
        initialErrors,
        enableReinitialize: true,
    });

    const signUpHandler = useCallback(() => {
        dispatch(signUpCloseActionCreator());
        history.push(Routes.SIGN_UP);
    }, [dispatch, history]);

    if (isAuthenticated) {
        history.push(Routes.PROFILE);
    }

    return (
        <div>
            <Typography align="center" variant="h4" component="h1" className={classes.authTitle}>
            Welcome to The SpaceandPeople Promotional Kiosk Online Booking Portal
            </Typography>
            <Typography align="center" variant="h4" component="h2" className={classes.loginTitle}>
                Please sign in to proceed
            </Typography>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={6}>
                    <Card variant="outlined">
                        <CardHeader title="Sign in"/>
                        <form onSubmit={handleSubmit}>
                            <CardContent>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    error={!!(errors.email && touched.email)}
                                    label="Email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.email && touched.email && errors.email}
                                    margin="dense"
                                />
                                <PasswordField
                                    fullWidth
                                    variant="outlined"
                                    error={!!(errors.password && touched.password)}
                                    label="Password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.password && touched.password && errors.password}
                                    margin="dense"
                                />
                            </CardContent>
                            <CardActions>
                                <Grid container direction="row" justify="space-around">
                                    <Button
                                        type="submit"
                                        className={classes.button}
                                        variant={'contained'}
                                        disabled={loading}
                                        color={'primary'}>
                                        Sign In
                                    </Button>
                                    <Button
                                        type="button"
                                        className={classes.button}
                                        onClick={signUpHandler}
                                        disabled={loading}
                                    >
                                        create a new account
                                    </Button>
                                    <Button className={classes.button}
                                            onClick={() => history.push(Routes.RESET_PASSWORD)} disabled={loading}>
                                        Forgot password
                                    </Button>
                                </Grid>
                            </CardActions>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default LoginPage;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        authTitle: {
            marginTop: 15,
            marginBottom: 12,
            fontFamily: 'Example',
        },
        loginTitle: {
            marginBottom: 15,
            fontFamily: 'Example',
        },
        button: {}
    }),
);
