import React from 'react';
import { useFormik } from 'formik';
import { CardContent, CardActions, Grid, Button } from '@material-ui/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Routes from '../../../routes';
import PasswordField from '../../../common/PasswordField';
import { AuthActionTypes } from '../../../ducks/auth/types';
import { authenticatedSelector } from '../../../ducks/auth/selectors';

type PasswordRecoveryFormPropsType = {
    code: string;
};

const validationSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Password must contain at least 8 characters').required('Field is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Password does not match')
        .required('Confirm your password'),
});

const PasswordRecoveryForm: React.FC<PasswordRecoveryFormPropsType> = ({ code }: PasswordRecoveryFormPropsType) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(authenticatedSelector);

    const initialValues = {
        password: '',
        confirmPassword: '',
        recoveryCode: code,
    };

    const form = useFormik({
        initialValues,
        onSubmit: ({ password, recoveryCode }) => {
            dispatch({ type: AuthActionTypes.RECOVER_PASSWORD, payload: { password, recoveryCode } });
        },
        validationSchema,
    });

    return (
        <form onSubmit={form.handleSubmit}>
            <CardContent>
                <PasswordField
                    fullWidth
                    variant="outlined"
                    error={!!(form.errors.password && form.touched.password)}
                    label="Password"
                    name="password"
                    value={form.values.password}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    helperText={form.errors.password && form.touched.password && form.errors.password}
                    margin="dense"
                />

                <PasswordField
                    fullWidth
                    variant="outlined"
                    error={!!(form.errors.confirmPassword && form.touched.confirmPassword)}
                    label="Confirm password"
                    name="confirmPassword"
                    value={form.values.confirmPassword}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    helperText={
                        form.errors.confirmPassword && form.touched.confirmPassword && form.errors.confirmPassword
                    }
                    margin="dense"
                />
            </CardContent>
            <CardActions>
                <Grid container direction="row" justify="space-around">
                    <Button variant={'contained'} color={'primary'} type="submit">
                        Save
                    </Button>
                    <Button
                        type="button"
                        disabled={!!isAuthenticated}
                        onClick={() => history.push(Routes.LOGIN)}
                    >
                        Back to login
                    </Button>
                </Grid>
            </CardActions>
        </form>
    );
};

export default PasswordRecoveryForm;
