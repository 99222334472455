import React from 'react';
import { Grid } from '@material-ui/core';

interface IProps {
    children: React.ReactNode;
}

const PageWrapper: React.FC<IProps> = ({ children }: IProps) => {
    return (
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
                <div>{children}</div>
            </Grid>
        </Grid>
    );
};

export default PageWrapper;
