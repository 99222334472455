import { BookingDataByIdType, ConfirmedBookingType } from '../../api/types';
import { BookingActionTypes } from './types';
import { SpacesType } from '../venue/types';
import { UpdateUserDataType } from '../user/types';

export const bookingDataActionCreator = (data: ConfirmedBookingType) =>
    ({
        type: BookingActionTypes.BOOKING_SUCCESS_DATA,
        payload: {
            data,
        },
    } as const);

export const confirmedBookingDataActionCreator = (data: ConfirmedBookingType) =>
    ({
        type: BookingActionTypes.CONFIRMED_BOOKING_SUCCESS_DATA,
        payload: {
            data,
        },
    } as const);

export const bookingDataByIdActionCreator = (bookingByIdData: BookingDataByIdType) =>
    ({
        type: BookingActionTypes.GET_BOOKING_SPACES_BY_ID,
        payload: {
            bookingByIdData,
        },
    } as const);

export const bookingDeleteByIdActionCreator = () =>
    ({
        type: BookingActionTypes.DELETE_BOOKING_SPACES_BY_ID,
        payload: { bookingByIdData: {} as BookingDataByIdType },
    } as const);

export const addCustomerActionCreator = (customerDetails: UpdateUserDataType) =>
    ({
        type: BookingActionTypes.ADD_CUSTOMER,
        payload: { customerDetails },
    } as const);

export const addCustomerSpacesActionCreator = (customerSpaces: Array<SpacesType>) =>
    ({
        type: BookingActionTypes.ADD_SPACES_CUSTOMER,
        payload: { customerSpaces },
    } as const);

export const deleteCustomerActionCreator = () =>
    ({
        type: BookingActionTypes.DELETE_CUSTOMER,
    } as const);

export const deleteSpaceActionCreator = () =>
    ({
        type: BookingActionTypes.DELETE_SPACES_CUSTOMER,
    } as const);

export const bookingErrorActionCreator = (errors: [any] | null) =>
    ({
        type: BookingActionTypes.BOOKING_ERRORS,
        payload: { errors },
    } as const);

export const resetBookingErrorActionCreator = () =>
    ({
        type: BookingActionTypes.RESET_ERROR,
    } as const);

export const getBookingSpaceTimeByIdActionCreator = (times: any) =>
    ({
        type: BookingActionTypes.GET_BOOKING_SPACES_BY_ID,
        payload: { bookingByIdData: times },
    } as const);

export const isCreatedPaymentActionCreator = (created: boolean) =>
    ({
        type: BookingActionTypes.IS_CREATED_PAYMENT,
        payload: { created },
    } as const);

export const canceledPaymentActionCreator = (canceled: boolean) =>
    ({
        type: BookingActionTypes.CANCELED_PAYMENT,
        payload: { canceled },
    } as const);
