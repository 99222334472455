import { BookingDataType } from '../../api/types';
import {
    addCustomerActionCreator,
    addCustomerSpacesActionCreator,
    bookingDataActionCreator,
    bookingDataByIdActionCreator,
    bookingDeleteByIdActionCreator,
    bookingErrorActionCreator, confirmedBookingDataActionCreator, isCreatedPaymentActionCreator,
    deleteCustomerActionCreator,
    deleteSpaceActionCreator,
    getBookingSpaceTimeByIdActionCreator,
    resetBookingErrorActionCreator, canceledPaymentActionCreator,
} from './action';

export enum BookingActionTypes {
    BOOKING_SUCCESS_DATA = '@@book/BOOKING_SUCCESS_DATA',
    CONFIRMED_BOOKING_SUCCESS_DATA = '@@book/CONFIRMED_BOOKING_SUCCESS_DATA',
    BOOKING_SEND_DATA = '@@book/BOOK_SEND_DATA',
    CONFIRM_BOOKING_DEPENDING_ON_PAYMENT = '@@book/CONFIRM_BOOKING_DEPENDING_ON_PAYMENT',
    CONFIRM_BOOKING_DEPENDING_SECURE_PAYMENT = '@@book/CONFIRM_BOOKING_DEPENDING_SECURE_PAYMENT',

    BOOKING_GET_DATA_BY_ID = '@@book/BOOKING_GET_DATA_BY_ID',
    GET_BOOKING_SPACES_BY_ID = '@@book/GET_BOOKING_SPACES_BY_ID',
    DELETE_BOOKING_SPACES_BY_ID = '@@book/DELETE_BOOKING_SPACES_BY_ID',
    ADD_CUSTOMER = '@@book/ADD_CUSTOMER',
    DELETE_CUSTOMER = '@@book/DELETE_CUSTOMER',
    ADD_SPACES_CUSTOMER = '@@book/ADD_SPACES_CUSTOMER',
    DELETE_SPACES_CUSTOMER = '@@book/DELETE_SPACES_CUSTOMER',
    BOOKING_ERRORS = '@@book/BOOKING_ERRORS',
    RESET_ERROR = '@@book/RESET_ERROR',
    SET_BOOKING_TIME = '@@book/SET_BOOKING_TIME',
    IS_CREATED_PAYMENT='@@book/CREATED_PAYMENT',
    CANCELED_PAYMENT='@@book/CANCELED_PAYMENT'
}

export type BookingAction = {
    type: BookingActionTypes.BOOKING_SEND_DATA;
    payload: { booking: BookingDataType; balanceTransaction?: string, };
};

export type BookingGetByIdAction = {
    type: BookingActionTypes.BOOKING_GET_DATA_BY_ID;
    payload: { id: string; paymentIntent: string; };
};

export type ConfirmBookingDependingOnPaymentAction = {
    type: BookingActionTypes.CONFIRM_BOOKING_DEPENDING_ON_PAYMENT;
    payload: { status: string, balanceTransaction: string, id: number };
};

export type ConfirmBookingDependingSecurePaymentAction = {
    type: BookingActionTypes.CONFIRM_BOOKING_DEPENDING_SECURE_PAYMENT;
    payload: { bookingId:string, paymentIntent:string };
};

export type BookingActionsType =
    | ReturnType<typeof bookingDataActionCreator>
    | ReturnType<typeof bookingDataByIdActionCreator>
    | ReturnType<typeof bookingDeleteByIdActionCreator>
    | ReturnType<typeof addCustomerActionCreator>
    | ReturnType<typeof deleteCustomerActionCreator>
    | ReturnType<typeof addCustomerSpacesActionCreator>
    | ReturnType<typeof deleteSpaceActionCreator>
    | ReturnType<typeof bookingErrorActionCreator>
    | ReturnType<typeof resetBookingErrorActionCreator>
    | ReturnType<typeof getBookingSpaceTimeByIdActionCreator>
    | ReturnType<typeof confirmedBookingDataActionCreator>
    | ReturnType<typeof canceledPaymentActionCreator>
    | ReturnType<typeof isCreatedPaymentActionCreator>;
