import { AuthActionTypes } from './types';

export const loginSuccessActionCreator = () =>
    ({
        type: AuthActionTypes.LOGIN_SUCCESS,
    } as const);

export const loginErrorActionCreator = (errors: [any] | null) =>
    ({
        type: AuthActionTypes.LOGIN_ERROR,
        payload: { errors },
    } as const);

export const signUpSuccessActionCreator = () =>
    ({
        type: AuthActionTypes.SIGN_UP_SUCCESS,
        payload: {},
    } as const);

export const signUpErrorActionCreator = (errors: [any] | null) =>
    ({
        type: AuthActionTypes.SIGN_UP_ERROR,
        payload: { errors },
    } as const);

export const signUpCloseActionCreator = () =>
    ({
        type: AuthActionTypes.SIGN_UP_CLOSE,
    } as const);

export const logoutActionCreator = () =>
    ({
        type: AuthActionTypes.LOGOUT_SUCCESS,
    } as const);

export const requestActionCreator = () =>
    ({
        type: AuthActionTypes.REQUEST,
    } as const);

export const resetErrorActionCreator = () =>
    ({
        type: AuthActionTypes.RESET_ERROR,
    } as const);

export const resetPasswordSuccessActionCreator = () =>
    ({
        type: AuthActionTypes.RESET_PASSWORD_SUCCESS,
    } as const);

export const resetPasswordModalShowActionCreator = () =>
    ({
        type: AuthActionTypes.RESET_PASSWORD_MODAL_SHOWN,
    } as const);

export const resetPasswordErrorActionCreator = (errors: any) =>
    ({
        type: AuthActionTypes.RESET_PASSWORD_ERROR,
        payload: {
            errors
        }
    } as const);

export const recoverPasswordSuccessActionCreator = (isSuccess:boolean) =>
    ({
        type: AuthActionTypes.RECOVER_PASSWORD_SUCCESS,
        payload:{
            isSuccess
        }
    } as const);


export const updatePasswordSuccessActionCreator = (isSuccess:boolean) =>
    ({
        type: AuthActionTypes.UPDATE_PASSWORD_SUCCESS,
        payload:{
            isSuccess
        }
    } as const);

export const updatePasswordErrorActionCreator = (errors: any) =>
    ({
        type: AuthActionTypes.UPDATE_PASSWORD_ERROR,
        payload: {
            errors
        }
    } as const);



export const updateUserDataSuccessActionCreator = (isSuccess:boolean) =>
    ({
        type: AuthActionTypes.UPDATE_USER_DATA_SUCCESS,
        payload:{
            isSuccess
        }
    } as const);

export const updateUserDataErrorActionCreator = (errors: any) =>
    ({
        type: AuthActionTypes.UPDATE_USER_DATA_ERROR,
        payload: {
            errors
        }
    } as const);


