import { instance } from './axios';
import { BookingDataByIdType, BookingDataType, ConfirmedBookingType, ResponseType } from './types';
import { AxiosResponse } from 'axios';

export const bookingAPI = {
    sendData(
        bookingCreate: BookingDataType,
        balanceTransaction?: string,
    ): Promise<AxiosResponse<ResponseType<ConfirmedBookingType>>> {
        return instance.post<ResponseType<ConfirmedBookingType>>('bookings/', {
            booking: bookingCreate,
            balanceTransaction: balanceTransaction,
        });
    },
    getBookings(id: string, paymentIntent?: string): Promise<AxiosResponse<ResponseType<BookingDataByIdType>>> {
        return instance.get<ResponseType<BookingDataByIdType>>(`bookings/${id}`);
    },
    confirmBookingFromPayment(paymentIntentId:string, id: number) {
        return instance.post(`bookings/confirm/${id}`,
            {
                paymentIntentId,
            }
        )
    }
};