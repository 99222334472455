import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { resetErrorActionCreator } from '../../ducks/auth/actions';
import { resetBookingErrorActionCreator } from '../../ducks/booking/action';

type ErrorPropsType = {
    error: any
}

const ErrorSnackbarComponent: React.FC<ErrorPropsType> = ({ error }) => {
    //const error = useSelector<RootState, [any] | null>((state) => state.auth.errors);
    const dispatch = useDispatch();

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(resetErrorActionCreator());
        dispatch(resetBookingErrorActionCreator());
    };

    const isOpen = error !== null;
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={isOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={error}
                action={
                    <React.Fragment>
                        <Button color="primary" size="small" onClick={handleClose}>
                            UNDO
                        </Button>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    );
};

export default ErrorSnackbarComponent;
