import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createStyles,
    Grid,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Routes from '../../routes';
import { AuthActionTypes } from '../../ducks/auth/types';
import { loadingSelector, signUpSucceededSelector } from '../../ducks/auth/selectors';
import PasswordField from '../../common/PasswordField';
import Modal from '../../components/Modal';

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string().min(8, 'Password must contain at least 8 characters').required('Password is required'),
    confirmPassword: Yup.string()
        .required('Confirm your password')
        .oneOf([Yup.ref('password')], 'Password does not match'),
});

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
};

const SignUpPage: React.FC = () => {
    const classes = useStyles();
    const signUpSucceeded = useSelector(signUpSucceededSelector);
    const loading = useSelector(loadingSelector);
    const history = useHistory();
    const dispatch = useDispatch();
    const accessToken = localStorage.getItem('accessToken');
    const [successModalShown, setSuccessModalShown] = useState(false);
    const initialErrors = {};

    const showSuccessModal = () => {
        setSuccessModalShown(true);
        return;
    };

    const hideSuccessModal = useCallback(() => {
        setSuccessModalShown(false);
        dispatch({
            type: AuthActionTypes.SIGN_UP_CLOSE,
        });
        history.push(Routes.LOGIN);
    }, [dispatch, history]);

    useEffect(() => {
        if (signUpSucceeded) {
            showSuccessModal();
            history.push(Routes.LOGIN);
        } else if (accessToken) {
            history.push(Routes.PROFILE);
        }
    }, [history, signUpSucceeded, accessToken]);

    const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues,
        onSubmit: ({ firstName, lastName, email, password }) => {
            dispatch({
                type: AuthActionTypes.SIGN_UP,
                payload: {
                    firstName,
                    lastName,
                    email,
                    password,
                },
            });
        },
        validationSchema,
        initialErrors,
        enableReinitialize: true,
    });

    if (signUpSucceeded) {
        history.push(Routes.LOGIN);
    }

    return (
        <div>
            <Typography align="center" variant="h3" component="h1" className={classes.authTitle}>
                Welcome
            </Typography>
            <Typography align="center" variant="h4" component="h2" className={classes.signUpTitle}>
                Please sign up to proceed
            </Typography>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={6}>
                    <Card variant="outlined">
                        <CardHeader title="Sign up"/>
                        <form onSubmit={handleSubmit}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            error={!!(errors.firstName && touched.firstName)}
                                            label="First Name"
                                            name="firstName"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={errors.firstName && touched.firstName && errors.firstName}
                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            error={!!(errors.lastName && touched.lastName)}
                                            label="Last Name"
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={errors.lastName && touched.lastName && errors.lastName}
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    error={!!(errors.email && touched.email)}
                                    label="Email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.email && touched.email && errors.email}
                                    margin="dense"
                                />
                                <PasswordField
                                    fullWidth
                                    variant="outlined"
                                    error={!!(errors.password && touched.password)}
                                    label="Password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.password && touched.password && errors.password}
                                    margin="dense"
                                />
                                <PasswordField
                                    fullWidth
                                    variant="outlined"
                                    error={!!(errors.confirmPassword && touched.confirmPassword)}
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={
                                        errors.confirmPassword && touched.confirmPassword && errors.confirmPassword
                                    }
                                    margin="dense"
                                />
                            </CardContent>
                            <CardActions>
                                <Grid container direction="row" justify="space-around">
                                    <Button type="submit" variant={'contained'} color={'primary'} disabled={loading} className={classes.button}>
                                        Sign Up
                                    </Button>
                                    <Button
                                        type="button"
                                        disabled={loading}
                                        className={classes.button}
                                        onClick={() => history.push(Routes.LOGIN)}
                                    >
                                        already have an account?
                                    </Button>
                                    <Button disabled={loading} className={classes.button} onClick={() => history.push(Routes.RESET_PASSWORD)}>
                                        Forgot password
                                    </Button>
                                </Grid>
                            </CardActions>
                        </form>
                        <Modal handleClose={hideSuccessModal} isOpen={successModalShown} title="">
                            <h3>Almost done...</h3>
                            <p>
                                We sent confirmation link to the email. Please follow it in order to activate your
                                account.
                            </p>
                            <Button onClick={hideSuccessModal} type="button" variant="contained" color="primary">
                                Done
                            </Button>
                        </Modal>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default SignUpPage;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        authTitle: {
            marginTop: 5,
            marginBottom: 2,
        },
        signUpTitle: {
            marginBottom: 5,
        },
        button: {
        }
    }),
);
