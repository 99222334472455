import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import StripePage from './stripePage';
import { BookingDataType } from '../../api/types';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

const PaymentPage: React.FC<PaymentPagePropsType> = ({
                                                         handleBack,
                                                         openConfirmBooking,
                                                         setOpenPayment,
                                                         dataToSend,
                                                         setOpen,
                                                         isPartialPayment,
                                                         additionalCharge,
                                                     }) => {
    return (
        <Elements stripe={stripePromise}>
            <StripePage
                handleBack={handleBack}
                openConfirmBooking={openConfirmBooking}
                setOpenPayment={setOpenPayment}
                dataToSend={dataToSend}
                setOpen={setOpen}
                isPartialPayment={isPartialPayment}
                additionalCharge={additionalCharge}
            />
        </Elements>
    );
};

type PaymentPagePropsType = {
    handleBack: () => void;
    openConfirmBooking: (switcher: boolean) => void;
    setOpenPayment: (switcher: boolean) => void;
    dataToSend: BookingDataType;
    setOpen: (switcher: boolean) => void
    isPartialPayment: boolean
    additionalCharge:string
};

export default PaymentPage;
