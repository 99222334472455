import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { authenticatedSelector, updatePasswordSelector, updateUserSelector } from '../../ducks/auth/selectors';
import { AuthActionTypes } from '../../ducks/auth/types';
import Routes from '../../routes';
import ChangePasswordForm from './changePasswordComponent/ChangePasswordForm';
import { updatePasswordSuccessActionCreator, updateUserDataSuccessActionCreator } from '../../ducks/auth/actions';
import ChangeUserForm from './changeUserForm/ChangeUserComponent';
import { selectUserState } from '../../ducks/user/selectors';

const Profile = () => {
    const classes = useStyles();
    const user = useSelector(selectUserState);
    const isAuthenticated = useSelector(authenticatedSelector);
    const updatedPassword = useSelector(updatePasswordSelector);
    const updatedUser = useSelector(updateUserSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isChangePassword, setIsChangePassword] = useState<boolean>(false)
    const [isChangeUserData, setIsChangeUserData] = useState<boolean>(false)

    useEffect(() => {
        if (isAuthenticated) dispatch({ type: AuthActionTypes.CHECK_AUTH });
        if (updatedPassword) {
            setIsChangePassword(false)
            setTimeout(() => {
                dispatch(updatePasswordSuccessActionCreator(false))
            }, 2000)
        }
        if (updatedUser) {
            setIsChangeUserData(false)
            setTimeout(() => {
                dispatch(updateUserDataSuccessActionCreator(false))
            }, 2000)
        }
    }, [dispatch, isAuthenticated, updatedPassword, updatedUser,]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Card className={classes.root}>
                {updatedPassword &&
                <div style={{ color: 'green', fontSize: 20, fontFamily: 'Example', textAlign: 'center' }}>Your password
                    has been
                    changed </div>
                }
                {updatedUser &&
                <div style={{ color: 'green', fontSize: 20, fontFamily: 'Example', textAlign: 'center' }}>User data
                    has been
                    changed </div>
                }
                <div style={{ width: '75%', margin: '30px 0 20px 20px' }}>
                    {user.firstName ?<ChangeUserForm
                        disabled={isChangeUserData}
                        isChangeUser={setIsChangeUserData}
                        user={user}
                    /> : null}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button
                        style={{ margin: 10 }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            history.push(Routes.SEARCH_PROMOTIONS);
                        }}
                    >
                        Search space
                    </Button>

                    {!isChangePassword &&
                    <Button
                        style={{ margin: 10 }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setIsChangePassword(true);
                        }}
                    >
                        Change password
                    </Button>
                    }
                    {isChangePassword && <Button
                        style={{ margin: 10 }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setIsChangePassword(false);
                        }}
                    >
                        Cancel
                    </Button>}
                </div>
                {isChangePassword && <div style={{ margin: 20 }}>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} sm={10}>
                            <Card variant="outlined">
                                <ChangePasswordForm isChanged={setIsChangePassword}/>
                            </Card>
                        </Grid>
                    </Grid>
                </div>}

            </Card>
        </div>
    );
};

export default Profile;

const useStyles = makeStyles({
    root: {
        margin: 40,
        minWidth: 700,
        minHeight: 400,
    },
});
