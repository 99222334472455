import { instance } from './axios';
import {
    MeResponseType,
    RecoveryPasswordResponseType,
    ResponseType,
    SignInParamsType,
    SignInResponseType,
    SignUpParamsType,
    VerifyPasswordResetTokenResponseType
} from './types';

export const authAPI = {
    me(checkTokenParams: { params: { accessToken: string } }) {
        return instance.get<ResponseType<MeResponseType>>('users/me', checkTokenParams);
    },
    signIn(signInParams: SignInParamsType) {
        return instance.post<ResponseType<SignInResponseType>>('users/signIn', signInParams);
    },
    signUp(signUpParams: SignUpParamsType) {
        return instance.post<ResponseType<any>>('users', signUpParams);
    },
    recoveryPassword(email: string) {
        return instance.post<ResponseType<RecoveryPasswordResponseType>>('users/resetPassword', { email });
    },
    verifyPasswordResetToken(password: string, token: string) {
        return instance.post<ResponseType<VerifyPasswordResetTokenResponseType>>('users/verifyPasswordResetToken', {
            token,
            password
        })
    },
    updatePassword(currentPassword: string, newPassword: string) {
        return instance.put<ResponseType<any>>(`users/update`, { currentPassword, newPassword })
    },
    updateUserData(email: string, firstName: string, lastName: string) {
        return instance.put<ResponseType<any>>(`users/update`, { email, firstName, lastName })
    },
};
