import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    AppBar,
    Button,
    Checkbox,
    createStyles,
    FormControlLabel,
    Grid,
    InputAdornment,
    LinearProgress,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
    Theme,
    Toolbar,
    Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { DateRange } from '@material-ui/lab';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

import SelectedDateComponent from '../../components/SelectedDateComponent/SelectedDateComponent';
import LocationCardComponent from './LocationCardComponent/LocationCardComponent';
import { searchDataSelector, venuesSelector } from '../../ducks/venue/selectors';
import { VenuesActionTypes, VenueType } from '../../ducks/venue/types';
import { IInitialValues } from '../../components/forms/bookYourSpace/BookYourSpaceForm';
import { setSearchDataAC } from '../../ducks/venue/actions';
import { bookingDeleteByIdActionCreator } from '../../ducks/booking/action';
import { bookingDataSelector } from '../../ducks/booking/selectors';

const validationSchema = Yup.object().shape({
    point: Yup.string().required('Point is required'),
});

const LocationsFoundPage = () => {
    const classes = useStyles();
    const venues = useSelector(venuesSelector);
    const searchData = useSelector(searchDataSelector);
    const bookingData = useSelector(bookingDataSelector);
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(useLocation().search);
    const checkin = queryParams.get('checkin');
    const checkout = queryParams.get('checkout');
    const point = queryParams.get('point');
    const checkInDate = new Date(checkin as string);
    const checkOutDate = new Date(checkout as string);
   // const [selectedDate, handleDateChange] = React.useState<DateRange<Date | null>>([checkInDate, checkOutDate]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const searchDataPoint = searchData.search;
    const commonWords = ['the', 'centre', 'a', '', 'and'];

    const searchString = `${searchDataPoint || point}`
        .toLowerCase()
        .replaceAll(/,/g, ' ')
        .split(' ');

    const searchCommonWords = (searchStringList: string[], commonWordsList: string[]) => {
        const filtered = searchStringList.filter(el => {
             return commonWordsList.indexOf(el) === -1;
         });
        return filtered;
    };

    const initialValues: IInitialValues = {
        point: searchDataPoint || (point as string),
      //  date: [checkInDate, checkOutDate],
    };

    const formik = useFormik({
        validate: (values: IInitialValues) => {
            if (!values.point) {
                return {
                    point: 'Point is required',
                };
            }
        },
        initialValues,

        onSubmit: () => {
            // formik.values.date = [selectedDate[0], selectedDate[1]];
            // if (!formik.values.date[0] || !formik.values.date[1]) {
            //     return;
            // }
            dispatch(
                setSearchDataAC({
                    search: formik.values.point,
                    // dateIn: formik.values.date[0],
                    // dateOut: formik.values.date[1],
                }),
            );
        },
        validationSchema,
    });

    const findVenues = (venues: VenueType[], stringArray: string[]) => {
        const searchObj = stringArray.map((el) => {
            return venues.filter((venue) => {
                if (venue.name === searchDataPoint) {
                    return true;
                } else if (venue.town === searchDataPoint) {
                    return true;
                } else if (venue.postCode === searchDataPoint) {
                    return true;
                } else if (venue.region === searchDataPoint) {
                    return true;
                } else if (venue.name.toLowerCase().indexOf(el) !== -1) {
                    return true;
                } else if (venue.postCode.toLowerCase().indexOf(el) !== -1) {
                    return true;
                } else if (venue.region.toLowerCase().indexOf(el) !== -1) {
                    return true;
                } else if (venue.town.toLowerCase().indexOf(el) !== -1) {
                    return true;
                }
                return false;
            });
        });
        const findSearchVenues = searchObj.reduce((venues, venuesArray) => (venues.push(...venuesArray), venues), []);

        function removeDuplicates(arr: VenueType []) {
            const result: VenueType[] = [];
            const duplicatesIndices: VenueType[] = [];

            // We iterate over each element in the original array
            arr.forEach((current: VenueType, index: any) => {
                if (duplicatesIndices.includes(index)) return;

                result.push(current);

                // Compare each element in the array after the current one
                for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {
                    const comparison = arr[comparisonIndex];
                    const currentKeys = Object.keys(current);
                    const comparisonKeys = Object.keys(comparison);

                    // Checking the length of the arrays
                    if (currentKeys.length !== comparisonKeys.length) continue;

                    // Checking the value of the keys
                    const currentKeysString = currentKeys.sort().join('').toLowerCase();
                    const comparisonKeysString = comparisonKeys.sort().join('').toLowerCase();
                    if (currentKeysString !== comparisonKeysString) continue;

                    // Checking key indexes
                    let valuesEqual = true;
                    for (let i = 0; i < currentKeys.length; i++) {
                        const key = currentKeys[i];
                        //@ts-ignore
                        if (current[key] !== comparison[key]) {
                            valuesEqual = false;
                            break;
                        }
                    }
                    if (valuesEqual) duplicatesIndices.push(comparisonIndex);
                } // End of cycle
            });
            return result;
        }

        return removeDuplicates(findSearchVenues);
    };

    const searchedCommonWords = searchCommonWords(searchString, commonWords);
    const searchedVenues = findVenues(venues, searchedCommonWords);

    useEffect(() => {
        dispatch({ type: VenuesActionTypes.CHECK_VENUES });
        if (bookingData.mappingId) dispatch(bookingDeleteByIdActionCreator());
    }, [dispatch, bookingData.mappingId]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (venues.length === 0) {
        return <LinearProgress/>;
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" color="inherit">
                <Toolbar className={classes.header}>
                    <form className={classes.formContainer} onSubmit={formik.handleSubmit}>
                        {/* <div className={classes.headerItem}>
                            <SelectedDateComponent
                                name={'date'}
                                value={formik.values.date}
                                onChange={formik.handleChange}
                                selectedDate={selectedDate}
                                handleDateChange={handleDateChange}
                            />
                        </div> */}
                        <TextField
                            className={classes.headerItem}
                            name={'point'}
                            defaultValue={formik.initialValues.point}
                            value={formik.values.point}
                            onChange={formik.handleChange}
                            error={!!(formik.errors.point && formik.touched.point)}
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button type="submit" variant="contained" color="primary" className={classes.button}>
                            Search
                        </Button>
                    </form>

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <FormControlLabel
                                control={<Checkbox name="digitalKioskForPromotions" color="primary"/>}
                                label="Digital Kiosk for promotions"
                            />
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <div className={classes.locationBody}>
                {searchedVenues.length > 0 && (
                    <Typography variant={'h5'} className={classes.text}>
                        {' '}
                        {searchedVenues.length} Locations found{' '}
                    </Typography>
                )}
                <Grid container className={classes.locationContainer}>
                    <Grid item xs={12} sm={12} className={classes.cardContainer}>
                        {searchedVenues.length > 0 ? (
                            searchedVenues.map((card: VenueType) => {

                                return (
                                    <LocationCardComponent
                                        name={card.name}
                                        key={card.id}
                                        id={card.id}
                                        mappingId={card.mappingId}
                                        region={card.region}
                                        address={card.address}
                                        postCode={card.postCode}
                                        town={card.town}
                                        weeklyFootfall={card.weeklyFootfall}
                                        details={card.details}
                                        ageInfo={card.ageInfo}
                                        catchmentPercent={card.catchmentPercent}
                                        femalePercentage={card.femalePercentage}
                                        avgDailyCost={card.avgDailyCost}
                                        avgWeekendCost={card.avgWeekendCost}
                                        thumbnailUrl={card.thumbnailUrl}
                                        createdAt={card.createdAt}
                                        updatedAt={card.updatedAt}
                                        hasImage={card.hasImage}
                                        additionalCharge={card.additionalCharge}
                                        Spaces={card.Spaces}
                                    />
                                );
                            })
                        ) : (
                            <div style={{ fontSize: 30, textAlign: 'center' }}>
                                No results were found for your search.
                                <SentimentVeryDissatisfiedIcon fontSize={'small'}/>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default LocationsFoundPage;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Example',
            zIndex: 100,
        },
        header: {
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: 5,
        },
        headerItem: {
            margin: '5px 15px 0px 15px',
        },
        locationContainer: {
            marginTop: 20,
        },
        cardContainer: {
            overflow: 'auto',
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': {
                width: 10,
                backgroundColor: '#f9f9fd',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: '#274a66',
            },
        },
        mapContainer: {
            paddingLeft: 10,
        },
        text: {
            fontFamily: 'Example',
        },
        locationBody: {
            margin: '0 auto',
            marginTop: 20,
            width: 1280,
            position: 'relative',
        },
        formContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        button: {
            letterSpacing: 1,
            padding: '10px 5px 10px 5px',
            textTransform: 'none',
            width: 150,
            height: 50,
        },
    }),
);
