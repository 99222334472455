import { SpacesType } from '../ducks/venue/types';

function addDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export const partialPayment = (customerSpaces: Array<SpacesType>) => {
    let partialPaymentResult = false;
    for (let space of customerSpaces) {
        const dates = space.checkInDate as Date
        if (addDays(new Date(), 30) <= dates as unknown) {
            partialPaymentResult = true
            return partialPaymentResult
        }
    }
    return partialPaymentResult
}