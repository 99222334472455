import { AxiosResponse } from 'axios';

import { instance } from './axios';
import { FetchCalculatedCostResponseType, ResponseType } from './types';

export const spacesAPI = {
    getSpaceBookingData(spaceId: number): Promise<AxiosResponse> {
        return instance.get(`/spaces/${spaceId}`);
    },
    fetchCalculatedPeriodCost(spaceId: number, data: {
        start: string,
        end: string
    }) {
        return instance.put<ResponseType<FetchCalculatedCostResponseType>>(`spaces/cost/${spaceId}`, data)
    }
};