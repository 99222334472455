import React, { useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import Routes from '../../routes';
import { authenticatedSelector } from '../../ducks/auth/selectors';

const OnboardingPage: React.FC = () => {
    const history = useHistory();
    const classes = useStyles();
    const isAuthenticated = useSelector(authenticatedSelector);
    const token = localStorage.getItem('accessToken');

    useEffect(() => {
        if (isAuthenticated || token) {
            history.push(Routes.SEARCH_PROMOTIONS);
        }
    }, [history, isAuthenticated, token]);

    return (
      <div className={classes.root}>
        <Typography variant="h3" className={classes.text}>
          UK Network of Promotional Kiosks
        </Typography>
        <Typography variant="h5" className={classes.text}>
          Reach new customers in high footfall shopping centres with the
          SpaceandPeople Promotional Kiosk
        </Typography>

        <p className={classes.intro}>
          The SpaceandPeople Promotional Kiosk network offers a digital,
          experiential platform which enables companies to promote easily and
          cost-effectively, eliminating logistics and build-costs. This
          uniquely-integrated approach ensures a seamless journey from start to
          finish, from uploading your visuals to the kiosk to customised
          lighting to suit your brand. The kiosks are located in high footfall
          venues including shopping centres throughout the UK and can be used
          for a range of promotional activities.
        </p>

        <p className={classes.intro}>
          The Promotional Kiosks offer you the chance to speak directly to
          potential customers within your local area. Ideal for gyms,
          photographers, local attractions and home improvement companies, our
          kiosks can be used to promote special offers, sign up new customers or
          create awareness of your company. The Promotional Kiosks are fully
          digitally enabled and images are either uploaded remotely by our
          in-house team or via USB stick so all you have to do is turn up and
          promote.
        </p>

        <div className={classes.buttonContainer}>
          <Button
            component={RouterLink}
            to={Routes.LOGIN}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Login
          </Button>
          <Button
            component={RouterLink}
            to={Routes.SIGN_UP}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            New customer
          </Button>
          <Button
            component={RouterLink}
            to={Routes.SEARCH_PROMOTIONS}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            I am just browsing
          </Button>
        </div>
        <Typography variant="body2" className={classes.footer}>
          SPACEANDPEOPLE GROUP
        </Typography>
      </div>
    );
};

export default OnboardingPage;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'uppercase',
            position: 'relative',
        },
        buttonContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            [theme.breakpoints.down('xs')]: {
                margin: 10,
                justifyContent: 'center',
            },
        },
        button: {
            width: 230,
            margin: 40,
            letterSpacing: 1,
            padding: '12px 20px 12px 20px',
            fontFamily: 'Example',
            [theme.breakpoints.down('sm')]: {
                margin: 10,
                padding: '12px 0 12px 0',
            },
        },
        text: {
            marginBottom: 40,
            fontFamily: 'Example',
            [theme.breakpoints.down('md')]: {
                textAlign: 'center',
            },
            textAlign: 'center'
        },
        intro: {
            margin: '0px 200px 20px 200px',
            textAlign: 'center',
            textTransform: 'none',
            fontWeight: 'bold'
        },
        footer: {
            position: 'absolute',
            bottom: 10,
        },
    }),
);
