import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import { authenticatedSelector, resetPasswordRequestSelector } from '../../ducks/auth/selectors';
import Modal from '../../components/Modal';
import { AuthActionTypes } from '../../ducks/auth/types';
import ResetPasswordForm from '../../components/forms/authForm/ResetPasswordForm';
import { useHistory } from 'react-router-dom';
import Routes from '../../routes';

const ResetPasswordPage: React.FC = () => {
    const [successModalShown, setSuccessModalShown] = useState(false);
    const resetPasswordRequestSent = useSelector(resetPasswordRequestSelector);
    const isAuthenticated = useSelector(authenticatedSelector);
    const dispatch = useDispatch();
    const history = useHistory()

    const showSuccessModal = useCallback(() => {
        setSuccessModalShown(true);
        dispatch({ type: AuthActionTypes.RESET_PASSWORD_MODAL_SHOWN });
    }, [dispatch]);

    const hideSuccessModal = () => {
        setSuccessModalShown(false);
    };

    if (isAuthenticated) {
        history.push(Routes.PROFILE)
    }

    useEffect(() => {
        if (resetPasswordRequestSent) {
            showSuccessModal();
        }
    }, [resetPasswordRequestSent, showSuccessModal]);

    return (
        <div>
            <Typography align="center" variant="h3" component="h1">
                Reset password
            </Typography>
            <Typography align="center" variant="h4" component="h2">
                Enter your email and we will send you a link to reset password
            </Typography>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={6}>
                    <Card variant="outlined">
                        <CardHeader title="Reset password"/>
                        <ResetPasswordForm/>
                        <Modal handleClose={hideSuccessModal} isOpen={successModalShown} title="Link sent!">
                            <p>
                                We sent restore password link to the email. Please follow it in order to change your
                                password.
                            </p>
                            <CardActions>
                                <Button onClick={hideSuccessModal}>Done</Button>
                            </CardActions>
                        </Modal>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default ResetPasswordPage;
