import { formatDate } from './formatDate';

export const disableHandler = (date: any, arrayOfDates: [any]) => {
    let bookedTimeArray = [];
    let isUnavailable = false;
    if (arrayOfDates.length > 0) {
        bookedTimeArray = arrayOfDates.map((item: any) => {
            item.from = item.from.slice(0, 10);
            item.to = item.to.slice(0, 10);
            return item;
        });
    }
    const newDate = formatDate(date);
    let cuttoffDate = new Date();
    let cuttoffFutureDates = new Date();
    cuttoffDate.setTime(cuttoffDate.getTime()  + ((14 - cuttoffDate.getDay()) % 7 + 7) * 24 * 60 * 60 * 1000);
    cuttoffFutureDates.setTime(
      cuttoffDate.getTime() + 26 * (7 * 24 * 60 * 60 * 1000)
    );

    if (
      newDate <= formatDate(cuttoffDate) ||
      newDate > formatDate(cuttoffFutureDates)
    ) {
      isUnavailable = true;
      return isUnavailable;
    }
    for (const time of bookedTimeArray) {
        if (time.from <= newDate && time.to >= newDate) {
            isUnavailable = true;
            return isUnavailable;
        }
    }
    return isUnavailable
};