import React, { MouseEvent } from 'react';
import {
    Button,
    Card,
    Checkbox,
    createStyles,
    FormControl,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    withStyles,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';

import { SpacesType } from '../../ducks/venue/types';
import { BookingDataType } from '../../api/types';
import { getDay } from '../../helpers/getDay';
import { formatDate, formatDateGB } from '../../helpers/formatDate';
import { UpdateUserDataType } from '../../ducks/user/types';

type ConfirmBookingComponentPropsType = {
    setOpenConfirmBooking: (switcher: boolean) => void;
    openConfirmBooking: boolean;
    customerSpaces: Array<SpacesType>;
    userDetails: UpdateUserDataType;
    confirmCondition: boolean;
    setConfirmCondition: (confirm: boolean) => void;
    currentVenueRegion: string;
    currentVenueId: number;
    setDataToSend: (data: BookingDataType) => void;
    handleNext: () => void;
    handleBack: () => void;
    setOpen: (switcher: boolean) => void;
    setOpenPayment: (switcher: boolean) => void;
    setTotalPrice: () => number;
    total: string;
    additionalCharge: string
};

const ConfirmBookingComponent: React.FC<ConfirmBookingComponentPropsType> = ({
                                                                                 setOpenConfirmBooking,
                                                                                 customerSpaces,
                                                                                 userDetails,
                                                                                 confirmCondition,
                                                                                 setConfirmCondition,
                                                                                 currentVenueRegion,
                                                                                 currentVenueId,
                                                                                 setDataToSend,
                                                                                 handleBack,
                                                                                 setOpen,
                                                                                 handleNext,
                                                                                 setTotalPrice,
                                                                                 total,
                                                                                 additionalCharge,
                                                                                 setOpenPayment
                                                                             }) => {
    const classes = useStyles();

    const handleClose = () => {
        setOpenConfirmBooking(false);
        setOpen(true);
        handleBack();
    };

    const setConfirmConditionHandler = (event: MouseEvent<HTMLButtonElement>) => {
        setConfirmCondition(!confirmCondition);
    };

    const confirmBookingHandler = () => {
        const sendData: BookingDataType = {
            addressLineFirst: userDetails.addressLineFirst,
            addressLineSecond: userDetails.addressLineSecond,
            companyName: userDetails.companyName,
            postCode: userDetails.postCode,
            town: userDetails.town,
            totalCost: setTotalPrice(),
            email: userDetails.email,
            phoneNumber: userDetails.phoneNumber,
            address: currentVenueRegion,
            productInformation: userDetails.productInformation,
            campaignInformation: userDetails.campaignInformation,
            venueId: currentVenueId,
            periods: customerSpaces.map((space) => {
                const outDate = formatDate(space.checkOutDate as Date);
                const inDate = formatDate(space.checkInDate as Date);
                const periodDays = Math.floor((Number(space.checkOutDate as Date) - Number(space.checkInDate as Date)) / (1000 * 60 * 60 * 24) + 1);
                const periodCost = space.amout?.toFixed(2);

                return {
                    periodCost: periodCost,
                    periodDays: periodDays,
                    spaceId: space.id,
                    startDate: inDate,
                    endDate: outDate,
                };
            }) as [],
        };

        setOpen(false);
        setDataToSend(sendData);
        setOpenConfirmBooking(false);
        setOpenPayment(true);
        handleNext();

    };

    return (
        <Card>
            <div className={classes.root}>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">spaces</StyledTableCell>
                                <StyledTableCell align={'center'} className={classes.border2}>
                                    Date
                                </StyledTableCell>
                                <StyledTableCell align="center">Daily Cost</StyledTableCell>
                                <StyledTableCell align="center" style={{ borderLeft: '1px solid white' }}>
                                    Days
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ borderLeft: '1px solid white' }}>
                                    Total
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customerSpaces.map((customerSpace, index) => {
                                const quantityDays = getDay(customerSpace.checkInDate, customerSpace.checkOutDate);
                                const amout = customerSpace.amout;
                                let daylyCost = 0;
                                if (amout) {
                                    daylyCost = amout / quantityDays
                                }
                                
                                return (
                                    <StyledTableRow key={index + customerSpace.id}>
                                        <StyledTableCell align="center">
                                            <FormControl>{customerSpace.name}</FormControl>
                                        </StyledTableCell>
                                        <StyledTableCell className={classes.border}>
                                            <div>
                                                <div style={{ fontWeight: 'bold' }}>
                                                    from: {formatDateGB(customerSpace.checkInDate ? customerSpace.checkInDate : null)}
                                                </div>
                                                <div style={{ fontWeight: 'bold' }}>
                                                    to: {formatDateGB(customerSpace.checkOutDate ? customerSpace.checkOutDate : null)}
                                                </div>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <div><NumberFormat decimalScale={2} value={daylyCost} displayType={'text'} thousandSeparator={true} prefix={'£'}/></div>
                                        </StyledTableCell>
                                        <StyledTableCell style={{ borderLeft: '1px solid #01458E' }} align="center">
                                            <div>{quantityDays}</div>
                                        </StyledTableCell>
                                        <StyledTableCell style={{ borderLeft: '1px solid #01458E' }} align="center">
                                            <div style={{ fontWeight: 'bold' }}> <NumberFormat value={customerSpace.amout} displayType={'text'} thousandSeparator={true} prefix={'£'} /></div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
                <Card className={classes.totalCost}>
                    <div style={{ fontWeight: 'bold' }}>Rental Cost:</div>
                    <div> <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'£'} /></div>
                </Card>
                <Card className={classes.totalCost}>
                    <div style={{ fontWeight: 'bold' }}>Additional change:</div>
                    <div> <NumberFormat value={additionalCharge} displayType={'text'} thousandSeparator={true} prefix={'£'} /></div>
                </Card>
                <Card className={classes.totalCost}>
                    <div style={{ fontWeight: 'bold' }}>Total cost:</div>
                    <div>  <NumberFormat value={Number(total) + Number(additionalCharge)} displayType={'text'} thousandSeparator={true} prefix={'£'} /></div>
                </Card>
            </div>

            <div className={classes.userInformationContainer}>
                <div>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Company Name"
                        margin="dense"
                        disabled
                        defaultValue={userDetails.companyName}
                    />
                </div>
                <div className={classes.dialogInput}>
                    <TextField
                        fullWidth
                        style={{ marginRight: 10 }}
                        variant="outlined"
                        label="Email"
                        name="email"
                        placeholder="Email"
                        margin="dense"
                        disabled
                        defaultValue={userDetails.email}
                    />
                    <TextField
                        style={{ marginLeft: 10 }}
                        fullWidth
                        variant="outlined"
                        label="Phone number"
                        name="phoneNumber"
                        placeholder="Phone number"
                        margin="dense"
                        disabled
                        defaultValue={userDetails.phoneNumber}
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        variant="outlined"
                        defaultValue={userDetails.addressLineFirst}
                        label="Address Line 1"
                        name="addressLineFirst"
                        placeholder="Address Line 1"
                        margin="dense"
                        disabled
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        variant="outlined"
                        defaultValue={userDetails.addressLineSecond}
                        label="Address Line 2"
                        name="addressLineSecond"
                        placeholder="Address Line 2"
                        margin="dense"
                        disabled
                    />
                </div>
                <div className={classes.dialogInput}>
                    <TextField
                        style={{ marginRight: 10 }}
                        fullWidth
                        variant="outlined"
                        defaultValue={userDetails.town}
                        label="Town"
                        name="town"
                        placeholder="town"
                        margin="dense"
                        disabled
                    />
                    <TextField
                        style={{ marginLeft: 10 }}
                        fullWidth
                        variant="outlined"
                        value={userDetails.postCode}
                        label="Post code"
                        name="postCode"
                        placeholder="Post code"
                        margin="dense"
                        disabled
                    />
                </div>
                <div className={classes.textareaContainer}>
                    <TextField
                        variant={'outlined'}
                        label="Product information"
                        style={{ minWidth: '100%', maxWidth: '100%', maxHeight: '100%' }}
                        name={'productInformation'}
                        placeholder="Please provide an overview of your company’s product/services"
                        multiline
                        disabled
                        defaultValue={userDetails.productInformation}
                        rows={2}
                        rowsMax={3}
                    />
                </div>
                <div>
                    <TextField
                        variant={'outlined'}
                        style={{ minWidth: '100%', maxWidth: '100%', maxHeight: '100%' }}
                        label={'Campaign information'}
                        name="campaignInformation"
                        placeholder="Campaign information"
                        multiline
                        rows={2}
                        rowsMax={3}
                        disabled
                        defaultValue={userDetails.campaignInformation}
                    />
                </div>
            </div>
            <div className={classes.confirmDateContainer}>
                <div style={{ marginLeft: 10, fontSize: 20 }}>
                    <Checkbox
                        color="primary"
                        checked={confirmCondition}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onClick={setConfirmConditionHandler}
                    />
                    <>I accept:</>
                    <a href={process.env.REACT_APP_AGREEMENT_URL}
                       target="_blank" rel="noreferrer"
                       className={classes.accept}> the Terms and Conditions</a>
                </div>
                <div className={classes.buttonContainer}>
                    <Button className={classes.button} variant="contained" color="primary" onClick={handleClose}>
                        Back
                    </Button>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        disabled={!confirmCondition}
                        onClick={confirmBookingHandler}
                    >
                        Proceed to Payment
                    </Button>
                </div>
            </div>
        </Card>
    );
};

export default ConfirmBookingComponent;

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#01458E',
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
            fontFamily: 'Example',
            letterSpacing: 0.4,
            '& > div': {
                padding: 10,
            },
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 20,
        },
        spacesContainer: {
            display: 'flex',
            border: ' 1px solid red',
        },
        border2: {
            borderLeft: '1px solid white',
            borderRight: '1px solid white',
        },
        border: {
            borderLeft: '1px solid #01458E',
            borderRight: '1px solid #01458E',
        },
        confirmDateContainer: {
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-around',
            marginBottom: 20,
        },
        button: {
            margin: 10,
            width: 250,
        },
        dialogInput: {
            display: 'flex',
            marginBottom: 10,
        },
        textareaContainer: {
            marginBottom: 20,
        },
        textarea: {
            minWidth: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
        },
        userInformationContainer: {
            padding: '20px 20px 0 20px',
        },
        totalCost: {
            width: 150,
            marginLeft: 20,
            borderRadius: 15,
            fontSize: 16,
            fontFamily: 'Example',
            textAlign: 'center',
            justifyContent: 'space-around',
            '& > div': {
                marginLeft: 5,
                fontSize: 16,
            },
        },
        accept: {
            textDecoration: 'none',
            color: '#01458E',
            fontFamily: 'Example',
            letterSpacing: 0.5,
            '&:hover': {
                color: '#964B00'
            }
        }
    }),
);