import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    makeStyles,
    Paper,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import RoomIcon from '@material-ui/icons/Room';
import NumberFormat from 'react-number-format';

import { venueSpacesSelector } from '../../../ducks/venue/selectors';
import Routes from '../../../routes';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SpaceTable: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [dialogParams, setDialogParams] = useState({ url: '', name: '' });
    const spaces = useSelector(venueSpacesSelector);
    const isAuthenticated = localStorage.getItem('accessToken');

    const handleClose = () => {
        setOpen(false);
    };
    const clickAssistant = isAuthenticated ? '' : 'Login please';
    return (
      <div className={classes.root}>
        <TableContainer className={classes.table} component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">List of spaces</StyledTableCell>
                <StyledTableCell align={"left"} className={classes.border2}>
                  Information
                </StyledTableCell>
                <StyledTableCell align="center">Book</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {spaces.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell style={{ width: 230 }} align="center">
                    {/* <div style={{ cursor: 'pointer' }}> */}
                    <div>
                      <div
                        className={classes.spaceName}
                        onClick={() => {
                          setOpen(true);
                          setDialogParams({
                            url: row.siteMapUrl,
                            name: row.name,
                          });
                        }}
                      >
                        {row.name}
                      </div>
                    </div>
                    <div>
                      <RoomIcon fontSize={"inherit"} /> {row.location}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell className={classes.border}>
                    <div dangerouslySetInnerHTML={{ __html: row.info }} />
                    <div>
                      {" "}
                      Indicative Weekly Price:{" "}
                      <NumberFormat
                        value={row.avgDailyCost}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"£"}
                      />
                    </div>
                    {/* <div> Average Daily Cost: £{row.avgDailyCost}</div> */}
                    {/* <div> Average Weekend Cost: £{row.avgWeekendCost}</div> */}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 150 }} align="center">
                    <Tooltip title={clickAssistant}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          history.push(
                            `${Routes.BOOKING_PAGE}/?venue=${row.venueId}&space=${row.id}`
                          );
                        }}
                      >
                        Book now
                      </Button>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {open && dialogParams.url && (
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <img
              src={dialogParams.url}
              style={{ borderRadius: 20, padding: 12 }}
              alt={""}
            />
            <div className={classes.dialogSpaceText}>{dialogParams.name}</div>
            <DialogActions>
              <Button
                style={{ margin: 5 }}
                variant="contained"
                onClick={handleClose}
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
};

export default SpaceTable;

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#01458E',
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
            fontFamily: 'Example',
            letterSpacing: 0.4,
            '& > div': {
                padding: 10,
            },
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 20,
    },
    table: {
      borderRadius: 15,
    },
    spaceName: {
      textTransform: "uppercase",
      fontWeight: "bold",
      letterSpacing: 1,
      color: "#01458E",
      fontSize: 16,
      // '&:hover': {
      //     color: '#5288e3',
      // },
    },

    border: {
      borderLeft: "1px solid #01458E",
      borderRight: "1px solid #01458E",
    },
    border2: {
      borderLeft: "1px solid white",
      borderRight: "1px solid white",
    },
    dialogSpaceText: {
      textAlign: "center",
      marginTop: 5,
      textTransform: "uppercase",
    },
  })
);
