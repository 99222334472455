import React from 'react';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { AuthActionTypes, ChangeUserDataAction } from '../../../ducks/auth/types';
import { UserType } from '../../../ducks/user/types';


const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(1, 'Name must contain at least 1 characters').required('Field is required'),
    lastName: Yup.string().min(1, 'Name must contain at least 1 characters').required('Field is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
});

type ChangedUserProps = {
    disabled: boolean
    isChangeUser: (isChanged: boolean) => void
    user: UserType
}

const ChangeUserForm: React.FC<ChangedUserProps> = React.memo(({ disabled, isChangeUser, user }) => {
    const dispatch = useDispatch();
    const initialValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
    };

    const form = useFormik({
        initialValues,
        onSubmit: ({ firstName, lastName, email }) => {
            isChangeUser(false)
            dispatch({
                type: AuthActionTypes.CHANGE_USER_DATA,
                payload: { firstName, lastName, email }
            } as ChangeUserDataAction)
        },
        validationSchema,
    });

    return (
        <form onSubmit={form.handleSubmit}>
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ fontSize: 16, marginRight: 10,width:80 }}>
                        First Name:
                    </div>
                    <div style={{ width: '100%' }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            error={!!(form.errors.firstName && form.touched.firstName)}
                            name="firstName"
                            value={form.values.firstName}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            disabled={!disabled}
                            helperText={form.errors.firstName && form.touched.firstName && form.errors.firstName}
                            margin="dense"
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ fontSize: 16, marginRight: 10,width:80 }}>
                        Last Name:
                    </div>
                    <div style={{ width: '100%' }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            error={!!(form.errors.lastName && form.touched.lastName)}
                            name="lastName"
                            value={form.values.lastName}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            disabled={!disabled}
                            helperText={form.errors.lastName && form.touched.lastName && form.errors.lastName}
                            margin="dense"
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ fontSize: 16, marginRight: 10,width:80, }}>
                        Email:
                    </div>
                    <div style={{ width: '100%' }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            error={!!(form.errors.email && form.touched.email)}
                            name="email"
                            value={form.values.email}
                            onChange={form.handleChange}
                            disabled={!disabled}
                            onBlur={form.handleBlur}
                            helperText={
                                form.errors.email && form.touched.email && form.errors.email
                            }
                            margin="dense"
                        />
                    </div>

                </div>
            </div>
            {disabled &&
            <Button style={{ marginTop: 30, width: 200 }} type="submit" variant="contained" color="primary">
                Save
            </Button>}
            {!disabled &&
            <Button style={{ marginTop: 30, width: 200 }} variant="contained" color="primary" onClick={() => {
                isChangeUser(true)
            }
            }>
                Change User Data
            </Button>}
        </form>
    );
});

export default ChangeUserForm;
