import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

import Routes from '../../routes';
import { jwtVerify } from '../../helpers/jwtVerify';

interface PrivateRouteProps {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, path }: PrivateRouteProps) => {
  const history = useHistory();
  //const isAuthenticated = localStorage.getItem('accessToken')
  const accessToken = localStorage.getItem("accessToken") as string;
  const isAuthenticated = accessToken ? jwtVerify(accessToken) : false;

  useEffect(() => {
    if (!isAuthenticated) {
      history.replace(Routes.LOGIN, { from: path });
    }
  }, [history, isAuthenticated, path]);

  return isAuthenticated ? <Route component={Component} path={path} /> : null;
};

export default PrivateRoute;
