import React, { useCallback } from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import BookYourSpaceForm from '../../components/forms/bookYourSpace/BookYourSpaceForm';
import { useDispatch } from 'react-redux';
import { SearchDateType } from '../../ducks/venue/types';
import { setSearchDataAC } from '../../ducks/venue/actions';

const SearchPromotion = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const searchDataHandler = useCallback(
        (searchDate: SearchDateType) => {
            dispatch(setSearchDataAC(searchDate));
        },
        [dispatch],
    );

    return (
        <div className={classes.root}>
            <Typography variant="h4" className={classes.firstTextStyle}>
               UK Network of Promotional Kiosks
            </Typography>
            <Typography variant="h5" className={`${classes.firstTextStyle} + ${classes.secondTextStyle}`}>
            Reach new customers in high footfall shopping centres with the SpaceandPeople Promotional Kiosk
            </Typography>
            <div className={classes.searchContainer}>
                <BookYourSpaceForm searchDataHandler={searchDataHandler} />
            </div>
            <div className={classes.footer}>
                <Typography variant="body2">SPACEANDPEOPLE GROUP</Typography>
            </div>
        </div>
    );
};

export default SearchPromotion;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
            position: 'relative',
            fontFamily: 'Example',
        },
        firstTextStyle: {
            marginTop: 40,
            textTransform: 'uppercase',
            textAlign: 'center',
            fontFamily: 'Example',
        },
        secondTextStyle: {
            fontSize: 22,
        },
        searchContainer: {
            display: 'flex',
            marginTop: 40,
            justifyContent: 'center',
        },
        footer: {
            alignSelf: 'center',
            position: 'absolute',
            bottom: 10,
        },

    }),
);
