import { SearchDateType, VenuesActionTypes, venuesActionTypes, VenueType } from './types';

export type VenuesType = {
    venues: Array<VenueType>;
    currentVenue: VenueType;
    searchData: SearchDateType;
};

const initialState: VenuesType = {
    venues: [],
    currentVenue: {
        name: '',
        address: '',
        ageInfo: '',
        avgDailyCost: '',
        avgWeekendCost: '',
        catchmentPercent: 1,
        abc1percentage:1,
        createdAt: '',
        details: '',
        id: 1,
        femalePercentage: 1,
        mappingId: 1,
        postCode: '',
        region: '',
        town: '',
        thumbnailUrl: '',
        updatedAt: '',
        weeklyFootfall: 1,
        additionalCharge:'',
        hasImage: '',
        Spaces: [],
    },
    searchData: {} as SearchDateType,
};

export const venuesReducer = (state = initialState, action: venuesActionTypes) => {
    switch (action.type) {
        case VenuesActionTypes.FETCH_VENUE:
            return { ...state, venues: action.payload.venues };
        case VenuesActionTypes.FIND_VENUE:
            return { ...state, currentVenue: action.payload.venue };
        case VenuesActionTypes.DELETE_VENUE:
            return { ...state, currentVenue: {} as VenueType };
        case VenuesActionTypes.SET_SEARCH_DATE:
            return { ...state, searchData: action.payload.searchDate };
        default:
            return state;
    }
};
