import { all, call, put, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { getVenueAction, VenuesActionTypes, VenueType } from './types';
import { venueAPI } from '../../api/venueAPI';
import { getVenueByIdAC, getVenuesAC } from './actions';
import { bookingDeleteByIdActionCreator } from '../booking/action';

function* venues() {
    try {
        const response: AxiosResponse<Array<VenueType>> = yield call(venueAPI.getVenues);
        yield put(getVenuesAC(response.data));
    } catch (error) {}
}

function* getVenueById(action: getVenueAction) {
    try {
        yield put(bookingDeleteByIdActionCreator());
        const response: AxiosResponse<VenueType> = yield call(venueAPI.getVenueById, action.payload.id);
        yield put(getVenueByIdAC(response.data));
    } catch (error) {}
}



function* watchVenues() {
    yield takeEvery(VenuesActionTypes.CHECK_VENUES, venues);
    yield takeEvery(VenuesActionTypes.GET_VENUE, getVenueById);

}

export default function* venuesSaga() {
    yield all([watchVenues()]);
}
