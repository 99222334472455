import React, { useCallback, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppBar,
    createStyles,
    LinearProgress,
    Link,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    Typography,
} from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import Routes from '../../routes';
import { logoutActionCreator } from '../../ducks/auth/actions';
import { loadingSelector } from '../../ducks/auth/selectors';
import logoImage from '../../assets/SpaceandPeopleLogo.png';

type LogoutPropsType = {
    children: React.ReactNode;
    isAuthenticated: boolean;
};

const Layout: React.FC<LogoutPropsType> = ({ isAuthenticated, children }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement | (EventTarget & SVGSVGElement)>(null);
    const loading = useSelector(loadingSelector);

    const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const meHandler = useCallback(() => {
        handleClose();
        history.push(Routes.PROFILE);
    }, [history]);

    const loginHandler = useCallback(() => {
        handleClose();
        history.push(Routes.LOGIN);
    }, [history]);

    const createUserHandler = useCallback(() => {
        handleClose();
        history.push(Routes.SIGN_UP);
    }, [history]);

    const logoutHandler = useCallback(() => {
        handleClose();
        dispatch(logoutActionCreator());
        localStorage.clear();
        history.push(Routes.ONBOARDING);
    }, [history, dispatch]);

    const resetPassword = useCallback(() => {
        handleClose();
        history.push(Routes.RESET_PASSWORD);
    }, [history]);

    const Navigation = () => {
        return (
            <nav className={classes.navigation}>
                <img src={logoImage} alt="credit card example" className={`${classes.logo}`}/>
                <Typography variant="body2" className={`${classes.item}`}>
                    <Link component={RouterLink} to={Routes.SEARCH_PROMOTIONS} color="inherit">
                        Bookings
                    </Link>
                </Typography>
            </nav>
        );
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="inherit">
                <Toolbar className={classes.header}>
                    <Navigation />
                    <PermIdentityIcon
                        aria-label="account of current user"
                        aria-controls="Typography"
                        style={{ cursor: 'pointer' }}
                        aria-haspopup="true"
                        onClick={handleClick}
                    />
                    <Menu
                        className={classes.menu}
                        id="Typography"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {!isAuthenticated && (
                            <MenuItem className={classes.menuItem} onClick={loginHandler}>
                                Login
                            </MenuItem>
                        )}
                        {isAuthenticated && (
                            <MenuItem className={classes.menuItem} onClick={meHandler}>
                                My account
                            </MenuItem>
                        )}
                        {!isAuthenticated && (
                            <MenuItem className={classes.menuItem} onClick={createUserHandler}>
                                Create new user
                            </MenuItem>
                        )}
                        {!isAuthenticated && <MenuItem className={classes.menuItem} onClick={resetPassword}>
                            Recovery Password
                        </MenuItem>}
                        {isAuthenticated && (
                            <MenuItem className={classes.menuItem} onClick={logoutHandler}>
                                Logout
                            </MenuItem>
                        )}
                    </Menu>
                </Toolbar>
                {loading && <LinearProgress />}
            </AppBar>
            {children}
        </div>
    );
};

export default Layout;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            fontFamily: 'Example',
        },
        item: {
            margin: 0,
            cursor: 'pointer',
            fontSize: 18,
            marginLeft: 30,
            fontFamily: 'Example',
        },
        menuItem: {
            fontFamily: 'Example',
        },
        menu: {
            position: 'relative',
            '& > MenuItem': {
                backgroundColor: 'red',
            },
        },
        navigation: {
            display: 'flex',
            alignItems: 'center',
            flex: '1 1 auto',
            justifyContent: 'space-between',
            marginRight: 40,
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 90,
        },
        logo : {
            width: '30%'
        }
    }),
);
