import React, { useCallback, useEffect, useState } from 'react';
import {
    CircularProgress,
    createStyles,
    FormControl,
    makeStyles,
    NativeSelect,
    TableCell,
    TableRow,
    Theme,
    withStyles,
} from '@material-ui/core';
import { DateRange, DateRangeDelimiter, LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { AxiosResponse } from 'axios';
import NumberFormat from 'react-number-format';

import { SpacesType, VenueType } from '../../ducks/venue/types';
import { spacesAPI } from '../../api/spacesAPI';
import { formatDate } from '../../helpers/formatDate';
import { disableHandler } from '../../helpers/unavailableDateDisable';
import { isSameError } from '../../helpers/isSameDateError';
import { getDay } from '../../helpers/getDay';
import { FetchCalculatedCostResponseType, ResponseType } from '../../api/types';
import { enGB} from 'date-fns/locale';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import {ValueType} from "rsuite/lib/DateRangePicker";
import { setDate } from 'date-fns';


type ComponentPropsType = {
    spacesArray: Array<SpacesType>;
    setTotal: (n: string) => void;
    total: string;
    setSpacesArray: (space: Array<SpacesType>) => void;
    currentVenue: VenueType;
    row: SpacesType;
    bookedTime: any;
    setBookedTime: (map: any) => void;
    getBookedTime: (spaceId: number) => void;
    isError: boolean;
    setDuplicatedArray: (arr: any) => void;
};

const SpacesComponent: React.FC<ComponentPropsType> = React.memo(
    ({
         row,
         currentVenue,
         spacesArray,
         setSpacesArray,
         setTotal,
         total,
         isError,
         bookedTime,
         getBookedTime,
         setDuplicatedArray,
     }) => {
        const classes = useStyles();
        const [option, setOption] = useState(row.name);
        const [currentRow, setCurrentRow] = useState<SpacesType>(row);
        const checkIn = row.checkInDate ? row.checkInDate : new Date();
        const checkOut = row.checkOutDate ? row.checkOutDate : undefined;
        const initialDays = (checkIn && checkOut) ? getDay(checkIn, checkOut).toString() : '0';
        const initialAmount = row.amout ? row.amout.toFixed(2).toString() : '0';
        const initialDailyCost = (initialAmount && initialDays) ?
            (+initialDays === 0 && +initialAmount === 0) ? '0' : (Number(initialAmount) / Number(initialDays)).toFixed(2)
            : '0';
        const [selectedDate, handleDateChange] = useState<ValueType>([checkIn, checkOut]);
        const [selectedDateValue, setDateValue] = useState<ValueType>([checkIn, checkOut]);
        const [error, setError] = useState<Array<string | null>>([null, null]);
        const [days, setDays] = useState<string>(initialDays);
        const [currentRowSum, setCurrentRowSum] = useState<string>(initialAmount);
        const [dailyCost, setDailyCost] = useState<string>(initialDailyCost);
        const [loading, setLoading] = useState<boolean>(false);
        // const weekdays = [
        //   "Sunday",
        //   "Monday",
        //   "Tuesday",
        //   "Wednesday",
        //   "Thursday",
        //   "Friday",
        //   "Saturday",
        // ];

        useEffect(() => {
            if (!bookedTime.get(currentRow.id)) {
                getBookedTime(currentRow.id);
            }
        }, [currentRow.id]);

        const disableUnavailable = (date: unknown) => {
            let arrayOfDates = bookedTime.get(currentRow.id.toString());
            
            if ((arrayOfDates && arrayOfDates.length === 0) || (arrayOfDates === undefined)) {
                arrayOfDates = [];
            }
            
            return disableHandler(date, arrayOfDates);
        };

        const handleChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>): void => {
            setLoading(false)
            setOption(event.currentTarget.value);
            setDuplicatedArray([])
            const findElement = currentVenue.Spaces.find(
                (space: SpacesType) => space.name === event.currentTarget.value,
            );
            let newObject: SpacesType = {
                ...findElement,
                index: currentRow.index,
            } as SpacesType;
            setTotal(total)
            setDays('0');
            setDailyCost('0');
            setCurrentRowSum('0');
            if (currentRowSum > '0') {
                const totalSum = (Number(total) - Number(currentRowSum)).toString()
                setTotal(totalSum)
            }
            setCurrentRow(newObject);
            const newArray = [...spacesArray];
            newArray[currentRow.index] = newObject;
            setSpacesArray(newArray);
            handleDateChange([new Date(), undefined]);

        }, [setLoading, setOption, setTotal, setSpacesArray, handleDateChange, currentRow.index, currentRowSum, currentVenue.Spaces, setDuplicatedArray, spacesArray, total]);

        const handleChangeDate = useCallback(
            async (date:ValueType) => {
                setDateValue(date);
                const arrDates = bookedTime.get(currentRow.id.toString());
                const checkInDate = formatDate(date[0]? date[0] : new Date());
                const checkOutDate = formatDate(date[1]? date[1] : new Date());
                const startDate = date[0];
                const endDate = date[1];
                setLoading(true);
                setDuplicatedArray([])

                    for (const time of arrDates) {
                        let isUnavailable = false;
                        if (time.from >= checkInDate && time.to <= checkOutDate) {
                            isUnavailable = true;
                        }
                        if (isUnavailable) {
                            const newArray = [...spacesArray];
                            const totalSum = (Number(total) - Number(currentRowSum)).toString()
                            setTotal(totalSum);
                            setCurrentRowSum('0');
                            setDays('0');
                            newArray[currentRow.index] = { ...currentRow };
                            setSpacesArray(newArray);
                            handleDateChange([undefined, undefined]);
                            setLoading(false)
                            return;
                        }
                    }

                handleDateChange([new Date(), undefined]);
                setDailyCost('0')

                if ((checkInDate && checkOutDate) && (checkOutDate >= checkInDate)) {
                    const res: AxiosResponse<ResponseType<FetchCalculatedCostResponseType>> = await spacesAPI.fetchCalculatedPeriodCost(currentRow.id, {
                        start: checkInDate,
                        end: checkOutDate
                    })
                    const calculatedCostResponse = res.data as unknown as FetchCalculatedCostResponseType
                    const totalDays = getDay(new Date(calculatedCostResponse.start), new Date(calculatedCostResponse.end))
                    setDays(totalDays.toString());
                    const totalSumOfDays = calculatedCostResponse.amout;
                    const countDailyCost = (totalSumOfDays / totalDays).toFixed(2)
                    const rowSum = Number(total) - Number(currentRowSum) + Number(totalSumOfDays);
                    const totalCostOfRow = rowSum.toFixed(2);
                    setCurrentRowSum(totalSumOfDays.toString())
                    setDailyCost(countDailyCost);
                    setTotal(totalCostOfRow);
                    const newCurrent = {
                        ...currentRow,
                        amout: calculatedCostResponse.amout,
                        checkInDate: startDate,
                        checkOutDate: endDate,
                    };
                    setCurrentRow(newCurrent);
                    const newArray = spacesArray;
                    newArray[currentRow.index] = newCurrent;
                    setSpacesArray(newArray);
                    setLoading(false);
                }
            },
            [total, currentRow, bookedTime, currentRowSum, setDuplicatedArray,handleDateChange, setSpacesArray, setTotal, spacesArray],
        );

        return (
            <StyledTableRow key={currentRow.id}>
                <StyledTableCell style={{ width: '230px' }} align="center">
                    <FormControl className={classes.formControl}>
                        <NativeSelect value={option} onChange={handleChange} variant={'filled'}>
                            {currentVenue.Spaces.map((space: SpacesType, index) => {
                                return (
                                    <option value={space.name} key={index + space.id}>
                                        {space.name}
                                    </option>
                                );
                            })}
                        </NativeSelect>
                    </FormControl>
                </StyledTableCell>
                <StyledTableCell className={classes.border}>
                    <div className={classes.calendar}>
               <LocalizationProvider locale={enGB} dateAdapter={DateFnsUtils}> 
                            {/* <DateRangePicker className={classes.disabledDays}
                                shouldDisableDate={disableUnavailable}
                                startText="Starting Date"
                                endText="Finish Date"
                                value={selectedDate}
                                onError={([startReason, endReason], [start, end]) => {
                                    if (startReason === 'invalidRange' && isSameError(start, end)) {
                                        setError([null, null]);
                                        return;
                                    }
                                    setError([startReason, endReason]);
                                }}
                                onChange={handleChangeDate}
                                renderInput={(startProps, endProps) => (
                                    <>
                                        <TextField
                                            datatype={'dd/mm/yyyy'}
                                            {...startProps}
                                            autoComplete={'off'}
                                            helperText={null}
                                            size={'small'}
                                            error={!!error[0] || isError}
                                        />
                                        <DateRangeDelimiter> to </DateRangeDelimiter>
                                        <TextField
                                            datatype={'dd/mm/yyyy'}
                                            {...endProps}
                                            autoComplete={'off'}
                                            helperText={null}
                                            size={'small'}
                                            error={!!error[0] || isError}
                                        />
                                    </>
                                )}
                            /> */}
                               <DateRangePicker 
                                  hoverRange="week" 
                                  isoWeek ranges={[]}
                                  onOk={handleChangeDate}
                                  value={selectedDateValue}
                                  disabledDate={disableUnavailable}
                                  onOpen={()=>setDateValue([new Date(), new Date()])}
                                 // block={true}
                                  cleanable={true}
                                  onClean={()=>{handleChangeDate([new Date(), undefined])}}
                                  format="DD-MM-YYYY"
                                  placeholder="Start Date - Finish Date"
                                />
                        </LocalizationProvider>
                    </div>
                </StyledTableCell>
                <StyledTableCell style={{ width: '150px' }} align="center">
                    {loading ? <CircularProgress/> : <NumberFormat value={dailyCost} displayType={'text'} thousandSeparator={true} prefix={'£'} />}
                </StyledTableCell>
                <StyledTableCell style={{ width: '150px', borderLeft: '1px solid #01458E' }} align="center">
                    {days}
                </StyledTableCell>
                <StyledTableCell style={{ width: '150px', borderLeft: '1px solid #01458E' }} align="center">
                    {loading ? <CircularProgress/> :  <NumberFormat value={currentRowSum} displayType={'text'} thousandSeparator={true} prefix={'£'} />}
                </StyledTableCell>
            </StyledTableRow>
        );
    },
);
export default SpacesComponent;

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#01458E',
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
            fontFamily: 'Example',
            letterSpacing: 0.4,
            '& > div': {
                padding: 10,
            },
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Example',
            backgroundColor: '#FDFFF9',
            height: '100vh',
        },
        venue: {
            fontSize: 20,
            textAlign: 'center',
        },
        formControl: {},
        space: {
            display: 'grid',
            flexDirection: 'column',
        },

        table: {
            borderRadius: 15,
        },
        spaceName: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            letterSpacing: 1,
            color: '#01458E',
            fontSize: 16,
            '&:hover': {
                color: '#5288e3',
            },
        },
        border: {
            borderLeft: '1px solid #01458E',
            borderRight: '1px solid #01458E',
        },
        border2: {
            borderLeft: '1px solid white',
            borderRight: '1px solid white',
        },
        dialogSpaceText: {
            textAlign: 'center',
            marginTop: 5,
            textTransform: 'uppercase',
        },
        fab: {
            margin: theme.spacing(2),
        },
        footerBar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        button: {
            width: 230,
            letterSpacing: 1,
            padding: '12px 20px 12px 20px',
            fontFamily: 'Example',
            margin: '0 auto',
        },
        dialogContainer: {
            width: 580,
            height: 450,
            padding: 10,
            borderRadius: 50,
            '& >div': {
                margin: '15px 0 15px 0',
            },
        },
        dialogInput: {
            display: 'flex',
        },
        textareaContainer: {
            marginBottom: 40,
        },
        textarea: {
            minWidth: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
        },
        disabledDays: { 
            "& .MuiPickersDay-root": {
             fontWeight: 'bold'
            },
          
                  "& .MuiButtonBase-root.Mui-disabled": {
                    cursor: 'not-allowed',
                    pointerEvents: 'all',
                    color: 'rgba(0, 0, 0, 0.30)',
                    backgroundColor: '#FFFFFF',
                    border: 'none'
                  }  
        },
        calendar: {
            margin: '0px 150px',
            '& .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value': {
                color: '#000000',
            },
        }
    }),
);
