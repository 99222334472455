import { SearchDateType, VenuesActionTypes, VenueType } from './types';
import { VenueResponseType } from '../../api/types';

export const getVenuesAC = (venues: Array<VenueResponseType>) =>
    ({
        type: VenuesActionTypes.FETCH_VENUE,
        payload: {
            venues,
        },
    } as const);

export const getVenueByIdAC = (venue: VenueType) =>
    ({
        type: VenuesActionTypes.FIND_VENUE,
        payload: {
            venue,
        },
    } as const);

export const deleteCurrentVenueAC = () =>
    ({
        type: VenuesActionTypes.DELETE_VENUE,
    } as const);

export const setSearchDataAC = (searchDate: SearchDateType) =>
    ({
        type: VenuesActionTypes.SET_SEARCH_DATE,
        payload: {
            searchDate,
        },
    } as const);
