import React from 'react';
import { useFormik } from 'formik';
import {
    Button,
    Card,
    createStyles,
    InputAdornment,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { DateRange } from '@material-ui/lab';
import * as Yup from 'yup';

import SelectedDateComponent from '../../SelectedDateComponent/SelectedDateComponent';
import Routes from '../../../routes';
import { SearchDateType } from '../../../ducks/venue/types';
import { formatDate } from '../../../helpers/formatDate';

export interface IInitialValues {
    point: string;
  //  date: DateRange<Date | null>;
}

type BookYourSpaceFormPropsType = {
    searchDataHandler: (searchDate: SearchDateType) => void;
};

const validationSchema = Yup.object().shape({
    point: Yup.string().required('Point is required'),
});

const initialValues: IInitialValues = {
    point: '',
    //date: [null, null],
};

const BookYourSpaceForm: React.FC<BookYourSpaceFormPropsType> = ({ searchDataHandler }) => {
    const classes = useStyles();
    const history = useHistory();
    //const [selectedDate, handleDateChange] = React.useState<DateRange<Date | null>>([null, null]);

    const formik = useFormik({
        validate: (values: IInitialValues) => {
            if (!values.point) {
                return {
                    point: 'Point is required',
                };
            }
        },
        initialValues,
        onSubmit: (values: IInitialValues) => {
            // formik.values.date = [selectedDate[0], selectedDate[1]];
            // if (!formik.values.date[0] || !formik.values.date[1]) {
            //     return;
            // }
            searchDataHandler({
                search: formik.values.point,
                // dateIn: formik.values.date[0],
                // dateOut: formik.values.date[1],
            });
            history.push(
                 `${Routes.LOCATIONS_FOUND}/?point=${values.point}`
                //  checkin=${formatDate(formik.values.date[0])}&checkout=${formatDate(
                //      formik.values.date[1],
                //  )}&
                // point=${values.point}`,
            );
        },
        validationSchema,
    });

    return (
        <Card className={classes.root}>
            <Typography variant="h6" className={classes.text}>
            Check availability and pricing for your Promotional Kiosk of interest
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    className={classes.margin}
                    name={'point'}
                    value={formik.values.point}
                    onChange={formik.handleChange}
                    error={!!(formik.errors.point && formik.touched.point)}
                    onBlur={formik.handleBlur}
                    helperText={formik.errors.point && formik.touched.point && formik.errors.point}
                    variant="outlined"
                    placeholder="Town, shopping centre"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                {/* <SelectedDateComponent
                    name={'date'}
                    value={formik.values.date}
                    onChange={formik.handleChange}
                    handleDateChange={handleDateChange}
                    selectedDate={selectedDate}
                /> */}
                <div className={classes.searchContainer}>
                    <div>
                        <Button type="submit" variant="contained" color="primary" className={classes.button}>
                            Search
                        </Button>
                    </div>
                </div>
            </form>
        </Card>
    );
};

export default BookYourSpaceForm;

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            width: 420,
            padding: '0 20px 0 20px',
        },
        text: {
            marginTop: 40,
            textTransform: 'uppercase',
            textAlign: 'start',
            fontFamily: 'Example',
            fontSize: 21,
        },
        margin: {
            margin: '10px 0 10px 0',
            width: '100%',
        },
        searchContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '10px 0 10px 0',
        },
        button: {
            letterSpacing: 1,
            padding: '10px 5px 10px 5px',
            textTransform: 'none',
        },
    }),
);
