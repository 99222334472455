import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    CardMedia,
    Container,
    createStyles,
    Grid,
    LinearProgress,
    makeStyles,
    Paper,
    Theme,
    Typography,
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';

import { currentVenueSelector } from '../../ducks/venue/selectors';
import { VenuesActionTypes } from '../../ducks/venue/types';
import SpaceTable from './SpaceTable/SpaceTable';
import { deleteCustomerActionCreator, deleteSpaceActionCreator } from '../../ducks/booking/action';
import { customerDetailsSelector, customerSpacesSelector } from '../../ducks/booking/selectors';

const VenueDetailsPage: React.FC = () => {
    const classes = useStyles();
    const findVenue = useSelector(currentVenueSelector);
    const customerSpaces = useSelector(customerSpacesSelector);
    const customerDetails = useSelector(customerDetailsSelector);
    const dispatch = useDispatch();
    const { id: venueId } = useParams<{ id: string }>();
    const defaultImageUrl = `${process.env.REACT_APP_API_URL}/images/default.jpeg`;

    useEffect(() => {
        dispatch({ type: VenuesActionTypes.GET_VENUE, payload: { id: venueId } });
        if (customerSpaces.length > 0) {
            dispatch(deleteSpaceActionCreator());
        }
        if (customerDetails.email) {
            dispatch(deleteCustomerActionCreator());
        }
    }, [dispatch, venueId, customerSpaces.length, customerDetails.email]);

    const fullImageFromServer = `${process.env.REACT_APP_API_URL}/images/${findVenue.id}_full.jpg`

    const cardImage = {
        color: 'blue',
        backgroundImage: `url(${fullImageFromServer})`,
    };

    const defaultImage = {
        color: 'blue',
        backgroundImage: `url(${defaultImageUrl})`,
    };

    return +venueId === findVenue.id && findVenue.address ? (
      <Container maxWidth="lg" className={classes.root}>
        <Typography variant="h6" className={classes.venueName}>
          {findVenue.name}
        </Typography>
        <CardMedia
          className={classes.image}
          style={findVenue.hasImage === "true" ? cardImage : defaultImage}
        >
          <div />
        </CardMedia>
        <div className={classes.venueContainer}>
          <div className={classes.venueDetails}>
            <div className={classes.venueName}>{findVenue.name}</div>
            <div>Address: {findVenue.address}</div>
            <div>
              Region: {findVenue.town}, {findVenue.region}
            </div>
          </div>
          <Paper className={classes.paperCoontainer}>
            <div className={classes.visitorsDemographicsContainer}>
              {findVenue.avgDailyCost ? (
                <div>
                  <Paper
                    style={{ backgroundColor: "#A8E7F0" }}
                    className={classes.paper}
                  >
                    <div>
                        <div>
                      {" "}
                      <NumberFormat
                        value={findVenue.avgDailyCost}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"£"}
                      />
                    </div>
                    <div>Indicative Weekly Price</div>
                  </div>
                  </Paper>
                </div>
              ) : null}
              {findVenue.weeklyFootfall ? (
                <div>
                  <Paper
                    style={{ backgroundColor: "#1F5962" }}
                    className={classes.paper}
                  >
                      <div>
                    <div>
                      <NumberFormat
                        value={findVenue.weeklyFootfall}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </div>
                    <div>Weekly Footfall</div>
                    </div>
                  </Paper>
                </div>
              ) : null}
              {findVenue.ageInfo ? (
                <div>
                  <Paper
                    style={{ backgroundColor: "#97D6DF" }}
                    className={classes.paper}
                  >
                      <div>
                    <div>{findVenue.ageInfo}</div>
                    <div>Majority Age Group</div>
                    </div>
                  </Paper>
                </div>
              ) : null}
              {findVenue.femalePercentage ? (
                <div>
                  <Paper
                    style={{ backgroundColor: "#A8E7F0" }}
                    className={classes.paper}
                  >
                       <div>
                    <div>{findVenue.femalePercentage}%</div>
                    <div>Female</div>
                    </div>
                  </Paper>
                </div>
              ) : null}
              {findVenue.catchmentPercent ? (
                <div>
                  <Paper
                    style={{ backgroundColor: "#1F5962" }}
                    className={classes.paper}
                  >
                       <div>
                    <div>{findVenue.catchmentPercent}</div>
                    <div>Within 30 min. drive time</div>
                    </div>
                  </Paper>
                </div>
              ) : null}
              {findVenue.abc1percentage ? (
                <div>
                  <Paper
                    style={{ backgroundColor: "#6EA9B2" }}
                    className={classes.paper}
                  >
                      <div>
                    <div>{findVenue.abc1percentage}%</div>
                    <div>ABC1 </div>
                    </div>
                  </Paper>
                </div>
              ) : null}
            </div>
          </Paper>
        </div>
        <div style={{ fontSize: 20, marginBottom: 50 }}>
          {ReactHtmlParser(findVenue.details)}
        </div>
        <SpaceTable />
      </Container>
    ) : (
      <LinearProgress />
    );
};

export default VenueDetailsPage;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      fontFamily: "Example",
    },
    venueName: {
      marginTop: 20,
      letterSpacing: 1,
      fontSize: 20,
      fontWeight: "bold",
    },
    image: {
      marginTop: 10,
      width: "100%",
      height: 400,
      borderRadius: 20,
    },
    venueContainer: {
      display: "flex",
      marginBottom: 20,
      marginTop: 20,
      "& > div": {
        fontSize: 20,
      },
      "@media screen and (max-width: 770px)": {
        flexDirection: "column",
      },
    },
    venueDetails: {
      width: "100%",
      "@media screen and (max-width: 1055px)": {
        flex: "1",
      },
    },
    visitorsDemographicsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      flexWrap: "wrap",
      width: "100%",
      padding: "5px",
      "& > div": {
        flexBasis: "50%",
        flexGrow: "1",
        padding: "5px 5px",
        /* On screens that are 992px or less, set the background color to blue */
      },
    },
    paperCoontainer: {
      "@media screen and (max-width: 1055px)": {
        flex: "2",
        margin: "15px 0px",
      },
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      fontSize: 18,
      color: "white",
      textTransform: "uppercase",
      height: "100%",
      display: "table",
      width: "100%",
      "& > div": {
        display: "table-cell",
        verticalAlign: "middle",
      },
    },
    papers: {
      padding: "10px 5px 10px 10px",
    },
  })
);
