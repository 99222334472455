import { UserActionsType, UserActionTypes, UserType } from './types';

const initialState: UserType = {} as UserType

const userReducer = (state = initialState, action: UserActionsType): UserType => {
    switch (action.type) {
        case UserActionTypes.SET_USER:
            return {
                ...state,
                email: action.payload.email,
                id: action.payload.id,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
            };
        case UserActionTypes.FETCH_USER: {
            return {
                ...state,
                ...action.payload.user
            }
        }
        default:
            return state;
    }
};

export default userReducer;
