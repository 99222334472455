import { AuthActionsType, AuthActionTypes, AuthState } from './types';

const initialState: AuthState = {
    loading: false,
    authenticated: false,
    errors: null,
    resetPasswordRequestSent: false,
    recoverPasswordRequestSent: false,
    signUpSucceeded: false,
    updatePassword: false,
    updateProfile: false,
    updateUserData: false,
};

const authReducer = (state = initialState, action: AuthActionsType): AuthState => {
    switch (action.type) {
        case AuthActionTypes.REQUEST: {
            return { ...state, loading: true, errors: null };
        }
        case AuthActionTypes.LOGIN_SUCCESS: {
            return { ...state, authenticated: true, errors: null, loading: false };
        }
        case AuthActionTypes.LOGIN_ERROR: {
            return { ...state, authenticated: false, errors: action.payload.errors, loading: false };
        }
        case AuthActionTypes.SIGN_UP_SUCCESS: {
            return { ...state, authenticated: false, errors: null, loading: false, signUpSucceeded: true };
        }
        case AuthActionTypes.SIGN_UP_ERROR: {
            return {
                ...state,
                authenticated: false,
                errors: action.payload.errors,
                loading: false,
                signUpSucceeded: false,
            };
        }
        case AuthActionTypes.SIGN_UP_CLOSE: {
            return { ...state, signUpSucceeded: false };
        }
        case AuthActionTypes.LOGOUT_SUCCESS: {
            return { ...state, authenticated: false };
        }
        case AuthActionTypes.RESET_ERROR: {
            return { ...state, errors: null };
        }
        case AuthActionTypes.RESET_PASSWORD_SUCCESS: {
            return { ...state, resetPasswordRequestSent: true, errors: null }
        }
        case AuthActionTypes.RESET_PASSWORD_ERROR: {
            return { ...state, resetPasswordRequestSent: false, errors: action.payload.errors }
        }
        case AuthActionTypes.RESET_PASSWORD_MODAL_SHOWN: {
            return { ...state, resetPasswordRequestSent: false, errors: null }
        }
        case AuthActionTypes.RECOVER_PASSWORD_SUCCESS: {
            return { ...state, recoverPasswordRequestSent: action.payload.isSuccess, errors: null }
        }
        case AuthActionTypes.UPDATE_PASSWORD_SUCCESS: {
            return { ...state, updatePassword: action.payload.isSuccess, errors: null }
        }
        case AuthActionTypes.UPDATE_PASSWORD_ERROR: {
            return { ...state, updatePassword: false, errors: action.payload.errors }
        }
        case AuthActionTypes.UPDATE_USER_DATA_SUCCESS: {
            return { ...state, updateUserData: action.payload.isSuccess, errors: null }
        }
        case AuthActionTypes.UPDATE_USER_DATA_ERROR: {
            return { ...state, updateUserData: false, errors: action.payload.errors }
        }
        default:
            return state;
    }
};

export default authReducer;
