import { all, AllEffect, call, ForkEffect, takeEvery } from 'redux-saga/effects';

import { FetchCalculatedPeriodCostAction, GetBookingTimeBySpaceAction, SpaceActionTypes } from './types';
import { spacesAPI } from '../../api/spacesAPI';

export function* getBookingTimeBySpace(action: GetBookingTimeBySpaceAction) {
    try {
        const response = yield call(spacesAPI.getSpaceBookingData, action.payload.spaceId);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

function* fetchCalculatedPeriodCost(action: FetchCalculatedPeriodCostAction) {
    try {
        const response = yield call(spacesAPI.fetchCalculatedPeriodCost, action.payload.spaceId, action.payload.date);
        return response.data
    } catch (e) {
        console.log(e)
    }
}

function* watchGetSpaceBookingDataBySpaces() {
    yield takeEvery(SpaceActionTypes.FETCH_CALCULATED_PERIOD_COST, fetchCalculatedPeriodCost);
    yield takeEvery(SpaceActionTypes.GET_BOOKING_TIMES_BY_SPACE, getBookingTimeBySpace);
}

export default function* spacesSaga(): Generator<AllEffect<Generator<ForkEffect<never>, void, unknown>>,
    void,
    unknown> {
    yield all([watchGetSpaceBookingDataBySpaces()]);
}
