import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardActionArea, CardMedia, makeStyles, Typography } from '@material-ui/core';

import { VenueType } from '../../../ducks/venue/types';
import NumberFormat from 'react-number-format';

const LocationCardComponent: React.FC<VenueType> = ({
                                                        id,
                                                        thumbnailUrl,
                                                        address,
                                                        avgDailyCost,
                                                        name,
                                                        postCode,
                                                        town,
                                                        hasImage
                                                    }) => {
    const classes = useStyles();
    const history = useHistory();

    const defaultImageUrl = `${process.env.REACT_APP_API_URL}/images/default.jpeg`;

    const imageFromServer = `${process.env.REACT_APP_API_URL}/images/${id}_preview.jpg`

    const cardImage = {
        color: 'blue',
        backgroundImage: `url(${imageFromServer})`,
    };

    const defaultImage = {
        color: 'blue',
        backgroundImage: `url(${defaultImageUrl})`,
    };

    const venueHandler = useCallback(() => {
        history.push(`/venues/${id}`);
    }, [history, id]);

    return (
        <Card className={classes.root} onClick={venueHandler}>
            <CardActionArea className={classes.venueActionAria}>
                <div>
                    <CardMedia className={classes.image} style={hasImage === 'true' ? cardImage : defaultImage}>
                        <div/>
                    </CardMedia>
                </div>
                <div className={classes.venueContainer}>
                    <Typography variant={'h6'} className={classes.venueTitle}>
                        {name}
                    </Typography>
                    <div>{address}</div>
                    <div>{postCode}</div>
                    <div>{town}</div>
                    {/* <div>Average daily price: £{avgDailyCost}</div> */}
                    <div>Indicative Weekly Price: <NumberFormat value={avgDailyCost} displayType={'text'} thousandSeparator={true} prefix={'£'} /></div>

                </div>
            </CardActionArea>
        </Card>
    );
};

export default LocationCardComponent;

const useStyles = makeStyles({
    root: {
        height: 200,
        margin: '0 10px 10px 0',
    },
    venueActionAria: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    image: {
        width: 300,
        height: 200,
        backgroundColor: 'grey',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    venueContainer: {
        height: 200,
        marginLeft: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        '& > div': {
            fontFamily: 'Example',
            letterSpacing: 1,
        },
    },
    venueTitle: {
        fontSize: 20,
        fontFamily: 'Example',
        letterSpacing: 1,
        fontWeight: 'bold',
    },
    div: {
        fontFamily: 'Example',
        letterSpacing: 1,
    },
    details: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 600,
    },
});
