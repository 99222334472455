const Routes = {
    ONBOARDING: '/',
    HOME: '/home',
    LOGIN: '/login',
    SIGN_UP: '/sign-up',
    RESET_PASSWORD: '/reset-password',
    PASSWORD_RECOVERY: '/password-recovery/:token',
    SEARCH_PROMOTIONS: '/search-promotions',
    LOCATIONS_FOUND: '/locations-found',
    PROFILE: '/me',
    AGREEMENT_PAGE: '/terms-and-conditions',
    VENUE_DETAILS_PAGE: '/venues/:id',
    BOOKING_PAGE: '/booking-page',
    NOT_AUTHORIZED: '/not-authorized',
    BOOKING_DETAILS_PAGE: '/booking-page/:id',
};

export default Routes;
