import { UserType, UserActionTypes } from './types';

export const updateUserActionCreator = (email: string, id: number, firstName: string, lastName: string) => ({
    type: UserActionTypes.SET_USER,
    payload: { email, id, firstName, lastName },
} as const);

export const fetchUserActionCreator = (user: UserType) => ({
    type: UserActionTypes.FETCH_USER,
    payload: {
        user
    }
} as const)





