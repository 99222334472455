import { RootState } from '../../store';

export const bookingDataSelector = (state: RootState) => state.booking.data;
export const bookingDataIdSelector = (state: RootState) => state.booking.data;
export const confirmedDataSelector = (state: RootState) => state.booking.confirmedData;
export const receivedBookingDataIdSelector = (state: RootState) => state.booking.bookingByIdData;
export const customerSpacesSelector = (state: RootState) => state.booking.details.customerSpaces;
export const customerDetailsSelector = (state: RootState) => state.booking.details.customerDetails;
export const bookingErrorsSelector = (state: RootState) => state.booking.errors;
export const bookingCancelledPaymentSelector = (state: RootState) => state.booking.cancelled;
export const isCreatedPaymentSelector = (state: RootState) => state.booking.created;
