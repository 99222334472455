import { deleteCurrentVenueAC, getVenueByIdAC, getVenuesAC, setSearchDataAC } from './actions';

export enum VenuesActionTypes {
    FETCH_VENUE = '@@venues/FETCH_VENUE',
    FIND_VENUE = '@@venues/FIND_VENUE',
    DELETE_VENUE = '@@venues/DELETE_VENUE',
    CHECK_VENUES = '@@venues/CHECK_VENUES',
    GET_VENUE = '@@venues/GET_VENUE',
    SET_SEARCH_DATE = '@@venues/SET_SEARCH_DATE',
}

export interface getVenueAction {
    type: VenuesActionTypes.GET_VENUE;
    payload: {
        id: number;
    };
}

//types
export type VenueType = {
    name: string;
    id: number;
    mappingId: number;
    region: string;
    address: string;
    postCode: string;
    town: string;
    weeklyFootfall: number;
    details: string;
    ageInfo: string;
    catchmentPercent: number;
    femalePercentage: number;
    abc1percentage?: number;
    avgDailyCost: string;
    avgWeekendCost: string;
    thumbnailUrl: string;
    createdAt: string;
    updatedAt: string;
    hasImage: string;
    additionalCharge:string;
    Spaces: Array<SpacesType>;
};

export type SearchDateType = {
    search: string;
    // dateIn: Date;
    // dateOut: Date;
};

export type SpacesType = {
    VenueId: number;
    avgDailyCost: string;
    avgWeekendCost: string;
    createdAt: string;
    index: number;
    id: number;
    checkInDate?: Date | null;
    checkOutDate?: Date | null;
    location: string;
    info: string;
    mappingId: number;
    name: string;
    siteMapUrl: string;
    updatedAt?: string;
    venueId: number;
    amout?: number;
    end?: string;
    siteId?: number;
    start?: number;
};

//actions
type getVenueActionType = ReturnType<typeof getVenuesAC>;
type findVenueActionType = ReturnType<typeof getVenueByIdAC>;
type deleteVenueByIdActionType = ReturnType<typeof deleteCurrentVenueAC>;
type setSearchDataActionType = ReturnType<typeof setSearchDataAC>;

export type venuesActionTypes =
    | getVenueActionType
    | findVenueActionType
    | deleteVenueByIdActionType
    | setSearchDataActionType;
