import React, { useRef, useImperativeHandle } from 'react';

const StripeInput: React.FC<any> = ({ component: Component, inputRef, ...props }) => {
    const elementRef = useRef<any>();
    useImperativeHandle(inputRef, () => ({
        focus: () => elementRef.current.focus,
    }));
    return <Component onReady={(element: any) => (elementRef.current = element)} {...props} />;
};
export default StripeInput;
