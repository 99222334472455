import React, { FormEvent, useState } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, CircularProgress, makeStyles, Paper, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import cardImage from '../../assets/credit_cards@3x.png';
import StripeInput from '../../components/StripeInput/StripeInput';
import PaymentService from '../../services/paymentService';
import { BookingDataType } from '../../api/types';

type StripePagePropsType = {
    handleBack: () => void;
    openConfirmBooking: (switcher: boolean) => void;
    setOpenPayment: (switcher: boolean) => void;
    dataToSend: BookingDataType;
    setOpen: (switcher: boolean) => void
    isPartialPayment: boolean
    additionalCharge: string
};

const StripePage: React.FC<StripePagePropsType> = React.memo(({
                                                                  handleBack,
                                                                  setOpenPayment,
                                                                  openConfirmBooking,
                                                                  dataToSend,
                                                                  setOpen,
                                                                  isPartialPayment,
                                                                  additionalCharge,
                                                              }) => {
    const classes = useStyles();
    const history = useHistory();
    const stripe: any = useStripe();
    const elements: any = useElements();
    const [disable, setDisable] = useState<boolean>(false);
    const [errors, setErrors] = useState<null | string>(null)
    const fullCharged = (dataToSend.totalCost + Number(additionalCharge)).toFixed(2);
    const depositOfFullCharged = (dataToSend.totalCost * 25 / 100 + Number(additionalCharge)).toFixed(2);

    const backToConfirmDataHandler = () => {
        handleBack();
        openConfirmBooking(true);
        setOpenPayment(false);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        setDisable(true);
        setOpen(false);
        event.preventDefault();

        if (!stripe || !elements) {
            setDisable(false);
            return;
        }

        const cardElement = elements && elements.getElement(CardNumberElement);
        const response = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: dataToSend.companyName,
                //email: dataToSend.email,
                phone: dataToSend.phoneNumber,
                address: {
                    line1: dataToSend.addressLineFirst,
                },
            },
        });

        if (response.error) {
            setErrors(response.error.message);
        } else {
            const createPaymentResponce = await PaymentService.createPayment({
                paymentMethod: response.paymentMethod.id,
                percentage: isPartialPayment ? 25 : 100,
                booking:dataToSend,
            })

            const redirectUrl = createPaymentResponce.url ? createPaymentResponce.url : null

            if (response.error) {
                setErrors(response.error.message)
            }

            if (redirectUrl) {
                window.location.href = createPaymentResponce.url
            }
            if (createPaymentResponce.state === 'submitted') {
                history.push(`/booking-page/${createPaymentResponce.id}/?created=true`)
                setDisable(false);
            }
        }
        setDisable(false);
    };

    return (
        <Paper variant={'elevation'} elevation={3} className={classes.root}>

            {isPartialPayment ? <div
                style={{ textAlign: 'center', fontSize: 18, fontFamily: 'Example', }}>
                <p>A deposit of £{depositOfFullCharged} will be charged</p>
            </div> : <div
                style={{ textAlign: 'center', fontSize: 18, fontFamily: 'Example', letterSpacing: 1 }}>
                <p>You will be charged the full amount of £{fullCharged}</p>
            </div>}
            <div className={classes.formGroup}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <p className={classes.label} style={{ display: 'block' }}>
                        Accepted Cards
                    </p>
                    {disable && <CircularProgress style={{ alignSelf: 'center', marginLeft: 10 }} size={20}/>}
                </div>
                <img src={cardImage} alt="credit card example" className={classes.creditCardImage}/>
            </div>
            <form onSubmit={handleSubmit}>
                <div style={{ display: 'flex', alignItems: 'center', margin: 30 }}>
                    <div>
                        <TextField
                            style={{ width: 240 }}
                            label="cardNumber"
                            variant="outlined"
                            required
                            margin="dense"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: {
                                    component: CardNumberElement,
                                },
                            }}
                        />
                    </div>
                    <div className={classes.payCardField}>
                        <TextField
                            style={{ width: 100, marginLeft: 10 }}
                            label="CVC"
                            margin="dense"
                            variant="outlined"
                            required
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: {
                                    component: CardCvcElement,
                                },
                            }}
                        />
                    </div>
                    <div className={classes.payCardField}>
                        <TextField
                            style={{ width: 150 }}
                            label="Expiration Date"
                            variant="outlined"
                            margin="dense"
                            required
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: {
                                    component: CardExpiryElement,
                                },
                            }}
                        />
                    </div>
                </div>

                <div style={{ marginLeft: 160 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={backToConfirmDataHandler}
                        disabled={disable}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={disable}
                        type="submit"
                        className={classes.button}
                    >
                        Pay
                    </Button>
                </div>
            </form>
        </Paper>
    );
});

export default StripePage;

const useStyles = makeStyles({
    root: {
        margin: '0 auto',
        width: 600,
    },
    formGroup: {
        marginTop: 10,
        height: 100,
    },
    label: {
        fontSize: 17,
        fontFamily: 'Example',
        color: '#062a1a',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    warning: {
        fontSize: 12,
    },
    creditCardImage: {
        width: 250,
        height: 40,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    button: {
        width: 100,
        margin: 20,
    },
    pay: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        margin: '10px 0 10px 0',
        '&>div': {
            margin: 10,
        },
    },
    payCardField: {
        margin: 10,
    },
});
