import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import authReducer from './auth/reducer';
import authSaga from './auth/sagas';
import userReducer from './user/reducer';
import { venuesReducer } from './venue/reducer';
import venuesSaga from './venue/sagas';
import userSaga from './user/sagas';
import bookingSaga from './booking/sagas';
import bookingReducer from './booking/reducer';
import stripeSaga from './stripe/sagas';
import spacesSaga from './space/sagas';

export function* rootSaga() {
    yield all([authSaga(), userSaga(), venuesSaga(), bookingSaga(), stripeSaga(), spacesSaga()]);
}

export const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    venues: venuesReducer,
    booking: bookingReducer,
});
