import React from 'react';
import { makeStyles } from '@material-ui/core';
import PageWrapper from '../../components/PageWrapper/PageWrapper';

const ErrorPage: React.FC = () => {
    const classes = useStyles();
    const myClassName = 'far fa-question-circle fa-spin';

    return (
        <PageWrapper>
            <div>
                <div className={classes.mainBox}>
                    <div className={classes.errorFirstLetter}>4</div>
                    <div className={classes.far}>
                        <i className={myClassName} />
                    </div>
                    <div className={classes.errorSecondLetter}>4</div>
                    <div className={classes.text}>
                        Maybe this page moved? Got deleted? Is hiding out in quarantine? Never existed in the first
                        place?
                        <p>
                            Let&apos;s go{' '}
                            <a className={classes.footerText} href={'/'}>
                                home
                            </a>{' '}
                            and try from there.
                        </p>
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};

export default ErrorPage;

const useStyles = makeStyles(() => ({
    mainBox: {
        margin: 'auto',
        height: 600,
        width: 600,
        position: 'relative',
    },
    errorFirstLetter: {
        color: '#3554aa',
        fontFamily: 'Example',
        fontSize: '11rem',
        position: 'absolute',
        left: '20%',
        top: '8%',
        fontWeight: 'bold',
    },
    far: {
        position: 'absolute',
        fontSize: '8.5rem',
        left: '41%',
        top: '13%',
        color: '#3554aa',
    },
    errorSecondLetter: {
        color: '#3554aa',
        fontFamily: 'Example',
        fontSize: '11rem',
        position: 'absolute',
        left: '68%',
        top: '8%',
        fontWeight: 'bold',
    },
    text: {
        textAlign: 'center',
        fontFamily: 'Example',
        fontSize: '1.6rem',
        position: 'absolute',
        left: '16%',
        top: '45%',
        width: '75%',
    },
    footerText: {
        textDecoration: 'none',
        color: '#3554aa',
        '&:hover': {
            color: '#803daa',
        },
    },
}));
