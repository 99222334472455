import { RootState } from '../../store';

export const authenticatedSelector = (state: RootState) => state.auth.authenticated;
export const resetPasswordRequestSelector = (state: RootState) => state.auth.resetPasswordRequestSent;
export const recoveryPasswordRequestSelector = (state: RootState) => state.auth.recoverPasswordRequestSent;
export const authErrorsSelector = (state: RootState) => state.auth.errors;
export const signUpSucceededSelector = (state: RootState) => state.auth.signUpSucceeded;
export const loadingSelector = (state: RootState) => state.auth.loading;
export const updatePasswordSelector = (state: RootState) => state.auth.updatePassword;
export const updateUserSelector = (state: RootState) => state.auth.updateUserData;
