import React from 'react';
import { makeStyles } from '@material-ui/core';

import PageWrapper from '../../components/PageWrapper/PageWrapper';

const AgreementPage: React.FC = () => {
    const classes = useStyles();

    return (
        <PageWrapper>
            <div>
                <h1 className={classes.textCenter}>Terms and Conditions</h1>
                <p>The Parties hereby agree to the following terms and conditions:</p>
                <p><strong>1&nbsp; &nbsp;Defined terms</strong></p>
                <p>&ldquo;<strong>Agreement</strong>&rdquo; means this agreement;</p>
                <p>&ldquo;<strong>Code of Conduct</strong>&rdquo; means any code of conduct and venue Rules and Regulations with which Operator must comply;</p>
                <p>&ldquo;<strong>End Date</strong>&rdquo; means the end date set out in Key Commercial Definitions;</p>
                <p>&ldquo;<strong>Force Majeure Event</strong>&rdquo; means, but is not limited to: closure of Venue, acts of God, fire, flood, earthquake, windstorm or other natural disaster; war, threat of or preparation for war, armed conflict or similar actions; terrorist attack, civil war, civil commotion or riots; compliance with any law; extreme adverse weather conditions; collapse of building structures, failure of utilities, plant machinery, machinery, computers or vehicles; any labour dispute, including but not limited to strikes, industrial action or lockouts; and non-performance by suppliers or subcontractors;</p>
                <p>&ldquo;<strong>Hours of Operation</strong>&rdquo; means all hours that Venue is open for business to the general public or as Supplier may at its discretion stipulate from time to time;</p>
                <p>&ldquo;<strong>Key Commercial Definitions</strong>&rdquo; means those definitions set out as such at the start of this Agreement;</p>
                <p>&ldquo;<strong>Kiosk</strong>&rdquo; means a kiosk in Venue located at Location;</p>
                <p>&ldquo;<strong>Location</strong>&rdquo; means as stated in Key Commercial Definitions;</p>
                <p>&ldquo;<strong>Licence Period</strong>&rdquo; means the period between Start Date and End Date;</p>
                <p>&ldquo;<strong>Manager</strong>&rdquo; the manager from time to time employed at Venue;</p>
                <p>&ldquo;<strong>Operator</strong>&rdquo; means the party as stated in Key Commercial Definitions</p>
                <p>&ldquo;<strong>Operator&rsquo;s Point of Contact</strong>&rdquo; means person named in Key Commercial Definitions;</p>
                <p>&ldquo;<strong>Owner</strong>&rdquo; means the owner of Venue;</p>
                <p>&ldquo;<strong>Rules and Regulations</strong>&rdquo; means rules and regulations as set out in Schedule 1 and as may be amended from time to time by Supplier;</p>
                <p>&ldquo;<strong>Schedule</strong>&rdquo; means the schedule to this Agreement;</p>
                <p>&ldquo;<strong>Services</strong>&rdquo; means the services to be provided by Supplier under this Agreement as set out in clause 5.1 and Key Commercial Definitions;</p>
                <p>&ldquo;<strong>Start Date</strong>&rdquo; means the start date set out in Key Commercial Definitions;</p>
                <p>&ldquo;<strong>Supplier</strong>&rdquo; means SpaceandPeople plc;</p>
                <p>&ldquo;<strong>Supplier&rsquo;s Fee</strong>&rdquo; means the fee payable by Operator to Supplier for the provision of Services;</p>
                <p>&ldquo;<strong>VAT</strong>&rdquo; means value added tax chargeable under the applicable law for the time being and any similar additional tax;</p>
                <p>&ldquo;<strong>Venue</strong>&rdquo; means the location set out in Key Commercial Definitions.</p>
                <p>1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A person includes a natural person, corporate or unincorporated body (whether or not having separate legal personality).</p>
                <p>1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Words in the singular shall include the plural and vice versa.</p>
                <p>1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Reference to a statute or statutory provision is a reference to it as it is in force for the time being, taking account of any amendment, extension, or re-enactment including any subordinate legislation for the time being in force made under it.</p>
                <p>1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A reference to writing or written includes post and e-mail but not fax.</p>
                <p><strong>2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Grant</strong></p>
                <p>2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subject to Operator complying with this Agreement, Supplier grants Operator the right to use Kiosk at Location within Venue for the duration of Licence Period for Permitted Use during Hours of Operation.</p>
                <p>2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator acknowledges that:</p>
                <p>2.2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator shall occupy Location as a licensee only and no relationship of landlord and tenant is created between Owner or Supplier and Operator by this Agreement;</p>
                <p>2.2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Owner, retains control, possession and management of Location and Operator has no right to exclude Owner, Manager or his agent or Supplier from Location;</p>
                <p>2.2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; this Agreement is personal to Operator and is not assignable and the rights given in this Agreement may only be exercised by Operator and its employees.</p>
                <p>2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Without prejudice to its rights to terminate this Agreement, Owner, Manager and Supplier are entitled at any time to require Operator to transfer to an alternative Location within Venue and Operator shall comply with such requirement.</p>
                <p>2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Manager has total discretion to refuse permission to Operator to use any advertisement if Manager considers such advertisement or display to be offensive or illegal, and in such case Operator will remain liable to pay such charges as agreed. Manger&rsquo;s decision on all aspects of the activities carried out in/on Location by Operator shall be final.</p>
                <p>2.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This Agreement does not give exclusive possession to Operator of Kiosk, Location or any specific location within Venue and Supplier, Owner and all others authorised by them shall be entitled to enter any part of Kiosk and/or Location at any time to ensure compliance by Operator of the terms of this Agreement and/or Code of Conduct and are entitled to require Operator to vacate Kiosk and any part of Venue at any time in the event of breach by Operator of the terms of this Agreement and/or Code of Conduct.</p>
                <p><strong>3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Commencement and duration</strong></p>
                <p>3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This Agreement shall commence on the date of this Agreement and unless otherwise terminated in accordance with clause 13, shall continue until End Date.</p>
                <p>3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator&rsquo;s rights granted pursuant to this Agreement shall cease in the case of Location on End Date or earlier on sooner determination of this Agreement.</p>
                <p><strong>4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Permitted Use</strong></p>
                <p>4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator will use Kiosk during Licence Period for the sole purpose of the advertising and promotion of Operator&rsquo;s business or cause (&ldquo;Permitted Use&rdquo;) and for no other purpose whatsoever.</p>
                <p><strong>5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Supplier&rsquo;s obligations</strong></p>
                <p>5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Supplier shall use reasonable endeavours to provide the following services to Operator:</p>
                <p>5.1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; preparing Kiosk for use by Operator; and</p>
                <p>5.1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; carrying out routine maintenance and cleaning of Kiosk.</p>
                <p><strong>6&nbsp; &nbsp; &nbsp; &nbsp; Operator&rsquo;s obligations</strong></p>
                <p>6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator shall:</p>
                <p>6.1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; conduct its business in a manner consistent with reputable business standards and practices and operate Kiosk continuously and uninterruptedly during Licence Period and throughout Hours of Operation;</p>
                <p>6.1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not attempt to move, alter or relocate Kiosk without the prior consent of Supplier and Owner;</p>
                <p>6.1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; provide, in a timely manner, such information as Supplier may require and ensure that it is accurate in all material respects.</p>
                <p>6.1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; comply with Code of Conduct;</p>
                <p>6.1.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; notify Supplier immediately of damage to Kiosk or Location and allow Supplier access to Kiosk to repair or replace it;</p>
                <p>6.1.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not use any furniture, equipment, goods or personal property on or in relation to the Kiosk without the written consent of Supplier;</p>
                <p>6.1.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; keep Location in the same state of repair and condition as it was in at the start of this Agreement;</p>
                <p>6.1.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; store equipment and other materials in such a position and in such a place in Venue as Manager or its agents shall from time to time prescribe;</p>
                <p>6.1.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; keep Kiosk and surrounding area clean, tidy and clear of rubbish, making no alterations, damage, holes or markings and leave the same in a clean and tidy condition free from damage and free of any of Operator&rsquo;s furniture, equipment goods and personal property at End Date or on earlier termination of this Agreement howsoever arising;</p>
                <p>6.1.10&nbsp;&nbsp;&nbsp; not display any signs or notices on Kiosk without the prior written consent of Supplier;</p>
                <p>6.1.11&nbsp;&nbsp;&nbsp; not use Kiosk or any access routes in Venue in such a way as to cause any nuisance, damage, disturbance, annoyance, inconvenience or interference to Venue or neighbouring property or to Owner&rsquo;s occupiers or users of Venue;</p>
                <p>6.1.12&nbsp;&nbsp;&nbsp; not impede Supplier or its representatives or agents in the exercise of Supplier&rsquo;s rights of possession and control of Kiosk and each and every part thereof;</p>
                <p>6.1.13&nbsp;&nbsp;&nbsp; conduct its business subject to all UK laws and has sole responsibility that these laws are respected;</p>
                <p>6.1.14&nbsp;&nbsp;&nbsp; ensure that all advertising messages are decent, honest and true at all times and to remove any immediately if required by Supplier or Manager;</p>
                <p>6.1.15&nbsp;&nbsp;&nbsp; immediately notify Supplier of any issue which arises in relation to Kiosk which may affect Services or this Agreement;</p>
                <p>6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If Supplier&rsquo;s performance of its obligations under this Agreement are prevented or delayed by any act or omission of Operator, its agents, subcontractors, consultants or employees, Supplier shall not be liable for any costs, charges or losses sustained or incurred by Operator that arise directly or indirectly from such prevention or delay.</p>
                <p>6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator shall be liable to pay to Supplier and/or Owner, on demand, all reasonable costs, charges or losses sustained or incurred by Supplier and/or Owner (including any direct, indirect or consequential losses, loss of profit and/or loss of reputation, loss or damage to property and those arising from injury to or death of any person and loss of opportunity to deploy resources elsewhere) that arise directly or indirectly from any acts or omission of Operator, including Operator&rsquo;s fraud, negligence, failure to perform or delay in the performance of any of its obligations under this Agreement.</p>
                <p>6.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator will, prior to Start Date, provide evidence of public liability and employers&rsquo; liability insurance with an insurance provider of good repute to the value of a minimum of &pound;10 million per occurrence in respect of each and every claim and any other insurances deemed necessary by Supplier and/or Owner, in respect of its operation of Kiosk at Location and comply with any recommendations and requirements of the insurers which have been notified to it.</p>
                <p>6.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator hereby agrees that Supplier, and any other companies related to or affiliated with Supplier (&ldquo;Related Companies&rdquo;) may create and/or use images of Operator&rsquo;s activities, materials and personnel in Venue, and hereby grants Supplier and its Related Companies an irrevocable, royalty-free, worldwide licence to use any such images and/or any trademarks and trade names of Operator for the purpose of referencing the activity in materials promoting Supplier&rsquo;s and Related Companies&rsquo; business.</p>
                <p>6.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator will: ensure that it employs such persons as are appropriate to ensure Location is attended at all times and procure that its employees and agents do not smoke, eat or drink in or around Location.</p>
                <p>6.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator will not:</p>
                <p>6.7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; operate in Venue any wireless network, base station, gateway or analogous wireless device other than a network or device that is at all times protected by encryption in accordance with the then current best practice for the protection of wireless devices in commercial premises;</p>
                <p>6.7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; drive or allow any motor vehicles into Venue.</p>
                <p>6.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator is responsible at all times throughout Licence Period for the setting up and moving of items and displays at Location, which must be undertaken by Operator under the supervision of Manager or his agents as required using only such parts of Venue as may be designated by Manager.</p>
                <p><strong>7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Owner&rsquo;s rights</strong></p>
                <p>7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Owner reserves the following rights for its own benefit and for the benefit of other occupiers of Venue:</p>
                <p>7.1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; to build on, alter, repair, maintain, add to or develop any other part of Venue and to erect scaffolding outside Location so long as access to Location is not materially obstructed;</p>
                <p>7.1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; to use conduits passing through Location for the passage or transmission of utilities;</p>
                <p>7.1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; support for Venue from Location; and</p>
                <p>7.1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; rights of escape through Location.</p>
                <p><strong>8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Alterations</strong></p>
                <p>8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator shall not make any alterations or additions to Location and/or Venue or to cause any damage to them.</p>
                <p>8.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator shall not permit or cause any damage obstruction to or interference with the operation of Venue and/or any other occupiers of Venue in the course of the execution of any works.</p>
                <p>8.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If any such damage obstruction or interference is caused Operator shall indemnify Owner against any losses costs or expenses arising out of any such damage obstruction or interference or out of any delay caused by Operator and shall immediately make it good or otherwise take steps to remedy the same.</p>
                <p>8.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If there shall be any failure to comply with this clause 8 Owner may require Operator or its professionals and contractors to vacate the area involved until the damage is remedied to its satisfaction.</p>
                <p><strong>9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fees </strong><strong>and payment</strong></p>
                <p>9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator shall pay Supplier&rsquo;s Fee and to Supplier in consideration of Services on the date this Agreement is entered into.</p>
                <p>9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operator will pay to Supplier a charge in accordance with Code of Conduct which shall be immediately due and payable for each day during which Operator fails to open Kiosk for business during Hours of Operation or opens for business later than the start of Hours of Operation or closes for business earlier than the end of Hours of Operation.</p>
                <p>9.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Supplier may, without limiting any other rights or remedies it may have, set off any amounts owed to it by Operator under this Agreement against any amounts payable by it to Operator.</p>
                <p>9.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All amounts payable to Supplier under this Agreement are exclusive of VAT and are to be paid free and clear of any bank charges, duties, fees or other transactional costs, the payment of which shall be the sole responsibility of Operator.</p>
                <p>9.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; As Kiosk is temporary and moveable, the parties acknowledge and agree that no business rates are currently payable in respect of the operation of Kiosk. However, in the event that rates are levied on the operation of Kiosk whether during Licence Period or anytime thereafter, Operator acknowledges that it shall be responsible for all payments thereof in respect of its use of the Kiosk during Licence Period. Operator shall remain liable for such rates notwithstanding the termination or expiry of this Agreement and Supplier and/or Owner shall be entitled to recover payment of such rates liability from Operator at any time in the future.</p>
                <p>9.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In addition to Supplier&rsquo;s Fee Operator will be responsible for, and will promptly pay, any taxes and other outgoings relating to Location which may be or become (whether before, during or after Licence Period) payable by virtue of this Agreement being granted to Operator.</p>
                <p><strong>10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Intellectual property rights</strong></p>
                <p>10.1&nbsp;&nbsp;&nbsp; As between Operator and Supplier, all intellectual property rights and all other rights to Kiosk and in materials used by Supplier to provide Services shall belong to and be owned by Supplier.</p>
                <p><strong>11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Limitation of liability</strong></p>
                <p>11.1&nbsp;&nbsp;&nbsp; Nothing in this Agreement limits or excludes Supplier&rsquo;s or Operator&rsquo;s liability for:</p>
                <p>11.1.1&nbsp;&nbsp;&nbsp;&nbsp; death or personal injury caused by its negligence; or</p>
                <p>11.1.2&nbsp;&nbsp;&nbsp;&nbsp; fraud or fraudulent misrepresentation.</p>
                <p>11.2&nbsp;&nbsp;&nbsp; Subject to clause 11.1, Supplier shall not be liable to Operator, whether in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising under or in connection with this Agreement.</p>
                <p>11.3&nbsp;&nbsp;&nbsp; Subject to clauses 11.1 and 11.2, Supplier&rsquo;s total liability to Operator arising under or in connection with this Agreement shall be limited to one hundred percent (100%) of Supplier&rsquo;s Fees paid by Operator to Supplier in respect of Licence Period.</p>
                <p>11.4&nbsp;&nbsp;&nbsp; Supplier gives no warranty that Kiosk or Venue are legally or physically fit for the purposes specified in clause 4.1.</p>
                <p>11.5&nbsp;&nbsp;&nbsp; The Operator remains liable throughout Licence Period and after the expiry or sooner termination of this Agreement for any damage to Location, its surrounds and any part of Venue caused by Operator or its staff or other agents, whether within Permitted Use or not, including any set- up and dismantling of Operator&rsquo;s items, materials and equipment.</p>
                <p>11.6&nbsp;&nbsp;&nbsp; Neither Owner nor Supplier will be liable for any damage to any items brought into Location or Venue by Operator.</p>
                <p>11.7&nbsp;&nbsp;&nbsp; Operator will indemnify Owner and Supplier against all costs, claims, demands, actions and proceedings which arise as a consequence of:</p>
                <p>11.7.1&nbsp;&nbsp;&nbsp;&nbsp; the breach by Operator of any of the terms and conditions of this Agreement;</p>
                <p>11.7.2&nbsp;&nbsp;&nbsp; injury or damage to any property whatsoever or the death of or injury to any person which may arise out of or in consequence of the use or Permitted Use or occupation of Location or entry of any person into Venue for any purpose related to the use or Permitted Use of Location; and</p>
                <p>11.7.3&nbsp;&nbsp;&nbsp; the negligence or wilful misconduct of Operator, its employees, agents and contractors.</p>
                <p>11.8&nbsp;&nbsp;&nbsp; Operator warrants that:</p>
                <p>11.8.1&nbsp;&nbsp;&nbsp; it will be responsible for obtaining and paying for all necessary licences and consents for the display of any permitted advertising or copyright material contained or the appearance of the likeness of any person or thing in any advertisement or promotion;</p>
                <p>11.8.2&nbsp;&nbsp;&nbsp; no advertisement or promotion will be in breach of copyright or any other intellectual property rights;</p>
                <p>by reason of or arising out of its exercise of the rights granted by this Agreement or its presence in Venue.</p>
                <p>11.9&nbsp;&nbsp;&nbsp; If Occupier receives any notice, order or direction from the local or any other competent authority, it must provide a copy to Owner as soon as possible after receipt.</p>
                <p><strong>12&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Termination</strong></p>
                <p>12.1&nbsp;&nbsp;&nbsp; Without prejudice to any other rights or remedies which Supplier may have, Supplier and/or Owner may terminate this Agreement without liability to Operator:</p>
                <p>12.1.1&nbsp;&nbsp;&nbsp; immediately on notice given by Supplier and/or Owner at any time following any breach by Operator of Operator&rsquo;s obligations contained or referred to in this Agreement;</p>
                <p>12.1.2&nbsp;&nbsp;&nbsp; on notice given by Supplier and/or Owner to Operator at any time provided that Supplier and/or Owner shall endeavour to provide not less than 3 days&rsquo; notice unless to do so might prejudice in any way the rights title or interest of Supplier or Owner.</p>
                <p>12.2&nbsp;&nbsp;&nbsp; On termination of this Agreement for any reason:</p>
                <p>12.2.1&nbsp;&nbsp;&nbsp; Operator shall not have any claim for compensation arising out of termination or the manner of such termination;</p>
                <p>12.2.2&nbsp;&nbsp;&nbsp; the accrued rights, remedies, obligations and liabilities of Supplier as at termination shall not be affected, including the right to claim damages in respect of any breach of this Agreement which existed at or before the date of termination; and</p>
                <p>12.2.3&nbsp;&nbsp;&nbsp; clauses which expressly or by implication have effect after termination shall continue in full force and effect, including but not limited to the following clauses: clause 9.5, clause 10, clause 11, this clause 12.2, clause 19, and clause 21.</p>
                <p>12.3&nbsp;&nbsp;&nbsp; Upon termination, Operator shall vacate Location, remove its materials, equipment and any other property (making good any damage caused) and leave Location in in the same state of repair and condition as they were in at the start of this Agreement. Any such materials, equipment and any other property not collected within 7 days of the termination of the Agreement will become the property of Owner and may be disposed of however they wish.</p>
                <p><strong>13&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Force majeure</strong></p>
                <p>13.1&nbsp;&nbsp;&nbsp; In the event of a Force Majeure Event out with the control of Supplier which prevents Supplier from providing Operator with use of Kiosk at Location, Supplier shall use its reasonable commercial endeavours subject to approval by Owner and/or Manager to locate a suitable alternative kiosk in a similar venue which Operator will be able to use. If no such alternative can be found, Supplier shall provide a refund of Supplier&rsquo;s Fees paid by Operator which pertain to any period that is unused by Operator as a result thereof.</p>
                <p><strong>14&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Variation</strong></p>
                <p>14.1&nbsp;&nbsp;&nbsp; No variation of this Agreement shall be valid unless it is in writing and signed by or on behalf of each of the parties.</p>
                <p><strong>15&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Severance</strong></p>
                <p>15.1&nbsp;&nbsp;&nbsp; If any court or competent authority finds that any provision of this Agreement (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision shall, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this Agreement shall not be affected.</p>
                <p>15.2&nbsp;&nbsp;&nbsp; If any invalid, unenforceable or illegal provision of this Agreement would be valid, enforceable and legal if some part of it were deleted, the provision shall apply with the minimum modification necessary to make it legal, valid and enforceable.</p>
                <p><strong>16&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Entire agreement</strong></p>
                <p>16.1&nbsp;&nbsp;&nbsp; This Agreement constitutes the entire agreement between the parties and supersedes and extinguishes all previous drafts, arrangements, understandings or agreements between them, whether written or oral, relating to the subject matter of this Agreement.</p>
                <p>16.2&nbsp;&nbsp;&nbsp; Each party acknowledges that, in entering into this Agreement, it does not rely on, and shall have no remedies in respect of, any representation or warranty (whether made innocently or negligently) that is not set out in this Agreement. Each party agrees that its only liability in respect of those representations and warranties that are set out in this Agreement (whether made innocently or negligently) shall be for breach of contract.</p>
                <p><strong>17&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Assignment</strong></p>
                <p>17.1&nbsp;&nbsp;&nbsp; The benefit of this Agreement may be assigned to any new owner of Venue by Owner or the Supplier on behalf of Owner.</p>
                <p>17.2&nbsp;&nbsp;&nbsp; Operator must not allow any other person to occupy or use Kiosk or Location.</p>
                <p>17.3&nbsp;&nbsp;&nbsp; This Agreement is personal to Operator is not capable of being assigned to any other person.</p>
                <p><strong>18&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No partnership or agency</strong></p>
                <p>18.1&nbsp;&nbsp;&nbsp; Nothing in this Agreement is intended to, or shall operate to, create a partnership between the parties, or to authorise either party to act as agent for the other, and neither party shall have authority to act in the name or on behalf of or otherwise to bind the other in any way (including the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power).</p>
                <p><strong>19&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Notices</strong></p>
                <p>19.1&nbsp;&nbsp;&nbsp; A notice or other communication given to a party under this Agreement shall be in writing in English, signed by or on behalf of the party giving it and shall be sent for the attention of the person, at the address specified in this clause (or to such other address or person as that party may notify to the other, in accordance with the provisions of this clause) and shall be delivered personally, sent by commercial courier or sent by pre-paid first-class post or recorded delivery.</p>
                <p>19.2&nbsp;&nbsp;&nbsp; The addresses for service of a notice are as follows:</p>
                <p>19.2.1&nbsp;&nbsp;&nbsp; Supplier: FAO: The CEO at the address as detailed in Key Commercial Definitions; and</p>
                <p>19.2.2&nbsp;&nbsp;&nbsp; Operator: FAO: Supplier&rsquo;s Point of Contact and address as detailed in Key Commercial Definitions.</p>
                <p>19.3&nbsp;&nbsp;&nbsp; If a notice has been properly sent or delivered in accordance with this clause, it will be deemed to have been received as follows (i) if delivered personally, at the time of delivery; or (ii) if delivered by commercial courier, at the time of signature of the courier&rsquo;s receipt; or (iii) if sent by pre-paid first- class post or recorded delivery, at 9.00 am on the fourth day after posting.</p>
                <p>19.4&nbsp;&nbsp;&nbsp; The provisions of this clause 19 shall not apply to the service of any process in any legal action or proceedings.</p>
                <p><strong>20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Acknowledgements by Operator</strong></p>
                <p>20.1&nbsp;&nbsp;&nbsp; Operator hereby acknowledges that in giving advice to Operator or recommending equipment and materials for use on Kiosk, Supplier does not give any guarantee or warranty (whether express or implied) with regard to such matters or generally in connection with any successful outcome expected by Operator resulting from its use of Kiosk. Any case studies or examples of previous operators&rsquo; activities that Supplier may provide to Operator are provided only as suggestions to Operator and, as they refer specifically to previous operators&rsquo; promotions and locations, should not be relied on by Operator as an indication of the success that Operator itself might achieve through use of Kiosk. Accordingly, Operator acknowledges that no representation, warranty, inducement or promise, express or implied, has been made by Supplier or relied upon by Operator in entering into this Agreement.</p>
                <p><strong>21&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Governing law and jurisdiction</strong></p>
                <p>21.1&nbsp;&nbsp;&nbsp; This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims), shall be governed by, and construed in accordance with, the laws of England.</p>
                <p>21.2&nbsp;&nbsp;&nbsp; The parties irrevocably agree that the courts in England shall have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with this Agreement or its subject matter or formation (including non-contractual disputes or claims).</p>
                <p><strong>22&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Data Protection</strong></p>
                <p>22.1&nbsp;&nbsp;&nbsp; Each party acknowledges that they will be a separate, independent Controller in relation to any processing of the Shared Personal Data undertaken by them, and they will comply with the Data Protection Laws at all times in their processing of the Shared Personal Data.</p>
                <p>22.2&nbsp;&nbsp;&nbsp; Each party will at all times:</p>
                <p>22.2.1&nbsp;&nbsp;&nbsp; Comply with the obligations imposed on a Controller under the Data Protection Laws relating to the processing of such Shared Personal Data;</p>
                <p>22.2.2&nbsp;&nbsp;&nbsp; take appropriate technical and organisational security measures, against any unauthorised or unlawful processing of the Shared Personal Data and against accidental loss, destruction of, or damage to such Shared Personal Data;</p>
                <p>22.2.3&nbsp;&nbsp;&nbsp; take no action that would place it, or the other party, in breach of the Data Protection Laws; and</p>
                <p>22.2.4&nbsp;&nbsp;&nbsp; only carry out such processing for the purposes of performing its obligations and exercising its rights pursuant to this Agreement in accordance with this Agreement.</p>
                <p>22.3&nbsp;&nbsp;&nbsp; Each party will at all times ensure that it is lawfully entitled to share the Shared Personal Data with the other party.</p>
                <p>22.4&nbsp;&nbsp;&nbsp; For the purposes of this Clause 22:</p>
                <p>22.4.1&nbsp;&nbsp;&nbsp; &ldquo;Data Protection Legislation&rdquo; means any law applicable relating to the processing, privacy and use of personal data, including: (i) the Data Protection Act 2018 and the Privacy and Electronic Communications (EC Directive) Regulations 2003, SI 2003/2426 (both as amended), and any laws or regulations implementing Directive 95/46/EC (Data Protection Directive) or Directive 2002/581EC; (ii) the General Data Protection Regulation (EU) 2016/679, and/or any corresponding or equivalent national laws or regulations; and/or (iii) any judicial or administrative implementation of any of the above, any guidance, guidelines, codes of practice, codes of conduct or approved certification mechanisms issued by the Information Commissioner, or other regulatory or supervisory authority responsible for administering Data Protection Legislation;</p>
                <p>22.4.2&nbsp;&nbsp;&nbsp; &ldquo;Shared Personal Data&rdquo; means any personal data disclosed by or on behalf of one party to this Agreement to the other party to this Agreement; and</p>
                <p>22.4.3&nbsp;&nbsp;&nbsp; the terms &ldquo;Controller&rdquo;, &ldquo;Data Subjects&rdquo;, &ldquo;Information Commissioner&rdquo;, &ldquo;Personal Data&rdquo;, &ldquo;Processor&rdquo; and &ldquo;Processing&rdquo; (including any derivatives thereof) shall have the meanings given to them in the Data Protection Legislation.</p>
                <p>This document is delivered and takes effect on the date stated at the beginning of it.<strong><br /> </strong></p>
                <p><strong>Rules and Regulations</strong></p>
                <p>The following rules and regulations have been established to guide your business toward higher profit and build a more professional image with your customers.</p>
                <p>A breach of these rules by Operator, or any employee of Operator, may subject Operator to termination of the Agreement and forfeiture of all monies paid as provided in that agreement.</p>
                <p><strong>Kiosk </strong></p>
                <p>The Supplier will provide Kiosk.</p>
                <p>Kiosk is the property of Supplier and must not be altered in any manner.</p>
                <p>No nails, screws, double-stick tape, wire, thumbtacks or any other material that might damage Kiosk are allowed.</p>
                <p>Any security deposit may be used to restore any damages or alterations to Venue if caused by Operator or Operator&rsquo;s employees. Additional fixtures and fittings are not permitted around Kiosk unless previously approved in writing by Supplier.</p>
                <p><strong>Set Up/Removal of Material</strong></p>
                <p>Set up must be scheduled with Supplier and must take place before or after Venue operating hours. Please call to verify the date, time and exact location.</p>
                <p>Verification of electricity and phone supply must be confirmed in advance to ensure availability.</p>
                <p><strong>Location</strong></p>
                <p>Location will be at the sole discretion of Supplier and Manager.</p>
                <p>Location is subject to change whenever Supplier or Manager determine it to be in the best interest of Venue.</p>
                <p><strong>Supplier&rsquo;s Fee Payments</strong></p>
                <p>Prior to set up you must have a fully executed Agreement.</p>
                <p>Supplier&rsquo;s Fee is due in advance. The due dates and amounts are set forth in the Agreement.</p>
                <p><strong>Operating Hours</strong></p>
                <p>You are required to operate during Hours of Operation. Operator and their employees should arrive 15 - 30 minutes prior to Venue opening to set up Kiosk. A &pound;25 fee will be charged for each late opening and early closing. You must be operating at the opening hour-not arriving at the opening hour.</p>
                <p>A fee of up to &pound;250 may be charged for each non-opening of Kiosk.</p>
                <p>Hours of Operation are traditionally extended during holiday periods and you will be required to conform to the extended hours. Details of the specific schedule will be published during each holiday.</p>
                <p><strong>Permitted</strong><strong> Use</strong></p>
                <p>You are permitted to use Kiosk only in the manner set out in the Agreement. Any variations must be agreed with Supplier.</p>
                <p>Excess inventory and items not directly related to your display must be concealed from the customer&rsquo;s view.</p>
                <p><strong>Signage and Digital Screen</strong></p>
                <p>Any signage on Kiosk must be professionally lettered, fit the signage/poster locations and be approved by Supplier. No handwritten signs are allowed, and any computer-generated signs must be professionally mounted on foam board or framed. You are permitted to use the digital screens on Kiosk.</p>
                <p>Operator shall not at any time, whether on the digital screens or otherwise, display any material that is not decent, honest, legal and true.</p>
                <p><strong>Dress Code</strong></p>
                <p>Personnel are expected to be well groomed, and dress in professional attire.</p>
                <p><strong>Staffing Requirements</strong></p>
                <p>All staff working on Kiosk must carry a colour photocopy of their passport and working visa (if needed) at all times whilst working on Kiosk. Kiosk must be staffed at all times. Gaps during lunch, dinner or breaks are not allowed.</p>
                <p>Associates friends and/or family should not loiter or socialise at Kiosk. Operators and employees may not care for their children or pets at Kiosk.</p>
                <p><strong>Conduct</strong></p>
                <p>Eating, drinking and smoking is not permitted at Kiosk. Reading, television and video games are not allowed.</p>
                <p>Operators and employees are encouraged to interact with customers at Kiosk.</p>
                <p><strong>Solicitation</strong></p>
                <p>Associates may not solicit business through any disruptive noises, &ldquo;hawking&rdquo;, &ldquo;barking&rdquo; or any other associated aggressive sales tactics. Product sampling and light demonstration is acceptable and encouraged.</p>
                <p>The use of megaphones, audio equipment (including radios), flashing lights or apparatus which might tend to annoy the public or other users of Venue are strictly prohibited.</p>
                <p><strong>Security</strong></p>
                <p>It is your responsibility to handle shoplifting, theft or property damage issues with the local police. Neither Supplier nor Owner will be responsible for any items lost or stolen.</p>
                <p><strong>Seating</strong></p>
                <p>No seating - Operator&lsquo;s employees will remain standing at all times and are prohibited from bringing in a seat</p>
                <p><strong>Floor Space</strong></p>
                <p>The floor space surrounding Kiosk must be kept clear.</p>
                <p><strong>Storage</strong></p>
                <p>You may not store boxes or merchandise on the floor around Kiosk.</p>
                <p>Storage Facilities are provided in Kiosk and are locked using a combination lock, this combination will be given to the user prior to set up. The user is liable for all locks, their damage, loss or misuse. Any items left in Kiosk will be delivered to Manager to hold as per Venue&rsquo;s lost and found conditions.</p>
                <p>If storage arrangements are made, neither Venue, Owner nor Supplier will be held responsible for the items place in storage.</p>
                <p><strong>Refuse Removal</strong></p>
                <p>You must comply with Venue&rsquo;s refuse removal policy. You may not use the refuse receptacles in the common area.</p>
            </div>
        </PageWrapper>
    );
};

export default AgreementPage;

const useStyles = makeStyles(() => ({
    textCenter: {
        textAlign: 'center'
    },
}));