import { BookingActionsType, BookingActionTypes } from './types';
import { BookingDataByIdType, ConfirmedBookingType } from '../../api/types';
import { SpacesType } from '../venue/types';
import { UpdateUserDataType } from '../user/types';

export type CustomerDetailsType = {
    business: string;
    email: string;
    phoneNumber: string;
    productInformation: string;
    campaignInformation: string;
};

export type InitialBookingType = {
    data: ConfirmedBookingType;
    confirmedData: ConfirmedBookingType
    bookingTimeData: [any];
    bookingByIdData: BookingDataByIdType;
    errors: [any] | null;
    created: boolean;
    cancelled: boolean;
    details: {
        customerDetails: UpdateUserDataType;
        customerSpaces: Array<SpacesType>;
    };

};

const initialState: InitialBookingType = {
    data: {} as ConfirmedBookingType,
    confirmedData: {} as ConfirmedBookingType,
    bookingTimeData: [] as any,
    bookingByIdData: {} as BookingDataByIdType,
    errors: null,
    created: false,
    cancelled: false,
    details: {
        customerDetails: {} as UpdateUserDataType,
        customerSpaces: [],
    },
};

const bookingReducer = (state = initialState, action: BookingActionsType): InitialBookingType => {
    switch (action.type) {
        case BookingActionTypes.BOOKING_SUCCESS_DATA:
            return {
                ...state,
                data: action.payload.data,
            };
        case BookingActionTypes.CONFIRMED_BOOKING_SUCCESS_DATA:
            return {
                ...state,
                confirmedData: action.payload.data
            }
        case BookingActionTypes.GET_BOOKING_SPACES_BY_ID:
            return {
                ...state,
                bookingByIdData: action.payload.bookingByIdData,
            };
        case BookingActionTypes.DELETE_BOOKING_SPACES_BY_ID:
            return {
                ...state,
                bookingByIdData: action.payload.bookingByIdData,
                data: {} as ConfirmedBookingType,
            };
        case BookingActionTypes.ADD_CUSTOMER:
            return {
                ...state,
                details: {
                    ...state.details,
                    customerDetails: action.payload.customerDetails,
                },
            };
        case BookingActionTypes.DELETE_CUSTOMER:
            return {
                ...state,
                details: {
                    ...state.details,
                    customerDetails: {} as UpdateUserDataType,
                },
            };
        case BookingActionTypes.ADD_SPACES_CUSTOMER:
            return {
                ...state,
                details: {
                    ...state.details,
                    customerSpaces: action.payload.customerSpaces,
                },
            };
        case BookingActionTypes.DELETE_SPACES_CUSTOMER:
            return {
                ...state,
                details: {
                    ...state.details,
                    customerSpaces: [],
                },
            };
        case BookingActionTypes.BOOKING_ERRORS:
            return {
                ...state,
                errors: action.payload.errors,
            };
        case BookingActionTypes.RESET_ERROR:
            return {
                ...state,
                errors: null,
            };
        case BookingActionTypes.IS_CREATED_PAYMENT:
            return {
                ...state,
                created: action.payload.created
            }
        case BookingActionTypes.CANCELED_PAYMENT:
            return {
                ...state,
                cancelled: action.payload.canceled
            }
        default:
            return state;
    }
};

export default bookingReducer;
