import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardContent,
    createStyles,
    Divider,
    FormControl,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography,
    withStyles,
} from '@material-ui/core';

import { authenticatedSelector } from '../../../ducks/auth/selectors';
import {
    bookingDataSelector,
    bookingErrorsSelector,
    receivedBookingDataIdSelector,
} from '../../../ducks/booking/selectors';
import {
    BookingActionTypes,
    BookingGetByIdAction,
    ConfirmBookingDependingSecurePaymentAction
} from '../../../ducks/booking/types';
import ErrorPage from '../../ErrorPage/ErrorPage';

const BookingPageById: React.FC = React.memo(() => {
    const url = new URL(window.location.href);
    const paymentIntent = url.searchParams.get('payment_intent');
    const bookingId = url.hash.split('/')[2];
    const queryParams = url.hash.split('?')[1]
    const created = queryParams ? queryParams.split('=')[1] : false
    const styles = useStyles();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(authenticatedSelector);
    const receivedBookingData = useSelector(receivedBookingDataIdSelector);
    const bookingData = useSelector(bookingDataSelector);
    const { id: bookingIdFromParams } = useParams<{ id: string }>();
    const errors = useSelector(bookingErrorsSelector);
    const [retrieveBol, setRetrieveBol] = useState<boolean>(true);
    const successMessage = !!(created || paymentIntent);
    const total = Number(receivedBookingData.totalCost + receivedBookingData.additionalCharge).toFixed(2)

    useEffect(() => {
            if (isAuthenticated && bookingId && !paymentIntent && receivedBookingData.id !== +bookingIdFromParams) {
                dispatch({
                    type: BookingActionTypes.BOOKING_GET_DATA_BY_ID,
                    payload: { id: bookingId },
                } as BookingGetByIdAction);
            }
            if (paymentIntent && retrieveBol && !bookingData.mappingId) {
                dispatch({
                    type: BookingActionTypes.CONFIRM_BOOKING_DEPENDING_SECURE_PAYMENT,
                    payload: { bookingId: bookingId, paymentIntent }
                } as ConfirmBookingDependingSecurePaymentAction)
                setRetrieveBol(false);
            }

            return () => {
                if (isAuthenticated && bookingId && paymentIntent && receivedBookingData.id) {
                    window.history.pushState({}, document.title, '/');
                }
            };
        },
        [dispatch, isAuthenticated, bookingId, paymentIntent, retrieveBol, receivedBookingData.id, bookingData.mappingId, bookingIdFromParams
        ]
    );

    if (errors) {
        return (
            <>
                <ErrorPage/>
            </>
        );
    }

    return receivedBookingData.id ? (
        <>
            <div style={{ textAlign: 'center' }}>
                <h1>
                    SUMMARY OF YOUR BOOKING <br/> {receivedBookingData.Venue.name}
                </h1>
            </div>
            <div style={{ textAlign: 'center' }}>
                {successMessage && (
                    <h2 style={{ color: 'green', fontFamily: 'Example', letterSpacing: 2 }}>
                        Congratulations, the payment was successful.
                    </h2>
                )}
            </div>

            <div className={styles.secondBlock}>
                <TableContainer className={styles.table} component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">spaces</StyledTableCell>
                                <StyledTableCell align={'center'} className={styles.border2}>
                                    Date
                                </StyledTableCell>
                                <StyledTableCell align="center">Daily Cost</StyledTableCell>
                                <StyledTableCell align="center" style={{ borderLeft: '1px solid white' }}>
                                    Days
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ borderLeft: '1px solid white' }}>
                                    Total
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {receivedBookingData.Periods.map((period) => {
                                return (
                                    <StyledTableRow key={period.id}>
                                        <StyledTableCell align="center">
                                            <FormControl>{period.Space.name}</FormControl>
                                        </StyledTableCell>
                                        <StyledTableCell className={styles.border}>
                                            <div>
                                                <div
                                                    style={{ fontWeight: 'bold' }}>from: {new Date(period.startDate).toUTCString().split(' ').slice(0, 4).join(' ')} </div>
                                                <div
                                                    style={{ fontWeight: 'bold' }}>to: {new Date(period.endDate).toUTCString().split(' ').slice(0, 4).join(' ')} </div>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <div>£{(period.periodCost / period.periodDays).toFixed(2)}</div>
                                        </StyledTableCell>
                                        <StyledTableCell style={{ borderLeft: '1px solid #01458E' }} align="center">
                                            <div>{period.periodDays}</div>
                                        </StyledTableCell>
                                        <StyledTableCell style={{ borderLeft: '1px solid #01458E' }} align="center">
                                            <div style={{ fontWeight: 'bold' }}>£{period.periodCost.toFixed(2)}</div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div>
                    <Card className={styles.card}>
                        <CardContent>
                            <Typography color="textSecondary">Booking Id:</Typography>
                            <Typography component="p">{receivedBookingData.mappingId}</Typography>
                            <Divider/>
                            <Typography color="textSecondary">Company name:</Typography>
                            <Typography component="p">{receivedBookingData.companyName}</Typography>
                            <Divider/>
                            <Typography color="textSecondary">Total cost with admin fee:</Typography>
                            <Typography component="p" style={{ fontWeight: 'bold' }}>
                                £{receivedBookingData.totalCost.toFixed(2)} +
                                £{Number(receivedBookingData.additionalCharge).toFixed(2)} = £{total}
                            </Typography>
                            <Divider/>
                            <Typography color="textSecondary" className={styles.margin}>
                                Email:
                            </Typography>
                            <Typography component="p">{receivedBookingData.email}</Typography>
                            <Divider/>
                            <Typography color="textSecondary" className={styles.margin}>
                                Phone number:
                            </Typography>
                            <Typography component="p">{receivedBookingData.phoneNumber}</Typography>
                            <Divider/>
                            <Typography color="textSecondary" className={styles.margin}>
                                Address:
                            </Typography>
                            <Typography component="p">{receivedBookingData.address}</Typography>
                            <Divider/>
                            <Typography color="textSecondary" className={styles.margin}>
                                Product information:
                            </Typography>
                            <Typography component="p">{receivedBookingData.productInformation}</Typography>
                            <Divider/>
                            <Typography color="textSecondary" className={styles.margin}>
                                Campaign information:
                            </Typography>
                            <Typography component="p">{receivedBookingData.campaignInformation}</Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>
    ) : null;
});

export default BookingPageById;

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#01458E',
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
            fontFamily: 'Example',
            letterSpacing: 0.4,
            '& > div': {
                padding: 10,
            },
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

const useStyles = makeStyles(() => ({
    card: {
        width: 320,
    },
    secondBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: '15%',
        marginRight: '15%',
        marginTop: 40,
    },
    margin: {
        marginTop: 8,
    },
    root: {
        overflow: 'initial',
        width: '70%',
        marginTop: 20,
        backgroundColor: 'transparent',
    },
    content: {
        position: 'relative',
        padding: 24,
        margin: '-5% 16px 0',
        backgroundColor: '#fff',
        borderRadius: 4,
    },
    table: {
        marginRight: 40,
    },
    border2: {
        borderLeft: '1px solid white',
        borderRight: '1px solid white',
    },
    border: {
        borderLeft: '1px solid #01458E',
        borderRight: '1px solid #01458E',
    },
    bookingNotFound: {
        margin: '0 auto',
        marginTop: 40,
        fontFamily: 'Example',
        fontSize: 50,
        color: '#01458E',
        letterSpacing: 0.8,
    },
}));
